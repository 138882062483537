import { useEffect } from "react";
import useCanvas from "../../Context/index";

const useStopLoading = () => {
  const { loading, setLoading, setNotification } = useCanvas();

  useEffect(() => {
    let loadingTimer: NodeJS.Timeout;

    if (loading) {
      loadingTimer = setTimeout(() => {
        setLoading(false);
        setNotification("An error occurred during the last operation");

        setTimeout(() => {
          setNotification("");
        }, 6000); // 4 seconds
      }, 7500); // 7.5 seconds
    }

    return () => {
      clearTimeout(loadingTimer);
    };
  }, [loading, setLoading, setNotification]);
};

export default useStopLoading;
