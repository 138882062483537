import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyStats } from "../../../../services/company";
import { parseLayout } from "../Dashboard/components/editor/layoutUtils";
import { userStore } from "../../../../stores";

import DashboardLayout from "../Dashboard/DashboardLayout";
import { softwareDashboardLayouts } from "../Dashboard/defaultDashboards";

import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../../../components/ui/Tooltip";
import Select from "../../../../components/ui/Select";
import Button from "../../../../components/ui/Button";
import {
  ensurePermission,
  withPermissions,
} from "../../../../Hooks/withPermissions";

const CompanyDashboard = () => {
  const user = userStore((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  const [layout, setLayout] = useState(softwareDashboardLayouts[0]);

  const handleSelectionChange = (name) => {
    const foundLayout = softwareDashboardLayouts.find(
      (item) => item.name === name
    );
    setLayout(foundLayout);
    if (foundLayout.name === "customTest") {
      // if editable, get the edited layout from storage
      const savedLayout = localStorage.getItem("dashboard-layout");
      if (savedLayout) {
        try {
          const parsedLayout = parseLayout(savedLayout);
          setLayout(parsedLayout);
        } catch (e) {
          console.error("Error parsing saved layout:", e);
          // fall back to default layout
          setLayout(foundLayout);
        }
      } else {
        setLayout(foundLayout);
      }
    } else {
      // if not editable, set layout to the default layout
      setLayout(foundLayout);
    }
  };

  /* 
  useEffect(() => {
    getCompanyStats().then((response) => {
      console.log(response);
      if (response?.status === 200) {
        setCompanyStats(response?.data?.stats);
        setLoading(false); // Set loading to false when data is available
      }
    });
  }, []); */

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    //eslint-disable-next-line
  }, [location]);

  return (
    <>
      {(user?.company && (
        <div className="w-full min-h-screen">
          <div className="flex  items-center justify-center gap-x-2 p-2 mt-3">
            <Select
              options={softwareDashboardLayouts.map((layout) => ({
                value: layout.name,
                label: layout.label,
              }))}
              defaultValue={softwareDashboardLayouts[0].name}
              className="m-0 inline-flex md:w-4/12 lg:w-2/12 text-md"
              onChange={(value) => {
                handleSelectionChange(value);
              }}
            />

            <Tooltip
              content="Edit Dashboards"
              position="bottom"
              effect="solid"
              border={true}
              type="light"
            >
              <Button
                size="sm"
                variant="ghost"
                onClick={() =>
                  navigate(`${location.pathname}/dashboard-editor`)
                }
              >
                <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
              </Button>
            </Tooltip>
          </div>

          <div className="lg:px-4 rounded-md">
            <DashboardLayout layout={layout} editMode={false} />
          </div>
        </div>
      )) || (
        <div className="w-full h-screen flex flex-col items-center justify-center">
          <div className="text-xl font-black">
            Company does not seem to exist
          </div>
          <div className="mt-5">
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyDashboard;
