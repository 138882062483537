export const pagePermissionsData = [
  {
    name: "Dashboard",
    key: "dashboard",
    checked: false,
    children: [
      {
        name: "Edit Dashboard",
        key: "edit-dashboard",
        checked: false,
      },
    ],
  },
  {
    name: "My Tasks",
    key: "my-tasks",
    checked: false,
    children: [
      {
        name: "Tasks by Type",
        key: "tasks-by-type",
        checked: false,
      },
      {
        name: "Tasks by User",
        key: "tasks-by-users",
        checked: false,
      },
    ],
  },
  {
    name: "My Jobs",
    key: "my-jobs",
    checked: false,
    children: [
      {
        name: "Company Jobs",
        key: "company-jobs",
        checked: false,
      },
      {
        name: "Dispatch Board",
        key: "dispatch-board",
        checked: false,
      },
    ],
  },
  {
    name: "Internal Repair Orders",
    key: "internal-repair-orders",
    checked: false,
    children: [
      {
        name: "Technician View",
        key: "technician-view",
        checked: false,
      },
      {
        name: "Admin",
        key: "admin",
        checked: false,
      },
    ],
  },
  {
    name: "External Repair Orders",
    key: "external-repair-orders",
    checked: false,
    children: [
      {
        name: "Read Only",
        key: "read-only",
        checked: false,
      },
      {
        name: "Admin",
        key: "admin",
        checked: false,
      },
    ],
  },
  {
    name: "Settings",
    checked: false,
    children: [
      {
        name: "User Management",
        checked: true,
      },
      {
        name: "Admin",
        checked: false,
        children: [
          {
            name: "Invite Users",
            checked: true,
          },
          {
            name: "Manage Permissions",
            checked: false,
          },
          {
            name: "Manage Availability",
            checked: false,
          },
        ],
      },
      {
        name: "General Settings",
        checked: false,
      },
      {
        name: "Labor & Parts Matrix",
        checked: false,
      },
      {
        name: "Task Settings",
        checked: false,
        children: [
          {
            name: "Task Type Settings",
            checked: true,
          },
          {
            name: "Recurring Tasks",
            checked: false,
          },
          {
            name: "Task Categories",
            checked: false,
          },
        ],
      },
      {
        name: "Custom Fields",
        checked: false,
      },
      {
        name: "Invoice Settings",
        checked: false,
      },
    ],
  },
];

export const taskPermissions = [
  {
    id: 1,
    label: "Photography",
    value: "photography",
    checked: true,
  },
  {
    id: 2,
    label: "Editing",
    value: "editing",
  },
  {
    id: 3,
    label: "QA 1",
    value: "qa_one",
  },
  {
    id: 4,
    label: "QA 2",
    value: "qa_two",
    checked: true,
  },
];

export const productCategoryPermissions = [
  {
    id: 1,
    label: "Category Uno",
    value: "category_uno",
    checked: false,
  },
  {
    id: 2,
    label: "Another Catgory",
    value: "another_category",
    checked: true,
  },
  {
    id: 3,
    label: "Last Category",
    value: "last_category",
    checked: true,
  },
];

export const customerPermissions = [
  {
    id: 1,
    label: "Extreme SportCars",
    value: 1,
    checked: false,
  },
  {
    id: 2,
    label: "Tradeline",
    value: 2,
    checked: true,
  },
  {
    id: 3,
    label: "Earth Motor Cars",
    value: 3,
    checked: true,
  },
];
