import { ref, uploadBytesResumable, uploadString } from "@firebase/storage";
import { storage } from "../../firebase";

export const firebaseUploadImg = (file) => {
  const storageRef = ref(
    storage,
    `/files/images/${file.name}_${new Date().getTime()}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);
  return uploadTask;
};

export const firebaseUploadDoc = (data, filename) => {
  const storageRef = ref(
    storage,
    `/files/feeder/${filename}_${new Date().getTime()}`
  );
  const uploadTask = uploadString(storageRef, data);

  return uploadTask;
};
