import { useEffect, useState } from "react";
import Papa from "papaparse";
import csvFilePath from "./sampleVehicles.csv";

const useCsvData = () => {
  const [jsonData, setJsonData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(csvFilePath)
      .then((response) => response.text())
      .then((data) => {
        Papa.parse(data, {
          header: true,
          complete: (result) => {
            setJsonData(result.data);
            setLoading(false);
          },
        });
      });
  }, []);

  return { jsonData, loading };
};

export default useCsvData;
