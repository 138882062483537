import { useState, useEffect, createElement, Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import GeneralInfo from "../../../../../../../User/components/GeneralInfo";
import Notifications from "../../../../../../../User/components/Notifications";
import Availability from "../../../../../../../User/components/Availability";
import TimeOffRequest from "../../../../../../../User/components/TimeOffRequest";
import Sections from "../../../../../../../../components/ui/Sections";
import { Spinner } from "../../../../../../../../components/ui";

import Permissions from "../../../../../../../User/components/Permissions";

import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { customFields } from "../../../../../../../RecordHistory/customFields";
const RecordHistory = lazy(() => import("../../../../../../../RecordHistory"));

const Profile = ({ user }) => {
  const location = useLocation();
  const defaultTabs = [
    {
      name: "General",
      value: "general",
      href: `tab=general`,
      current: true,
      component: GeneralInfo,
      data: { user },
    },
    {
      name: "Permissions",
      value: "permissions",
      href: `tab=permissions`,
      current: false,
      component: Permissions,
      data: { user },
    },
  ];

  const [tabs, setTabs] = useState(defaultTabs);
  const [openRecordHistory, setOpenRecordHistory] = useState(false);

  useEffect(() => {
    document.title = `${user?.name}'s Profile`;
    const updatedTabs = defaultTabs.map((tab, idx) => {
      if (tab?.data && Object?.keys(tab?.data)?.includes("user")) {
        tab.data = { user };
      }
      return tab;
    });
    setTabs(updatedTabs);
  }, [user]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let currentTab = params.get("tab");

    if (!currentTab) {
      currentTab = "general"; // set default tab to 'general' if no tab is in the URL parameters
    }

    const updatedTabs = tabs.map((tab) => {
      if (tab.value === currentTab) {
        return { ...tab, current: true };
      } else {
        return { ...tab, current: false };
      }
    });
    setTabs(updatedTabs);

    //eslint-disable-next-line
  }, [location]);

  return (
    <div className="min-h-screen">
      <div className="flex md:justify-between items-start p-5">
        <div>
          <div className="flex flex-col md:flex-row md:items-center md:gap-x-3 ">
            <div className="flex">
              <img
                src={
                  user?.photo ||
                  "https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg"
                }
                className="h-1/3 w-1/3 md:h-[140px] md:w-[140px] object-fill rounded-md shadow"
              />
            </div>
            <div className="flex flex-col my-4 md:my-0">
              <div className="font-bold text-lg">{user?.name}</div>
              {user?.createdAt && (
                <div className="font-semibold text-gray-500">
                  Member since {new Date(user?.createdAt).toLocaleDateString()}
                </div>
              )}
              <div className="flex gap-x-3 items-center">
                <ShieldCheckIcon className="w-8 h-8 text-yellow-400" />
                <div className="text-xs text-blue-500">Super Admin</div>
                <div></div>
              </div>
              <div className="w-fit">
                <div
                  className="text-xs text-blue-500 underline cursor-pointer"
                  onClick={() => {
                    setOpenRecordHistory(true);
                  }}
                >
                  View record history
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sections
        tabs={tabs}
        className="border-t md:px-5 mb-0 pr-5 md:pr-0 py-2 md:py-0"
      />
      <div className="">
        {tabs
          .filter((tab) => tab.current)
          .map((tab, idx) => {
            return (
              <Suspense
                fallback={
                  <div
                    key={idx}
                    className="w-full flex justify-center items-center h-[75vh]"
                  >
                    <Spinner size="large" />
                  </div>
                }
                key={idx}
              >
                {createElement(tab.component, tab.data)}
              </Suspense>
            );
          })}
      </div>
      <RecordHistory
        open={openRecordHistory}
        close={() => {
          setOpenRecordHistory(false);
        }}
        recordId={user?.id}
        model={"User"}
        fields={["name", "email"]}
        customFields={{
          password: customFields.OBFUSCATE,
          photo: customFields.PHOTOS,
          primaryColor: customFields.COLOR,
        }}
      />
    </div>
  );
};

export default Profile;
