import React, { useEffect, useRef, useState } from "react";
import {
  ArrowsPointingOutIcon,
  ChevronLeftIcon,
  EllipsisVerticalIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import { conversations } from "../dummy";
import { Input, Spinner } from "../../../components/ui";
import Button from "../../../components/ui/Button";
import ChatBubble from "../components/ChatBubble";
import MenuButton from "../../../components/ui/MenuButton";

const MessagesFlyout = ({ conversationId, onClose, onClickSpan }) => {
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const fileButtonRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const conversation = conversations.find(
        (conversation) => conversation.conversationId === Number(conversationId)
      );

      setMessages(conversation || []);
      setLoading(false);
    }, 500);
  }, [conversationId]);

  if (loading) {
    return (
      <div className="col-span-3 flex justify-center items-center h-screen">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div className="sticky px-3 py-2 border-b flex items-center justify-between">
        <flex className="flex items-center gap-x-2">
          <Button variant="ghost" onClick={onClose}>
            <ChevronLeftIcon className="h-4 w-4 text-gray-500" />
          </Button>
          <h1 className="font-bold">John Doe</h1>
        </flex>
        <div className="flex items-center-gap-x-2">
          <Button variant="ghost" onClick={onClickSpan}>
            <ArrowsPointingOutIcon className="h-5 w-5 text-gray-500" />
          </Button>
          <MenuButton
            variant="ghost"
            options={[
              {
                name: "Rename Chat",
              },
              {
                name: "Add Participants",
              },
              {
                name: "Chat Notifications",
              },
            ]}
            menuItemsClassName="w-auto"
            actionIcon={
              <EllipsisVerticalIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
                color="black"
              />
            }
          />
          {/* <Button variant="ghost">
            <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" />
          </Button> */}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto show_table_scrollbar p-4">
        {messages.messages?.map((message) => (
          <ChatBubble removable message={message} />
        ))}
      </div>
      <div className="sticky bottom-0 flex items-center gap-x-2 p-3 border-t">
        <div className="relative flex items-center justify-end w-full">
          <Input placeholder="Type a message" className="py-1.5" />
          <Button
            variant="ghost"
            className="absolute"
            onClick={() => {
              fileButtonRef.current?.click();
            }}
          >
            <input type="file" className="hidden" ref={fileButtonRef} />
            <PaperClipIcon className="h-full w-4 text-gray-500" />
          </Button>
        </div>
        <Button>Send</Button>
      </div>
    </div>
  );
};

export default MessagesFlyout;
