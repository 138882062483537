import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { get } from "lodash";
import useMediaQuery from "../../Hooks/useMediaQuery";
import Loading from "./Loading";

const DatePickerField = ({
  field,
  formState,
  label,
  required,
  showTimeSelect = true,
  disabledKeyboardNavigation = false,
  isDisabled,
  className,
  startingDate,
  isClearable,
  showTimeInput,
  showTimeSelectOnly,
  placeholder,
  size = "md",
  withPortal,
  calendarClassName = "chromelot_calendar_picker",
  dateFormat,
  showPreviousMonths,
  monthsShown,
  labelClassName,
  errorClassName,
  onChangeSideEffect,
  fullWidth,
}) => {
  const hasError =
    formState.errors[field.name] || get(formState.errors, field.name); // for nested fields;
  const isMobile = useMediaQuery("(max-width: 640px)");

  const handleColor = (time) => {
    return time.getHours() > 12 ? "time_pm" : "time_am";
  };

  return (
    <div className={twMerge("mt-3 w-full relative", className)}>
      {label && (
        <label
          htmlFor={field.name}
          className={twMerge(
            "my-2 block text-sm font-medium text-gray-800",
            labelClassName
          )}
        >
          {label}
          {required && (
            <span className="text-red-500 ml-1" aria-label="required">
              *
            </span>
          )}
        </label>
      )}
      <div className={twMerge("relative", fullWidth ? "w-full" : "")}>
        {formState.isLoading && (
          <div className="absolute inset-0 flex items-center justify-end z-40 pr-3.5">
            <Loading className="" />
          </div>
        )}
        <DatePicker
          showTimeInput={showTimeInput}
          withPortal={isMobile || withPortal}
          calendarClassName={calendarClassName}
          name={field.name}
          showIcon
          showTimeSelect={showTimeSelect}
          timeClassName={handleColor}
          disabled={isDisabled || formState.isLoading}
          showTimeSelectOnly={showTimeSelectOnly}
          dateFormat={
            dateFormat
              ? dateFormat
              : showTimeSelect
              ? "MMMM d, yyy  h:mm aa"
              : "MMMM d, yyy"
          }
          className={twMerge(
            "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 focus:outline-none sm:text-sm",
            hasError ? "border-red-500" : "",
            size === "xs"
              ? "px-1 py-0.5"
              : size === "sm"
              ? "px-2 py-1"
              : size === "md"
              ? "px-3 py-2"
              : size === "lg"
              ? "px-4 py-3"
              : "px-3 py-2"
          )}
          selected={
            formState.isLoading
              ? null
              : field.value && moment(field?.value).isValid()
              ? moment(field?.value).toDate()
              : null
          }
          onChange={(date) => {
            const selectedDate = moment(date).isValid() ? date : null;
            onChangeSideEffect && onChangeSideEffect(selectedDate);
            field.onChange(selectedDate);
          }}
          startDate={startingDate && moment(startingDate).toDate()}
          minDate={startingDate && moment(startingDate).toDate()}
          placeholderText={
            formState.isLoading ? "Loading..." : placeholder || "Select time.."
          }
          disabledKeyboardNavigation={disabledKeyboardNavigation}
          isClearable={isClearable}
          portalId="root-portal"
          showPreviousMonths={showPreviousMonths}
          monthsShown={monthsShown}
          timeInputLabel="Time:"
          autoFocus={false}
        />
      </div>

      {hasError && (
        <p
          className={twMerge(
            "text-sm text-end text-red-500 mt-1 absolute right-0",
            errorClassName
          )}
        >
          {hasError?.message}
        </p>
      )}
    </div>
  );
};

DatePickerField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.object,
  }).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  startingDate: PropTypes.string,
  showTimeInput: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  withPortal: PropTypes.bool,
  disabledKeyboardNavigation: PropTypes.bool,
  calendarClassName: PropTypes.string,
  dateFormat: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  showPreviousMonths: PropTypes.bool,
  monthsShown: PropTypes.number,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  onChangeSideEffect: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default DatePickerField;
