import React, { useState } from "react";
import { PRIMARY_COLOR, getPastelColor, getGreyscaleColor } from "../../theme";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import Button from "./Button";
import Tooltip from "./Tooltip";

const LinkButtonGroup = ({
  buttons = [],
  customClassName = "",
  isPastel = false,
  shouldPreserveOtherParams = true,
  onButtonClick,
  activeButtonTitle = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (button) => {
    if (activeButtonTitle) {
      return button.title === activeButtonTitle;
    }
    return location.pathname === button.path;
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const preservedParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.toString() ? `?${searchParams.toString()}` : "";
  };

  const primaryColor = isPastel ? getPastelColor(PRIMARY_COLOR) : PRIMARY_COLOR;

  return (
    <div className={twMerge("inline-flex rounded-md", customClassName)}>
      {buttons.map((button, index) => {
        if (button.iconOnly) {
          return (
            <Tooltip id={button.title} content={button.title} position="bottom">
              <Button
                key={index}
                variant={button.variant}
                mode={button.mode}
                size={button.size}
                style={{
                  backgroundColor:
                    isActive(button) || hoveredIndex === index
                      ? primaryColor
                      : "#f3f4f6",
                  color:
                    isActive(button) || hoveredIndex === index
                      ? "white"
                      : "black",
                }}
                onClick={() => {
                  onButtonClick && onButtonClick(button?.title); // if onButtonClick defined, send button title to parent
                  !isActive(button) && shouldPreserveOtherParams
                    ? navigate(button.path + preservedParams())
                    : navigate(button.path);
                }}
                className={twMerge(
                  `relative ${
                    index === 0 && buttons.length === 1
                      ? "rounded-md"
                      : index === 0
                      ? "rounded-l-md rounded-r-none border-r-none"
                      : index === buttons.length - 1
                      ? "rounded-r-md rounded-l-none"
                      : "-ml-px rounded-none"
                  } inline-flex items-center text-sm font-semibold text-gray-900 focus:z-10`,
                  button.className
                )}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {button.icon}
                {/* {hoveredIndex === index && (
                  <span className="opacity-100 w-[100px] transition-opacity bg-gray-500 px-1 text-sm text-gray-100 rounded-md absolute -bottom-12 left-0">
                    {button.title}
                </span>
              )} */}
              </Button>
            </Tooltip>
          );
        }

        return (
          <Button
            className={twMerge(
              `relative ${
                index === 0 && buttons.length === 1
                  ? "rounded-md"
                  : index === 0
                  ? "rounded-l-md rounded-r-none border-r-none"
                  : index === buttons.length - 1
                  ? "rounded-r-md rounded-l-none"
                  : "-ml-px rounded-none"
              } inline-flex items-center text-sm font-semibold text-gray-900 focus:z-10`,
              button.className
            )}
            key={index}
            variant={button.variant}
            mode={button.mode}
            size={button.size}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            style={{
              backgroundColor:
                isActive(button) || hoveredIndex === index
                  ? primaryColor
                  : "#f3f4f6",
              color:
                isActive(button) || hoveredIndex === index ? "white" : "black",
            }}
            onClick={() => {
              !isActive(button) && navigate(button.path + preservedParams());
            }}
          >
            {button.icon && <span className="mr-2">{button.icon}</span>}
            <span>{button.title}</span>
          </Button>
        );
      })}
    </div>
  );
};

const ButtonGroup = ({
  buttons = [],
  customClassName = "",
  isPastel = false,
  disabled = false,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const primaryColor = isPastel ? getPastelColor(PRIMARY_COLOR) : PRIMARY_COLOR;
  const greyScalePrimaryColor = getGreyscaleColor(primaryColor);

  return (
    <div className={twMerge("inline-flex rounded-md", customClassName)}>
      {buttons.map((button, index) => {
        if (button.iconOnly) {
          return (
            <Tooltip
              key={index}
              id={button.title}
              content={button.title}
              position="bottom"
            >
              <Button
                type="button"
                variant={button.variant}
                mode={button.mode}
                style={{
                  backgroundColor:
                    button.isActive || hoveredIndex === index
                      ? disabled
                        ? greyScalePrimaryColor
                        : primaryColor
                      : "#f3f4f6",
                  color:
                    button.isActive || hoveredIndex === index
                      ? "white"
                      : "black",
                }}
                size={button.size}
                className={twMerge(
                  `relative ${
                    index === 0 && buttons.length === 1
                      ? "rounded-md"
                      : index === 0
                      ? "rounded-l-md rounded-r-none border-r-none"
                      : index === buttons.length - 1
                      ? "rounded-r-md rounded-l-none"
                      : "-ml-px rounded-none"
                  } inline-flex items-center text-sm font-semibold text-gray-900 focus:z-10 `,
                  button.className,
                  `${disabled ? "cursor-not-allowed" : ""}`
                )}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={button.onClick}
              >
                {button.icon}
              </Button>
            </Tooltip>
          );
        }

        return (
          <Button
            key={index}
            type="button"
            style={{
              backgroundColor:
                button.isActive || hoveredIndex === index
                  ? disabled
                    ? greyScalePrimaryColor
                    : primaryColor
                  : "#f3f4f6",
              color:
                button.isActive || hoveredIndex === index ? "white" : "black",
            }}
            size={button.size}
            variant={button.variant}
            mode={button.mode}
            className={twMerge(
              `relative ${
                index === 0 && buttons.length === 1
                  ? "rounded-md"
                  : index === 0
                  ? "rounded-l-md rounded-r-none border-r-none"
                  : index === buttons.length - 1
                  ? "rounded-r-md rounded-l-none"
                  : "-ml-px rounded-none"
              } inline-flex items-center justify-center text-sm font-semibold text-gray-900 focus:z-10`,
              button.className,
              `${disabled ? "cursor-not-allowed" : ""}`
            )}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={button.onClick}
          >
            {button.icon && <span className="mr-2">{button.icon}</span>}
            <span>{button.title}</span>
          </Button>
        );
      })}
    </div>
  );
};

LinkButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      iconOnly: PropTypes.bool,
      path: PropTypes.string,
      icon: PropTypes.node,
      title: PropTypes.string,
      variant: PropTypes.string,
      mode: PropTypes.string,
      size: PropTypes.string,
      icon: PropTypes.node,
    })
  ),
  customClassName: PropTypes.string,
  isPastel: PropTypes.bool,
  shouldPreserveOtherParams: PropTypes.bool,
  onButtonClick: PropTypes.func, // optional - for sending button info up
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      iconOnly: PropTypes.bool,
      isActive: PropTypes.bool,
      icon: PropTypes.node,
      title: PropTypes.string,
      onClick: PropTypes.func,
      variant: PropTypes.string,
      mode: PropTypes.string,
      size: PropTypes.string,
      icon: PropTypes.node,
    })
  ),
  customClassName: PropTypes.string,
  isPastel: PropTypes.bool,
};

export { ButtonGroup, LinkButtonGroup };
