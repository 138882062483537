import { fabric } from "fabric";
import useCanvas from "../../../Context/index";
import Select from "../../../fields/ControlSelect";

const Shapes = () => {
  const { canvasRef, shapeTypes, setShapeTypes } = useCanvas();
  const options = [
    { label: "Square", value: "square" },
    { label: "Line", value: "line" },
    { label: "Circle", value: "circle" },
    { label: "Triangle", value: "triangle" },
    { label: "Octagon", value: "octagon" },
    { label: "Ellipse", value: "ellipse" },
    { label: "Star", value: "star" },
  ];

  const handleAddShape = (selectedShape) => {
    switch (selectedShape) {
      case "square":
        addSquare();
        break;
      case "line":
        addLine();
        break;
      case "circle":
        addCircle();
        break;
      case "triangle":
        addTriangle();
        break;
      case "ellipse":
        addEllipse();
        break;
      case "star":
        addStar();
        break;
      case "octagon":
        addOctagon();
        break;

      default:
        break;
    }
  };

  const addSquare = () => {
    const square = new fabric.Rect({
      left: 100,
      top: 100,
      width: 100,
      height: 100,
      fill: "#FFFFFF",
      stroke: "#000000",
      strokeWidth: 1,
      strokeUniform: true,
    });
    canvasRef.current?.add(square);
    canvasRef.current?.setActiveObject(square);
    canvasRef.current?.renderAll();
    setShapeTypes([...shapeTypes, "rect"]);
  };

  const addLine = () => {
    const line = new fabric.Line([50, 100, 200, 100], {
      left: 170,
      top: 150,
      stroke: "#000000",
      strokeWidth: 2,
      strokeUniform: true,
    });

    canvasRef.current?.add(line);
    canvasRef.current?.setActiveObject(line);
    canvasRef.current?.renderAll();
    setShapeTypes([...shapeTypes, "line"]);
  };

  const addCircle = () => {
    const circle = new fabric.Circle({
      left: 200,
      top: 200,
      radius: 50,
      fill: "#FFFFFF",
      stroke: "#000000",
      strokeWidth: 1,
      strokeUniform: true,
    });
    canvasRef.current?.add(circle);
    canvasRef.current?.setActiveObject(circle);
    canvasRef.current?.renderAll();
    setShapeTypes([...shapeTypes, "circle"]);
  };

  const addTriangle = () => {
    const triangle = new fabric.Triangle({
      left: 300,
      top: 300,
      width: 100,
      height: 100,
      fill: "#FFFFFF",
      stroke: "#000000",
      strokeWidth: 1,
      strokeUniform: true,
    });
    canvasRef.current?.add(triangle);
    canvasRef.current?.setActiveObject(triangle);
    canvasRef.current?.renderAll();
    setShapeTypes([...shapeTypes, "triangle"]);
  };

  const addEllipse = () => {
    const ellipse = new fabric.Ellipse({
      left: 100,
      top: 100,
      rx: 50,
      ry: 25,
      fill: "#FFFFFF",
      stroke: "#000000",
      strokeWidth: 1,
      strokeUniform: true,
    });
    canvasRef.current?.add(ellipse);
    canvasRef.current?.setActiveObject(ellipse);
    canvasRef.current?.renderAll();
    setShapeTypes([...shapeTypes, "ellipse"]);
  };

  const addStar = () => {
    const star = new fabric.Polygon(
      [...Array(10)].map((_, i) => {
        const angle = Math.PI * 2 * (i / 10) + Math.PI / 10;
        const length = i % 2 === 0 ? 50 : 25;
        return {
          x: length * Math.cos(angle),
          y: length * Math.sin(angle),
        };
      }),
      {
        left: 100,
        top: 100,
        fill: "#FFFFFF",
        stroke: "#000000",
        strokeWidth: 1,
        strokeUniform: true,
        objectCaching: false,
      }
    );
    canvasRef.current?.add(star);
    canvasRef.current?.setActiveObject(star);
    canvasRef.current?.renderAll();
    setShapeTypes([...shapeTypes, "polygon"]);
  };

  const addOctagon = () => {
    const points = getPolygonPoints(8, 50, 1.96);
    const octagon = new fabric.Polygon(points, {
      left: 100,
      top: 100,
      fill: "#FFFFFF",
      stroke: "#000000",
      strokeWidth: 1,
      strokeUniform: true,
    });
    canvasRef.current?.add(octagon);
    canvasRef.current?.setActiveObject(octagon);
    canvasRef.current?.renderAll();
    setShapeTypes([...shapeTypes, "polygon"]);
  };

  // get polygon points
  // get polygon points
  const getPolygonPoints = (sides, radius, initialAngle = -Math.PI / 2) => {
    return Array.from({ length: sides }, (_, i) => {
      const angle = initialAngle + Math.PI * 2 * (i / sides);
      return {
        x: 50 + radius * Math.cos(angle),
        y: 50 + radius * Math.sin(angle),
      };
    });
  };

  return (
    <>
      <Select
        options={options}
        onChange={handleAddShape}
        className=""
        placeholder="Shapes"
        triggerClassName="justify-center pl-4"
      />
    </>
  );
};

export default Shapes;
