import Joi from "joi";

export const validateSignupData = async (data) => {
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    name: Joi.string().min(5).max(50).required(),
    password: Joi.string().min(6).required(),
    confirmPassword: Joi.any().valid(Joi.ref("password")).required(),
    groupId: Joi.number().allow(null),
    role: Joi.any().valid("INTERNAL"),
    remember: Joi.string().allow(""),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateSigninData = async (data) => {
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    password: Joi.string().min(6).required(),
    remember: Joi.string().allow(""),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateEmailExists = async (data) => {
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateResetPasswordData = async (data) => {
  const schema = Joi.object({
    newPassword: Joi.string().min(6).required(),
    confirmPassword: Joi.any().valid(Joi.ref("newPassword")).required(),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateCreateCompanyData = async (data) => {
  const schema = Joi.object({
    name: Joi.string().required(),
    address: Joi.string().required(),
    logo: Joi.string().allow(""),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateCompanyFeedData = async (data) => {
  const schema = Joi.object({
    feederFtpDomain: Joi.string().required(),
    feederFtpUsername: Joi.string().required(),
    feederFtpPassword: Joi.string().required(),
    feederFtpPort: Joi.number().required(),
    feedSyncStatus: Joi.boolean().optional(),
    backgroundJobSetup: Joi.boolean().optional(),
    extras: Joi.object().optional(),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateCompanyFeedSourceData = async (data) => {
  const schema = Joi.object({
    data: Joi.object({
      ftpDomain: Joi.string().required(),
      ftpUsername: Joi.string().required(),
      ftpPassword: Joi.string().required(),
      ftpPort: Joi.number().required(),
      mapping: Joi.array().min(1).required(),
    }),
    active: Joi.boolean().required(),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateUpdateCompanyData = async (data) => {
  const schema = Joi.object({
    name: Joi.string().optional(),
    address: Joi.string().optional(),
    logo: Joi.string().optional(),
    feederFtpDomain: Joi.string().optional(),
    feederFtpUsername: Joi.string().optional(),
    feederFtpPassword: Joi.string().optional(),
    feederFtpPort: Joi.number().optional(),
    knackAppID: Joi.string().optional(),
    knackApiKey: Joi.string().optional(),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateCompanyKnackData = async (data) => {
  const schema = Joi.object({
    knackAppID: Joi.string().required(),
    knackApiKey: Joi.string().required(),
    knackCarsObject: Joi.string().required(),
    extras: Joi.object().allow(""),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateCreateCarData = async (data) => {
  const schema = Joi.object({
    VIN: Joi.string().required(),
    Year: Joi.number().required(),
    Make: Joi.string().required(),
    Model: Joi.string().required(),
    RetailPrice: Joi.string().required(),
    Mileage: Joi.string().allow(""),
    Body: Joi.string().allow(""),
    Description: Joi.string().allow(""),
    Image: Joi.string().allow(""),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateJoinData = async (data) => {
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    name: Joi.string().min(5).max(50).required(),
    password: Joi.string().min(6).required(),
    permissions: Joi.object().required(),
    companyId: Joi.number().required(),
    invitationId: Joi.number().required(),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};

export const validateEmail = async (data) => {
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  });
  try {
    await schema.validateAsync(data);
    return { status: true, message: null };
  } catch (err) {
    return { status: false, message: err.details[0].message };
  }
};
