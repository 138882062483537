import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from ".";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Button from "./Button";
import DatePickerField from "../fields/DatePickerField";
import moment from "moment";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { isEmpty } from "lodash";
import TextAreaField from "../fields/TextAreaField";

const TimePunchModal = ({ show = false, toggle, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      date: new Date(),
      timeStamps: [
        {
          start: "",
          end: "",
        },
      ],
      comments: "",
    },
  });

  const timeFieldArray = useFieldArray({
    control,
    name: "timeStamps",
  });

  const onSubmit = (data) => {
    setLoading(true);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    })
      .then(() => {
        setLoading(false);
        onSuccess && onSuccess(data);
        onClosed();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onClosed = () => {
    reset();
    toggle();
  };

  return (
    <Modal
      open={show}
      setOpen={onClosed}
      title="Record Time Punch"
      placement="top"
      modalStyle="md:max-w-md overflow-y-auto"
      ui={
        <form onSubmit={handleSubmit(onSubmit)} className="px-1 mt-9">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3">
              <strong className="font-bold mr-2">Error!</strong>
              <span className="block sm:inline">
                Something happened, please try again.
              </span>
            </div>
          )}

          <div className="">
            <Controller
              control={control}
              name="date"
              rules={{
                required: "Required",
              }}
              render={(props) => (
                <DatePickerField
                  showTimeSelect={false}
                  label="Date"
                  placeholder={moment().format("MM/DD/YYYY")}
                  required
                  className="mb-4"
                  {...props}
                />
              )}
            />
            <div className="">
              <h2 className="my-2 block text-sm font-medium text-gray-800">
                Time
              </h2>
              <div className="border rounded-md mb-3">
                {isEmpty(timeFieldArray.fields) ? (
                  <div className="flex items-center justify-center p-3">
                    No time stamps added
                  </div>
                ) : (
                  <div className="p-3">
                    {timeFieldArray.fields.map((field, index) => {
                      return (
                        <div
                          key={field.id}
                          className={twMerge(
                            "flex items-center gap-x-2",
                            index === 0 ? "mt-0" : "mt-5"
                          )}
                        >
                          <Controller
                            control={control}
                            name={`timeStamps[${index}].start`}
                            rules={{
                              required: "Required",
                            }}
                            render={(props) => (
                              <DatePickerField
                                {...props}
                                showTimeSelectOnly
                                required
                                dateFormat="h:mm aa"
                                className="mt-0"
                                placeholder="Start Time"
                                errorClassName="text-[10px] m-0"
                              />
                            )}
                          />
                          <span className="font-bold text-gray-600">-</span>

                          <Controller
                            control={control}
                            name={`timeStamps[${index}].end`}
                            rules={{
                              required: "Required",
                              validate: (value) => {
                                if (value) {
                                  return (
                                    moment(
                                      watch(`timeStamps[${index}].start`)
                                    ).isBefore(moment(value)) ||
                                    "Select higher time"
                                  );
                                }
                                return true;
                              },
                            }}
                            render={(props) => (
                              <DatePickerField
                                {...props}
                                showTimeSelectOnly
                                required
                                // isDisabled={!watch(`time[${index}].start_time`)}
                                placeholder="End Time"
                                dateFormat="h:mm aa"
                                className="mt-0"
                                errorClassName="text-[10px] m-0"
                              />
                            )}
                          />

                          <Button
                            variant="danger"
                            onClick={() => {
                              timeFieldArray.remove(index);
                            }}
                            disabled={
                              timeFieldArray.fields.length === 1 ? true : false
                            }
                          >
                            <TrashIcon className="h-4 w-4" />
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="flex items-center justify-between border-t bg-gray-100 p-2 rounded-b-md">
                  <h2 className="block text-sm font-medium text-gray-800">
                    Duration
                  </h2>
                  <span className="font-bold text-sm">
                    {/* // hours format */}
                    {Number(
                      watch("timeStamps").reduce((acc, curr) => {
                        if (curr.start && curr.end) {
                          acc += moment(curr.end).diff(
                            moment(curr.start),
                            "hours",
                            true
                          );
                        }
                        return acc;
                      }, 0)
                    ).toFixed(1)}{" "}
                    hours
                  </span>
                </div>
              </div>

              <div className="flex justify-end">
                <Button
                  onClick={() => {
                    timeFieldArray.append({
                      start: null,
                      end: null,
                    });
                  }}
                  size="sm"
                  variant="secondary"
                  className="flex items-center gap-x-2 pr-3"
                >
                  <PlusIcon className="h-4 w-4" />
                  <span>Add</span>
                </Button>
              </div>
            </div>

            <Controller
              control={control}
              name={`comments`}
              rules={{
                required: "Required",
              }}
              render={(props) => (
                <TextAreaField label="Comments" required {...props} />
              )}
            />
          </div>

          <div className="flex justify-end mt-7 gap-x-2">
            <Button onClick={onClosed} variant="ghost">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              isLoading={loading}
              loadingText="Saving..."
            >
              Save
            </Button>
          </div>

          <pre className="bg-gray-900 rounded-md p-2 text-white mt-3">
            <code>{JSON.stringify(watch(), null, 2)}</code>
          </pre>
        </form>
      }
    />
  );
};

TimePunchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default TimePunchModal;
