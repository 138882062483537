import moment from "moment";
import TableBody from "../../../../components/ui/table/TableBody";
import TableColumns from "../../../../components/ui/table/TableColumns";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Button from "../../../../components/ui/Button";
import { useEffect, useState } from "react";
import { Spinner } from "../../../../components/ui";
import { isEmpty } from "lodash";

const Availability = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState([]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setAvailability([
        {
          start: moment().set({ hour: 8, minute: 0 }),
          end: moment().set({ hour: 17, minute: 0 }),
        },
        {
          start: moment().set({ hour: 8, minute: 0 }),
          end: moment().set({ hour: 17, minute: 0 }),
        },
        {
          start: moment().set({ hour: 8, minute: 0 }),
          end: moment().set({ hour: 17, minute: 0 }),
        },
        {
          start: moment().set({ hour: 8, minute: 0 }),
          end: moment().set({ hour: 17, minute: 0 }),
        },
        {
          start: moment().set({ hour: 8, minute: 0 }),
          end: moment().set({ hour: 17, minute: 0 }),
        },
        {
          start: moment().set({ hour: 9, minute: 0 }),
          end: moment().set({ hour: 12, minute: 0 }),
        },
        {
          start: moment().set({ hour: 9, minute: 0 }),
          end: moment().set({ hour: 12, minute: 0 }),
        },
      ]);
    }, 1500);
  }, []);

  return (
    <div className="">
      <table className="w-full">
        <TableColumns
          columnClassName="border-b border-r px-3"
          columns={[
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ]}
          withActionColumn
        />
        <TableBody>
          {loading ? (
            <tr>
              <td align="center" colSpan={10} className="p-10 border-b">
                <Spinner size="large" />
              </td>
            </tr>
          ) : isEmpty(availability) ? (
            <tr>
              <td align="center" colSpan={10} className="p-5 border-b text-xl">
                No availability to display
              </td>
            </tr>
          ) : (
            <tr className="">
              {availability.map((item, idx) => (
                <td className="text-xs border-b border-r" key={idx}>
                  <h3 className="font-bold p-2 px-3">
                    {`${moment(item.start)
                      .set({ hour: 8, minute: 0 })
                      .format("hh:mm A")} - ${moment(item.end)
                      .set({ hour: 17, minute: 0 })
                      .format("hh:mm A")}`}
                  </h3>
                </td>
              ))}

              <td className="border-b p-3 hover:bg-gray-100 cursor-pointer">
                <PencilSquareIcon className="h-5 w-5 text-gray-500" />
              </td>
            </tr>
          )}
        </TableBody>
      </table>

      <div className="flex justify-end p-5">
        <Button>Save</Button>
      </div>
    </div>
  );
};

export default Availability;
