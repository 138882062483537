import { fabric } from "fabric";
import { initialCanvasWidth, initialCanvasHeight } from "../canvasOptions";

const zoomOperations = (
  fabricCanvasRef: React.RefObject<fabric.Canvas>,
  domCanvasRef: React.RefObject<HTMLCanvasElement>
) => {
  let previousZoomLevel = 1; // Keep track of the previous zoom level

  const setZoom = (zoomLevel) => {
    const fabricCanvas = fabricCanvasRef.current;

    if (!fabricCanvas) return;

    // Calculate the scaling factor relative to the previous zoom level
    const relativeZoomFactor = zoomLevel / previousZoomLevel;

    // Calculate new canvas dimensions
    const newCanvasWidth = initialCanvasWidth * zoomLevel;
    const newCanvasHeight = initialCanvasHeight * zoomLevel;

    // Update the canvas dimensions
    fabricCanvas.setWidth(newCanvasWidth);
    fabricCanvas.setHeight(newCanvasHeight);

    // Scale all objects inside the canvas
    fabricCanvas.forEachObject((object) => {
      object.scaleX *= relativeZoomFactor;
      object.scaleY *= relativeZoomFactor;
      object.left *= relativeZoomFactor;
      object.top *= relativeZoomFactor;
      object.setCoords();
    });

    // Update the previous zoom level
    previousZoomLevel = zoomLevel;

    fabricCanvas.renderAll();
  };

  const zoomIn = () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (!fabricCanvas) return;

    let zoom = fabricCanvas.getZoom();
    zoom += 0.1;
    setZoom(zoom);
  };

  const zoomOut = () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (!fabricCanvas) return;

    let zoom = fabricCanvas.getZoom();
    zoom -= 0.1;
    setZoom(zoom);
  };

  return { setZoom, zoomIn, zoomOut };
};

export default zoomOperations;
