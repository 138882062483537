import { useEffect, useState, useRef } from "react";

const useQuery = (apiFn) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({ message: "", type: "" });
  const [data, setData] = useState();

  const logic = () => {
    setInfo({ message: "", type: "" });
    setLoading(true);
    if (apiFn) {
      apiFn().then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          setData(response?.data);
          setInfo({ message: response?.data?.message, type: "success" });
        } else {
          setInfo({ message: response?.data?.message, type: "error" });
        }
      });
    }
  };
  const reload = () => {
    logic();
  };

  const updateData = (data) => {
    setData(data);
  };
  useEffect(() => {
    logic();
  }, []);
  return { loading, info, data, updateData, reload };
};

export default useQuery;
