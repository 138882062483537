// Import the new icons
/* import {
  FaCar,
  FaTruck,
  FaMoneyBill,
  FaClipboardList,
  FaUsers,
  FaCalendar,
} from "react-icons/fa"
import { IoMdSpeedometer, IoMdAlert } from "react-icons/io"
import { GiSiren } from "react-icons/gi"
import { ChartPieIcon, ArrowTrendingUpIcon } from "@heroicons/react/20/solid" */

const inventoryWidgetsList = [
  {
    name: "Cars in Inventory",
    id: "1",
    type: "summary",
    icon: "RectangleStack",
  },
  {
    name: "Vehicles In Prep",
    id: "2",
    type: "summary",
    icon: "ArrowPathLoop",
  },
  {
    name: "Vehicles In Prep",
    type: "chart",
    chartType: "pie",
    id: "3",
    icon: "ChartPie",
  },
  { name: "Active Cars", id: "4", type: "summary", icon: "Signal" },
  { name: "In Transit", id: "5", type: "summary", icon: "Truck" },
  { name: "Live Inventory", id: "6", type: "summary", icon: "Play" },
  {
    name: "Front Line Ready",
    id: "7",
    type: "summary",
    icon: "Key",
  },
  { name: "In Prep", id: "8", type: "summary", icon: "Wrench" },
  { name: "In Staging", id: "9", type: "summary", icon: "VideoCamera" },
  {
    name: "Pending Check In",
    id: "10",
    type: "summary",
    icon: "ArrowLeftRectangle",
  },
  {
    name: "Pending Check Out",
    id: "11",
    type: "summary",
    icon: "ArrowRightRectangle",
  },
  {
    name: "Sales this Week",
    id: "12",
    type: "chart",
    chartType: "line",
    icon: "ArrowTrendingUp",
  },
  {
    name: "Open Vehicle Issues",
    id: "13",
    type: "summary",
    icon: "Ticket",
  },
];

const financeWidgetsList = [
  {
    name: "Late Invoices Total",
    id: "50",
    type: "summary",
    icon: "ExclamationTriangle",
  },
  {
    name: "Late Invoices",
    id: "51",
    type: "summary",
    icon: "ExclamationTriangle",
  },
  { name: "Unpaid Invoices", id: "52", type: "summary", icon: "EnvelopeOpen" },
  {
    name: "Unpaid Invoices $",
    id: "53",
    type: "summary",
    icon: "EnvelopeOpen",
  },
  { name: "Paid Invoices", id: "54", type: "summary", icon: "Envelope" },
  { name: "Invoices Due", id: "55", type: "summary", icon: "BarsArrowUp" },
  { name: "Invoices Owed", id: "56", type: "summary", icon: "BarsArrowDown" },
  { name: "Total Owed", id: "57", type: "summary", icon: "Banknotes" },
  {
    name: "Pay Reports Owed",
    id: "58",
    type: "summary",
    icon: "DocumentArrowUp",
  },
];

const taskWidgetsList = [
  { name: "Tasks Assigned", id: "100", type: "summary", icon: "UserPlus" },
  { name: "Tasks Due", id: "101", type: "summary", icon: "Clock" },
  { name: "Late Tasks", id: "102", type: "summary", icon: "ExclamationCircle" },
  { name: "Blocked Tasks", id: "103", type: "summary", icon: "Ban" },
  { name: "Unassigned Tasks", id: "104", type: "summary", icon: "UserGroup" },
  { name: "Upcoming Jobs", id: "105", type: "summary", icon: "Briefcase" },
];

const customWidgetsList = [
  {
    id: "1",
    name: "Custom 1",
    type: "summary",
    chartType: null,
    icon: "Bolt",
  },
  {
    id: "2",
    name: "Custom 2",
    type: "summary",
    chartType: "line",
    icon: "Bolt",
  },
];

export {
  inventoryWidgetsList,
  financeWidgetsList,
  taskWidgetsList,
  customWidgetsList,
};
