import { Spinner, InfoCard } from "../../../../../../../../../components/ui";
import Shorcuts from "./components/Shorcuts";
import Delete from "./components/Delete";
import Undo from "./components/Undo";
import Redo from "./components/Redo";
import Button from "../fields/ControlButton";
import Zoom from "./components/Zoom";
import Duplicate from "./components/Duplicate";
import Save from "./components/Save";
import GroupUngroup from "./components/GroupUngroup";
import useCanvas from "../Context";

const BottomToolbar = () => {
  const { notification, loading } = useCanvas();
  return (
    <div className="relative">
      {loading && (
        <div className="z-10 absolute inset-0 h-20 flex items-center justify-center bg-black bg-opacity-40">
          <Spinner size="large" className="" />
        </div>
      )}
      <section className="flex justify-between items-center h-12 w-full px-4">
        <Shorcuts />
        <div className="flex items-center gap-2"></div>
        {/* <Zoom /> */}
        {notification && (
          <div className="hidden md:block">
            <InfoCard
              type="info"
              message={notification}
              forever={false}
            ></InfoCard>
          </div>
        )}
        <div className=" flex justify-end items-center gap-2">
          <GroupUngroup />
          <Duplicate />
          <Delete />
          <Undo />
          <Redo />
          <Save />
        </div>
      </section>
      {notification && (
        <div className="md:hidden">
          <InfoCard
            type="info"
            message={notification}
            forever={false}
          ></InfoCard>
        </div>
      )}
    </div>
  );
};

export default BottomToolbar;
