import { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";
import { fabric } from "fabric";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

// a FeaturesGroup is a group with groups nested in it.
// each group nested in it consists of a Text obj for the bullet points,
// and a Textbox obj for the features text
const NumFeatures = () => {
  const { canvasRef, lastSelectedObject } = useCanvas();
  const [numFeatures, setNumFeatures] = useState<string>("");

  //   console.log(lastSelectedObject.featuresGroup);

  useEffect(() => {
    setNumFeatures(lastSelectedObject?.totalNumFeatures);
  }, [lastSelectedObject]);

  const handleChange = (newNum) => {
    const canvas = canvasRef.current;
    const activeObject = canvas?.getActiveObject() as fabric.Group;

    if (activeObject && activeObject.name === "superFeaturesGroup") {
      updateSuperFeaturesGroup(canvasRef.current, true, activeObject, {
        totalNumFeatures: newNum,
      });
    }
  };

  return (
    <section>
      <Label>Features</Label>
      <Input
        value={numFeatures}
        onChange={(e) => {
          // Just update the state without changing the dimensions
          const newNum = e.target.value;
          setNumFeatures(newNum);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // Update the dimensions when Enter key is pressed
            handleChange(numFeatures);
          }
        }}
        min={1}
        className="w-full"
        type="number"
      />
    </section>
  );
};

export default NumFeatures;
