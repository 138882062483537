import React, { useEffect } from "react";
import Select from "../../../fields/ControlSelect";
import useCanvas from "../../../Context";

interface Car {
  Year: number;
  Make: string;
  Model: string;
  id: string;
}

interface Props {
  cars: Car[];
  loadingCars: boolean;
  reloadCars: () => void;
}

const CarToPreview: React.FC<Props> = ({ cars, reloadCars, loadingCars }) => {
  const { isCanvasReady, canvasRef, selectedCar, setSelectedCar } = useCanvas();
  //   console.log(cars);

  //------------------------------ selecting a car --------------------------------
  useEffect(() => {
    reloadCars();
  }, []);

  let vehicleOptions = [{ label: "No Vehicle", value: "No Vehicle" }];

  // Create options from fetched data
  if (Array.isArray(cars)) {
    vehicleOptions = [
      ...vehicleOptions,
      ...cars.map((car) => ({
        label: `${car.Year} ${car.Make} ${car.Model}`,
        value: car.id,
      })),
    ];
  }

  //   console.log(...cars.map((car) => ({ label: `${car.Year} ${car.Make} ${car.Model}`, value: car.id })));

  const handleChangeVehicle = (selectedValue: string) => {
    const selectedCar = cars.find((car) => car.id === selectedValue) || null;
    if (isCanvasReady) {
      //   replaceFields(canvasRef, selectedCar);
    }
    setSelectedCar(selectedCar);
  };

  return (
    <Select
      options={vehicleOptions}
      onChange={handleChangeVehicle}
      value={selectedCar?.id}
      highlightSelected={true}
      placeholder="Select a Car"
    />
  );
};

export default CarToPreview;
