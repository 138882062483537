import { useEffect, useState } from "react";

const useForm = (apiFn, validationFn, onSuccess, onError) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({ message: "", type: "" });
  const [data, setData] = useState();

  const handleSubmit = async (e) => {
    //Prvent form from being submitted
    e.preventDefault();

    //Remove any errors that might be present in a preceeding request
    setInfo({ message: "", type: "" });

    //Validate form with validation fn passed in hook
    if (validationFn) {
      const validationResult = await validationFn(formData);
      if (!validationResult.status) {
        setInfo({ message: validationResult.message, type: "error" });
        return;
      }
    }

    //Manage Loading while API call is running and return the results appropriately
    setLoading(true);
    let response;
    if (apiFn) {
      response = await apiFn();
    }
    console.log("REZZZ", response);
    setLoading(false);

    switch (true) {
      case response?.status === 200:
        setData(response?.data);
        setInfo({ message: response?.data?.message, type: "success" });
        if (onSuccess) {
          onSuccess(response?.data);
        }
        return;
      case response?.status >= 400 && response?.status < 500:
        setInfo({ message: response?.data?.message, type: "error" });
        if (onError) {
          onError();
        }
        return;
      case response?.status >= 500 && response?.status <= 600:
        setInfo({ message: response?.data?.message, type: "error" });
        if (onError) {
          onError();
        }
        return;
      default:
        setInfo({ message: response?.data?.message, type: "error" });
        if (onError) {
          onError();
        }
        return;
    }
  };

  const handleChange = (e) => {
    e.persist();
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {}, []);
  return [formData, loading, info, data, handleChange, handleSubmit];
};

export default useForm;
