import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ButtonGroup from "../../../../fields/ControlButtonGroup";

const AlignHorizontal = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentTextAlign, setCurrentTextAlign] = useState("left"); // Default text align

  const buttons = [
    {
      name: "Left",
      onClick: () => handleTextAlignChange("left"),
      isActive: currentTextAlign === "left",
    },
    {
      name: "Center",
      onClick: () => handleTextAlignChange("center"),
      isActive: currentTextAlign === "center",
    },
    {
      name: "Right",
      onClick: () => handleTextAlignChange("right"),
      isActive: currentTextAlign === "right",
    },
  ];

  // get horizontal align of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        if (obj.type === "i-text" || obj.type === "textbox") {
          setCurrentTextAlign(obj.textAlign || "left");
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleTextAlignChange = (newAlign) => {
    setCurrentTextAlign(newAlign);

    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];
      if (obj.type === "i-text" || obj.type === "textbox") {
        obj.set("textAlign", newAlign);
      }

      obj.setCoords();
      obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Horizontal Align</Label>
      <ButtonGroup buttons={buttons} />
    </section>
  );
};

export default AlignHorizontal;
