import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMediaQuery";

import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { isEmpty } from "lodash";
import Button from "../../components/ui/Button";
import {
  ArrowDownTrayIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

const PublicGallery = () => {
  const params = useParams();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [cols, setCols] = useState(isMobile ? 3 : 8);
  const [images, setImages] = useState([]);
  console.log(params.id);

  useEffect(() => {
    const images = [];
    for (let i = 0; i < 100; i++) {
      images.push(
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTntbVgQ_--dkQ61wfqV95H1VNqva1vblD5mOnzdvcb&s"
      );
    }

    setImages(images);
  }, []);

  const handleSliderChange = useCallback((e) => {
    const newCols = parseInt(e.target.value);
    setCols(newCols);
  }, []);

  if (isEmpty(images)) {
    return (
      <div className="flex justify-center items-center h-[50vh] text-lg font-bold text-gray-600 ">
        No photos available.
      </div>
    );
  }

  // Tried looping this but it doesn't work
  let grid;
  if (cols === 12) {
    grid = "grid-cols-12";
  } else if (cols === 11) {
    grid = "grid-cols-11";
  } else if (cols === 10) {
    grid = "grid-cols-10";
  } else if (cols === 9) {
    grid = "grid-cols-9";
  } else if (cols === 8) {
    grid = "grid-cols-8";
  } else if (cols === 7) {
    grid = "grid-cols-7";
  } else if (cols === 6) {
    grid = "grid-cols-6";
  } else if (cols === 5) {
    grid = "grid-cols-5";
  } else if (cols === 4) {
    grid = "grid-cols-4";
  } else if (cols === 3) {
    grid = "grid-cols-3";
  } else if (cols === 2) {
    grid = "grid-cols-2";
  } else if (cols === 1) {
    grid = "grid-cols-1";
  }

  return (
    <div className="">
      <h1 className="text-xl p-5">Company LOGO</h1>
      <div className="flex items-center justify-between gap-x-3 py-3 md:py-0 p-5">
        <div className="">
          <h2 className="text-xl">2021 Honda Odyssey</h2>
          <span>12HAGDAS123FGAS</span>
        </div>

        <Button className="flex items-center gap-x-2" variant="ghost">
          <ArrowDownTrayIcon className="w-5 h-5 text-gray-500" />
          <h2>Download All</h2>
        </Button>
      </div>
      <div className="p-5 mt-3 border-t h-[80vh] show_table_scrollbar overflow-y-auto pb-[80px]">
        <LightGallery
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
          closable
          showCloseIcon={true}
          elementClassNames={twMerge("grid gap-4", grid)}
        >
          {images.map((img, idx) => (
            <a
              href={img}
              key={img + idx}
              data-src={img}
              className=""
              draggable={false}
            >
              <img
                src={img}
                data-src={img}
                alt=""
                draggable={false}
                className={twMerge(
                  "hover:opacity-50 h-full w-full ease-in-out rounded-md duration-300 cursor-pointer"
                )}
              />
            </a>
          ))}
        </LightGallery>
      </div>
      <div className="fixed bottom-0 w-full p-3 flex bg-white left-0 items-center justify-between">
        <h2>{images.length} total photos</h2>
        <div className="flex md:justify-end justify-between md:w-auto w-full items-center gap-x-3 p-1 rounded-md  border">
          <Button
            size="sm"
            variant="ghost"
            onClick={() => {
              if (cols < 12) {
                setCols(cols + 1);
              }
            }}
          >
            <MinusIcon className="w-5 h-5" />
          </Button>
          <input
            type="range"
            max={isMobile ? 3 : 12}
            min={1}
            step={1}
            value={cols}
            onChange={handleSliderChange}
            className="transparent w-full h-1.5 cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200 transform rotate-180"
          />
          <Button
            size="sm"
            variant="ghost"
            onClick={() => {
              if (cols > 1) {
                setCols(cols - 1);
              }
            }}
          >
            <PlusIcon className="w-5 h-5" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PublicGallery;
