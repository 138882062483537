import { Fragment, useState } from "react";
import { Input, Notification } from "../../../../components/ui";
import Button from "../../../../components/ui/Button";
import { updateUser } from "../../../../services/users";
import { PRIMARY_COLOR } from "../../../../theme";
import { twMerge } from "tailwind-merge";

const AppearanceInfo = ({ user }) => {
  /*   const [userDetails, setUserDetails] = useState({
    primaryColor: user?.primaryColor || user?.company?.primaryColor,
  }); */
  const [userDetails, setUserDetails] = useState({
    primaryColor:
      user?.primaryColor || user?.company?.primaryColor || PRIMARY_COLOR,
  });
  const [info, setInfo] = useState({ message: "", type: "", title: "" });
  const initialColor = PRIMARY_COLOR;
  const [editPrimaryColor, setEditPrimaryColor] = useState(false);

  const updateUserDetail = (details) => {
    setInfo({ message: "", type: "" });
    updateUser(user?.id, details).then((response) => {
      if (response?.status === 200) {
        setInfo({ message: response?.data?.message, type: "success" });
        setUserDetails((prevState) => ({
          ...prevState,
          ...response?.data?.updatedData,
        }));
        setEditPrimaryColor(false);
      } else {
        setInfo({ message: response?.data?.message, type: "error" });
      }
    });
  };

  return (
    <Fragment>
      <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-3 px-5 py-3">
        <div className="block font-medium text-gray-500 py-3">
          Application Color
        </div>
        <div className="py-3">
          <input
            id="color-picker"
            className={twMerge(
              "opacity-0 absolute",
              editPrimaryColor ? "cursor-pointer" : "cursor-not-allowed"
            )}
            type="color"
            name="primaryColor"
            value={userDetails?.primaryColor}
            onChange={(e) => {
              setUserDetails({ primaryColor: e.target.value });
              setEditPrimaryColor(true);
            }}
            disabled={!editPrimaryColor}
          />
          <label
            htmlFor="color-picker"
            className="w-8 h-8 rounded-md cursor-pointer block border"
            style={{ background: `${userDetails?.primaryColor}` }}
          />
        </div>
        <div className="flex items-center justify-end gap-3 ml-5">
          {(editPrimaryColor && (
            <div className="flex gap-x-2 items-center">
              <Button
                variant="success"
                onClick={() => {
                  updateUserDetail({ primaryColor: userDetails?.primaryColor });
                }}
              >
                Update
              </Button>
              <Button
                variant="light"
                onClick={() => {
                  setUserDetails({ primaryColor: initialColor });
                  setEditPrimaryColor(false);
                }}
              >
                Cancel
              </Button>
            </div>
          )) || (
            <Button
              variant="light"
              onClick={() => {
                setEditPrimaryColor(true);
              }}
            >
              Edit
            </Button>
          )}

          {/* <Button
            variant="light"
            onClick={() => {
              setUserDetails({ primaryColor: initialColor });
              setEditPrimaryColor(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              updateUserDetail({ primaryColor: userDetails?.primaryColor });
            }}
          >
            Save
          </Button> */}
        </div>
      </div>

      <Notification
        type={info.type}
        message={info.message}
        title={info.title}
      />
    </Fragment>
  );
};

export default AppearanceInfo;
