import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ButtonGroup from "../../../../fields/ControlButtonGroup";

const TextBackgroundType = () => {
  const {
    canvasRef,
    isCanvasReady,
    textBackgroundType,
    setTextBackgroundType,
  } = useCanvas();
  const [lastFilledColor, setLastFilledColor] = useState("#FFFFFF"); // Default to white or any initial color

  const buttons = [
    {
      name: "Transparent",
      onClick: () => handleBackgroundChange("transparent"),
      isActive: textBackgroundType === "transparent",
    },
    {
      name: "Filled",
      onClick: () => handleBackgroundChange("filled"),
      isActive: textBackgroundType === "filled",
    },
  ];

  // get textBackgroundType of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;
    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        if (obj.backgroundColor) {
          setTextBackgroundType("filled");
          setLastFilledColor(obj.backgroundColor);
        } else {
          setTextBackgroundType("transparent");
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleBackgroundChange = (newBackground) => {
    setTextBackgroundType(newBackground);

    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];
      if (newBackground === "filled") {
        obj.set("backgroundColor", lastFilledColor); // Use the last known filled color
      } else {
        // Save the last filled color before setting it to transparent
        if (obj.backgroundColor) {
          setLastFilledColor(obj.backgroundColor);
        }
        obj.set("backgroundColor", null);
      }
      obj.setCoords();
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Background</Label>
      <ButtonGroup buttons={buttons} />
    </section>
  );
};

export default TextBackgroundType;
