import carloader from "../../assets/carloader.gif";
const SplashScreen = () => {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-1/2 md:w-1/4">
        <img src={carloader} className="w-full h-full" />
      </div>
    </div>
  );
};

export default SplashScreen;
