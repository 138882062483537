import { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ColorPicker from "../../../../fields/ControlColorPicker";

const BorderColor = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentColor, setCurrentColor] = useState("#000000"); // Default to black

  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleObjectBorderColor = (e) => {
      const obj = e.target;
      setCurrentColor(obj.stroke || "#000000");
    };

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        setCurrentColor(obj.stroke || "#000000");
      }
    };

    currentCanvas?.on("object:modified", handleObjectBorderColor);
    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("object:modified", handleObjectBorderColor);
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleColorChange = (newColor) => {
    setCurrentColor(newColor);
    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && currentColor !== "") {
      activeObjects.forEach((obj) => {
        obj.set("stroke", currentColor);

        obj.setCoords();
        obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
      });
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Border Color</Label>
      <ColorPicker value={currentColor} onChange={handleColorChange} />
    </section>
  );
};

export default BorderColor;
