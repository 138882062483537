import React from "react";
import { fabric } from "fabric";
import useCanvas from "../../../Context/index";
import Button from "../../../fields/ControlButton";
import {
  specialWrapperStart,
  specialWrapperEnd,
} from "../../../utils/constants";
import { replaceTextWithSpecialCode } from "../../../utils/hooks/useReplaceDynamicFields";

interface TextProps {
  buttonTitle: string;
  staticContent?: string;
  dynamic?: boolean;
  carFields?: string[];
}

interface DynamicTextbox extends fabric.Textbox {
  dynamic?: boolean;
  carFields?: string[];
}

const Text: React.FC<TextProps> = ({
  buttonTitle,
  staticContent,
  dynamic,
  carFields,
}) => {
  const { canvasRef, isCanvasReady, selectedCar } = useCanvas();

  const handleAddText = () => {
    const canvas = canvasRef.current;
    const dynamicContent = `${specialWrapperStart}${carFields}${specialWrapperEnd}`;

    // console.log(dynamicContent);
    // console.log(dynamic);

    // a text box will either be staticContent or dynamic - if dynamic, it uses the carfields array with special wrapper
    const getTextValue = () => {
      if (staticContent || staticContent === "") return staticContent;
      if (dynamic) {
        return dynamicContent;
      }
    };
    const textValue = getTextValue();
    console.log(textValue);

    const options: fabric.ITextboxOptions = {
      originX: "center",
      splitByGrapheme: true,
      width: 200,
      top: 150,
      left: 150,
      fontSize: 16,
      fontWeight: "500",
      lineHeight: 1.1,
      textAlign: "left",
      fill: "#000000",
      charSpacing: 0,
      padding: 0,
    };

    const textObj = new fabric.Textbox(textValue, options) as DynamicTextbox;

    if (dynamic) {
      textObj.dynamic = dynamic || false; // textboxes that should have textObj replaced later have property "dynamic" set to "true"
      textObj.carFields = carFields || [];
    }
    /* 
    // ---------- group code -----------------
    // Create a group with the Textbox
    const group = new fabric.Group([textObj], {
      top: 50,
      left: 150,
      backgroundColor: "#FFA500", // Background color set to light orange
      stroke: "#000", // Border color set to black
      strokeWidth: 7, // Border width set to 2
      lockScalingFlip: true,
    });

    // ---------- end group code -----------------

    canvas?.add(group); */
    canvas?.add(textObj);
    canvas?.renderAll();

    if (dynamic) replaceTextWithSpecialCode(textObj, selectedCar);

    // Make the latest added Textbox the active object and enter editing mode
    const allObjects = canvasRef.current?.getObjects();

    if (allObjects) {
      const lastObject = allObjects[allObjects.length - 1];
      if (lastObject && lastObject.type === "textbox") {
        canvasRef.current?.setActiveObject(lastObject);
        (lastObject as fabric.Textbox).setControlsVisibility({
          mt: false, // Disable top-middle control
          mb: false, // Disable bottom-middle control
          ml: true, // Disable middle-left control
          mr: true, // Disable middle-right control
          bl: false, // Disable bottom-left control
          br: false, // Disable bottom-right control
          tl: false, // Disable top-left control
          tr: false, // Disable top-right control
        });
        (lastObject as fabric.Textbox).enterEditing();
      }
    }
  };

  return (
    <Button className="text-xs w-full" onClick={() => handleAddText()}>
      {buttonTitle}
    </Button>
  );
};

export default Text;
