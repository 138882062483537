import TooltipWrapper from "../../fields/TooltipWrapper";
import Button from "../../fields/ControlButton";
import useCanvas from "../../Context";

const Duplicate = () => {
  const { duplicate } = useCanvas();

  return (
    <>
      <TooltipWrapper title="Duplicate Selected Item(s)">
        <Button
          className="py-[2px] text-sm whitespace-nowrap"
          onClick={() => duplicate()}
        >
          {/* <DocumentDuplicateIcon className="text-gray-700 w-4 h-4" /> */}
          Duplicate
        </Button>
      </TooltipWrapper>
    </>
  );
};

export default Duplicate;
