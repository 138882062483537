import React, { Fragment, useEffect, useState } from "react";
import {
  ArrowsPointingOutIcon,
  ChatBubbleLeftIcon,
  ChevronLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import useShowChatSidebar from "../showChatSidebar";
import Skeleton from "../../../components/ui/Skeleton";
import Button from "../../../components/ui/Button";
import { ButtonGroup } from "../../../components/ui/ButtonGroup";
import { dummyConversations } from "../dummy";
import Conversation from "../components/Conversation";
import MessagesFlyout from "./MessagesFlyout";
import Drawer from "../../../components/ui/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import NewMessage from "./NewMessage";

const ChatDrawer = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("team");
  const { showDrawer, setShowDrawer } = useShowChatSidebar();
  const [newMessage, setNewMessage] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  //
  useEffect(() => {
    setTab("team");
    setNewMessage(false);
    setConversationId(null);
  }, [showDrawer]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setConversations(dummyConversations);
      setLoading(false);
    }, 1000);
  }, [tab]);

  const onClickSpan = (id) => {
    setShowDrawer(false);
    id
      ? navigate(`/dashboard/chat/${id}`, {
          state: {
            from: location.pathname,
          },
        })
      : navigate("/dashboard/chat", {
          state: {
            from: location.pathname,
          },
        });
  };

  return (
    <Fragment>
      <Button
        onClick={() => setShowDrawer(true)}
        variant="ghost"
        className="flex mt-2 hover:bg-gray-200 rounded-full p-2 transition ease-in-out relative"
      >
        <ChatBubbleLeftIcon
          className="h-5 w-5 cursor-pointer"
          aria-hidden="true"
        />

        <span className="absolute top-0 right-0 bg-red-500 rounded-full px-1.5 text-white text-xs">
          1
        </span>
      </Button>

      <Drawer
        show={showDrawer}
        onClose={() => setShowDrawer(false)}
        rawContent
        content={
          <div className="bg-white">
            {conversationId ? (
              <MessagesFlyout
                conversationId={conversationId}
                onClose={() => setConversationId(null)}
                onClickSpan={() => onClickSpan(conversationId)}
              />
            ) : (
              <div className="flex flex-col h-screen overflow-hidden relative">
                {newMessage ? (
                  tab === "team" ? (
                    <NewMessage
                      back={() => {
                        setNewMessage(false);
                        setTab("team");
                      }}
                      close={() => setShowDrawer(false)}
                    />
                  ) : (
                    <div className="flex flex-col h-screen overflow-hidden">
                      <div className="flex items-center justify-between border-b p-2">
                        <div className="flex items-center gap-x-2">
                          <Button
                            variant="ghost"
                            onClick={() => {
                              setNewMessage(false);
                              setTab("sms");
                            }}
                          >
                            <ChevronLeftIcon className="h-5 w-5" />
                          </Button>
                          <h1>New Message | SMS</h1>
                        </div>
                        <Button
                          variant="ghost"
                          onClick={() => {
                            setNewMessage(false);
                            setShowDrawer(false);
                          }}
                        >
                          <XMarkIcon
                            className="h-5 w-5 cursor-pointer"
                            aria-hidden="true"
                          />
                        </Button>
                      </div>
                      <div className="flex-1 p-3">Something</div>
                      <div className="sticky bottom-0 flex items-center gap-x-2 p-3 border-t">
                        <Button
                          className="w-full"
                          onClick={() => {
                            alert("SEND SMS");
                            setNewMessage(false);
                            setShowDrawer(false);
                          }}
                        >
                          Send SMS
                        </Button>
                      </div>
                    </div>
                  )
                ) : (
                  <Fragment>
                    <div className="sticky px-3 py-2 border-b flex items-center justify-between">
                      <h1 className="font-bold">Messages</h1>
                      <div className="flex items-center gap-x-2">
                        <Button variant="ghost" onClick={() => onClickSpan()}>
                          <ArrowsPointingOutIcon className="h-5 w-5 text-gray-500" />
                        </Button>
                        <Button
                          variant="ghost"
                          onClick={() => setShowDrawer(false)}
                        >
                          <XMarkIcon className="h-5 w-5 text-gray-500" />
                        </Button>
                      </div>
                    </div>
                    <ButtonGroup
                      customClassName="p-3"
                      buttons={[
                        {
                          title: "Team",
                          isActive: tab === "team",
                          onClick: () => setTab("team"),
                        },
                        {
                          title: "SMS",
                          isActive: tab === "sms",
                          onClick: () => setTab("sms"),
                        },
                      ]}
                    />

                    <div className="flex-1 overflow-y-auto show_table_scrollbar">
                      {loading ? (
                        <Fragment>
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                          <Skeleton className="h-16 mb-1 rounded-none" />
                        </Fragment>
                      ) : (
                        conversations.map((conversation) => {
                          return (
                            <Conversation
                              onClick={() => {
                                setConversationId(conversation.id);
                              }}
                              key={conversation.id}
                              conversation={conversation}
                            />
                          );
                        })
                      )}
                    </div>
                    <div className="p-3 border-t">
                      <Button
                        className="w-full"
                        variant="secondary"
                        onClick={() => setNewMessage(true)}
                      >
                        New Message
                      </Button>
                    </div>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        }
      />
    </Fragment>
  );
};

export default ChatDrawer;
