// for now, only going to allow the first dashboard's adjustments to save to local storage, but can hit reset button to start over
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { parseLayout } from "./components/editor/layoutUtils";
import { userStore } from "../../../../stores";

import {
  PencilSquareIcon,
  TrashIcon,
  PlusIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";

import {
  companyDashboardLayouts,
  softwareDashboardLayouts,
} from "./defaultDashboards";

import {
  inventoryWidgetsList,
  financeWidgetsList,
  taskWidgetsList,
  customWidgetsList,
} from "./components/widgets/widgets";
import { ICON_MAP } from "./components/iconMap";

import Select from "../../../../components/ui/Select";
import Button from "../../../../components/ui/Button";
import Tooltip from "../../../../components/ui/Tooltip";
import DashboardLayout from "./DashboardLayout";
import AddCustomWidgetModal from "./components/widgets/AddCustomWidget";
import EditWidgetPopover from "./components/widgets/EditWidgetPopover";
import EditDashboardNameModal from "./components/editor/EditDashboardName";
import AddNewDashboardModal from "./components/editor/AddNewDashboard";
import DeleteDashboardModal from "./components/editor/DeleteDashboard";
import DeleteWidgetModal from "./components/widgets/DeleteWidget";

const DashboardEditor = () => {
  const user = userStore((state) => state.user);
  //   console.log(user.company.companyType);
  const navigate = useNavigate();

  const [modal, setModal] = useState({ name: null, id: null });
  const closeModal = () => setModal({ name: null, id: null });

  const dashboardLayouts =
    user.company.companyType === "software"
      ? softwareDashboardLayouts
      : companyDashboardLayouts;
  const [layout, setLayout] = useState(dashboardLayouts[0]);

  const handleSelectionChange = (name) => {
    const foundLayout = dashboardLayouts.find((item) => item.name === name);
    setLayout(foundLayout);
    if (foundLayout.name === "customTest") {
      // if editable, get the edited layout from storage
      const savedLayout = localStorage.getItem("dashboard-layout");
      if (savedLayout) {
        try {
          const parsedLayout = parseLayout(savedLayout);
          setLayout(parsedLayout);
        } catch (e) {
          console.error("Error parsing saved layout:", e);
          // fall back to default layout
          setLayout(foundLayout);
        }
      } else {
        setLayout(foundLayout);
      }
    } else {
      // if not editable, set layout to the default layout
      setLayout(foundLayout);
    }
  };

  /*   useEffect(() => {
    // load saved layout from localStorage when component mounts
    const savedLayout = localStorage.getItem("dashboard-layout");
    if (savedLayout) {
      setLayout(JSON.parse(savedLayout));
    }
  }, []); */

  // handleSaveLayout needs to keep the info that goes in the block which doesn't come with the newLayout argument
  /* const handleSaveLayout = (newLayout) => {
    console.log(newLayout);
    const updatedlayout = layout.map((block) => {
      const matchingLayoutItem = newLayout.find(
        (layoutItem) => layoutItem.i === block.i
      );
      if (matchingLayoutItem) {
        return {
          ...block,
          x: matchingLayoutItem.x,
          y: matchingLayoutItem.y,
          w: matchingLayoutItem.w,
          h: matchingLayoutItem.h,
        };
      } else {
        return block;
      }
    });
    console.log(updatedlayout);
    setLayout(updatedlayout);
    if (selectedLayout.isEditable) {
      localStorage.setItem("dashboard-layout", JSON.stringify(updatedlayout));
    }
  }; */

  const [newCounter, setNewCounter] = useState(
    localStorage.getItem("dashboard-layout")
      ? localStorage.getItem("dashboard-layout").length
      : 0
  );
  const handleAddItem = (
    name,
    type = "summary",
    icon = BoltIcon,
    value = 0,
    chartType
  ) => {
    const existingDetails = layout?.blocks || [];

    const newBlock = {
      i: "n" + newCounter,
      x: (existingDetails.length % 4) * 1, // Place new block next to previous, reset after 4 columns
      y: Infinity, // puts it at the bottom
      w: type === "chart" ? 2 : 1,
      h: type === "chart" ? 2 : 1.2,
      type,
      chartType,
      name,
      value,
      icon,
    };

    const newLayout = {
      ...layout,
      blocks: [...existingDetails, newBlock],
    };

    setLayout(newLayout);
    if (layout.name === "customTest") {
      localStorage.setItem("dashboard-layout", JSON.stringify(newLayout));
    }
    setNewCounter(newCounter + 1);
  };

  // initial widget lists

  // filtered widget lists in state
  const [financeWidgets, setFinanceWidgets] = useState(financeWidgetsList);
  const [inventoryWidgets, setInventoryWidgets] =
    useState(inventoryWidgetsList);
  const [taskWidgets, setTaskWidgets] = useState(taskWidgetsList);
  const [customWidgets, setCustomWidgets] = useState(customWidgetsList);

  // get only widgets that aren't on the dashboard
  useEffect(() => {
    setFinanceWidgets(
      financeWidgetsList.filter(
        (widget) =>
          !layout?.blocks?.some(
            (block) => block.name === widget.name && block.type === widget.type
          )
      )
    );
    setInventoryWidgets(
      inventoryWidgetsList.filter(
        (widget) =>
          !layout?.blocks?.some(
            (block) => block.name === widget.name && block.type === widget.type
          )
      )
    );
    setTaskWidgets(
      taskWidgetsList.filter(
        (widget) =>
          !layout?.blocks?.some(
            (block) => block.name === widget.name && block.type === widget.type
          )
      )
    );
    setCustomWidgets(
      customWidgetsList.filter(
        (widget) =>
          !layout?.blocks?.some(
            (block) => block.name === widget.name && block.type === widget.type
          )
      )
    );
  }, [layout]);

  // group the widgets so that they can use the same code when mapped over
  const [widgetGroups, setWidgetGroups] = useState();
  useEffect(() => {
    setWidgetGroups([
      { name: "Inventory", widgets: inventoryWidgets },
      { name: "Finance", widgets: financeWidgets },
      { name: "Tasks", widgets: taskWidgets },
    ]);
  }, [inventoryWidgets, financeWidgets, taskWidgets]);

  return (
    <>
      {(user?.company && (
        <div className="w-full min-h-screen">
          <div className="flex  items-center justify-start p-2">
            <Select
              options={dashboardLayouts.map((layout) => ({
                value: layout.name,
                label: layout.label,
              }))}
              defaultValue={dashboardLayouts[0].name}
              className="m-0 inline-flex md:w-4/12 lg:w-2/12 text-md mr-2"
              onChange={(value) => {
                handleSelectionChange(value);
              }}
            />
            <Button
              size="sm"
              variant="ghost"
              onClick={() => setModal({ name: "editLayout" })}
            >
              <PencilSquareIcon className="h-5 w-5" />
            </Button>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => setModal({ name: "addLayout" })}
            >
              <PlusIcon className="h-5 w-5" />
            </Button>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => setModal({ name: "deleteLayout" })}
            >
              <TrashIcon className="h-5 w-5" />
            </Button>
            <Button size="sm" variant="light" className={"ml-3"}>
              Save
            </Button>
          </div>
          <div className="grid grid-cols-12 h-[80vh]">
            <div className="col-span-4 md:col-span-4 lg:col-span-2 flex flex-col px-3 h-full">
              <section className="mb-10 h-[50vh]">
                <h3 className="text-gray-500 tracking-wider text-xs font-medium pb-1 pt-2  px-2">
                  Standard Widgets
                </h3>
                <div className=" rounded-md p-0 h-full overflow-auto border bg-gray-100">
                  {widgetGroups?.map((group) => (
                    <div key={group.name}>
                      <h4 className="text-center font-semibold text-gray-600 mx-5 text-sm mt-2">
                        {group.name}
                      </h4>
                      <ul className="mt-1 p-2 rounded-sm min-h-[45px]">
                        {group.widgets.map((widget) => (
                          <li
                            key={widget.id}
                            className="min-h-[30px] cursor-pointer border rounded-md bg-white text-gray-800 border-gray-400 mb-2 mx-2 p-2 hover:bg-slate-100 flex gap-2 justify-start items-center overflow-hidden whitespace-wrap text-overflow-ellipsis"
                            onClick={() =>
                              handleAddItem(
                                widget.name,
                                widget.type,
                                widget.icon,
                                0,
                                widget.chartType
                              )
                            }
                          >
                            <div
                              className={`rounded-md p-1 md:p-1 bg-gray-400 ${
                                widget.type === "chart"
                                  ? "border-2 border-orange-300"
                                  : "border-2 border-white"
                              }`}
                            >
                              {ICON_MAP[widget.icon] ? (
                                React.createElement(ICON_MAP[widget.icon], {
                                  className: "h-3 w-3 md:h-3 md:w-3 text-white",
                                  "aria-hidden": "true",
                                })
                              ) : (
                                // to fix unknown bug for for the chart's icon
                                <widget.icon
                                  className="h-3 w-3 md:h-3 md:w-3 text-white"
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                            <div className="text-xs">
                              {widget.name}{" "}
                              {widget.type === "chart" && <span>(chart)</span>}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </section>
              <section className="mb-6 h-[20vh] relative">
                <h3 className="text-gray-500 tracking-wider text-xs font-medium pb-1 pt-2  px-2">
                  Custom Widgets
                </h3>
                <div className="rounded-md p-0 h-full overflow-auto border bg-gray-100">
                  <ul className="mt-1 p-2 rounded-sm min-h-[45px]">
                    {customWidgets.map((widget) => (
                      <li
                        key={widget.id}
                        className="min-h-[30px] cursor-pointer border rounded-md bg-white text-gray-800 border-gray-400 mb-2 mx-2 p-2 hover:bg-slate-100 flex gap-2 justify-between items-center overflow-hidden whitespace-wrap text-overflow-ellipsis"
                        onClick={() =>
                          handleAddItem(
                            widget.name,
                            widget.type,
                            widget.icon,
                            0,
                            widget.chartType
                          )
                        }
                      >
                        <section className="flex gap-2 justify-between items-center overflow-hidden whitespace-wrap text-overflow-ellipsis">
                          <div
                            className={`rounded-md p-1 md:p-1 bg-gray-400 ${
                              widget.type === "chart"
                                ? "border-2 border-orange-300"
                                : "border-2 border-white"
                            }`}
                          >
                            {ICON_MAP[widget.icon] ? (
                              React.createElement(ICON_MAP[widget.icon], {
                                className: "h-3 w-3 md:h-3 md:w-3 text-white",
                                "aria-hidden": "true",
                              })
                            ) : (
                              <widget.icon
                                className="h-3 w-3 md:h-3 md:w-3 text-white"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          <div className="text-xs">
                            {widget.name}{" "}
                            {widget.type === "chart" && <span>(chart)</span>}
                          </div>
                        </section>
                        <EditWidgetPopover setModal={setModal} id={widget.id} />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="absolute right-2 -bottom-6 ">
                  <Tooltip
                    content="Add New Custom Widget"
                    position="bottom"
                    effect="solid"
                    border={true}
                    type="light"
                  >
                    <Button
                      size="xs"
                      variant="light"
                      mode="outlined"
                      className={"py-1 px-2 flex gap-1 items-center bg-white"}
                      onClick={() => setModal({ name: "addCustomWidget" })}
                    >
                      <PlusIcon className="h-3 w-3" /> New
                    </Button>
                  </Tooltip>
                </div>
              </section>
            </div>
            <DashboardLayout
              layout={layout}
              setLayout={setLayout}
              editMode={true}
            />
          </div>

          <EditDashboardNameModal
            open={modal.name === "editLayout"}
            closeModal={closeModal}
            layout={layout}
          />
          <AddNewDashboardModal
            open={modal.name === "addLayout"}
            closeModal={closeModal}
          />
          <DeleteDashboardModal
            open={modal.name === "deleteLayout"}
            closeModal={closeModal}
            layout={layout}
          />
          <DeleteWidgetModal
            open={modal.name === "deleteWidget"}
            closeModal={closeModal}
            customWidgets={customWidgets}
            modal={modal}
          />
          <AddCustomWidgetModal
            open={modal.name === "addCustomWidget"}
            closeModal={closeModal}
            setCustomWidgets={setCustomWidgets}
          />
        </div>
      )) || (
        <div className="w-full h-screen flex flex-col items-center justify-center">
          <div className="text-xl font-black">
            Company does not seem to exist
          </div>
          <div className="mt-5">
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default DashboardEditor;
