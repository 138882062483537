import { Fragment, useEffect, useRef, useState } from "react";

import { Dialog, Menu, Popover, Switch, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import {
  ALTERNATE_PRIMARY_COLOR,
  LABEL_COLOR,
  LINK_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_COLOR,
  SECONDARY_TEXT_COLOR,
  SUBTLE_COLOR,
} from "../../theme";
import { twMerge } from "tailwind-merge";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Input = ({
  id,
  type,
  name,
  placeholder,
  defaultValue,
  autoComplete,
  required,
  className,
  label,
  onChange,
  value,
  step,
  readOnly,
  inputRef,
  min,
  max,
  labelClassname,
  onBlur,
}) => {
  const types = {
    text: "text",
    email: "email",
    password: "password",
    checkbox: "checkbox",
    number: "number",
    datetime: "datetime-local",
    date: "date",
    color: "color",
  };

  const [focus, setFocus] = useState(false);

  return (
    <>
      {label !== "" && (
        <label
          htmlFor={name}
          className={`${labelClassname} my-2 block text-sm font-medium`}
          style={{
            color: `${LABEL_COLOR}`,
          }}
        >
          {label}
        </label>
      )}
      <input
        id={id || ""}
        name={name || ""}
        type={types[type] || "text"}
        step={(type === "number" && step) || 0}
        autoComplete={autoComplete}
        required={required || false}
        placeholder={placeholder}
        defaultValue={defaultValue}
        min={min}
        max={max}
        className={twMerge(
          "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none sm:text-sm",
          className,
          readOnly && "cursor-not-allowed"
        )}
        style={{
          borderColor: `${(focus && PRIMARY_COLOR) || ""}`,
          color: `${PRIMARY_TEXT_COLOR}`,
        }}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setFocus(false);
        }}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        ref={inputRef}
      />
    </>
  );
};

export const TextArea = ({
  id,
  name,
  placeholder,
  defaultValue,
  autoComplete,
  required,
  className,
  label,
  onChange,
  value,
  cols,
  rows,
  textareaRef,
  spellCheck,
  autoCorrect,
  autoCapitalize,
  wrap,
  onBlur,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      {label !== "" && (
        <label
          htmlFor={name}
          className="block text-sm font-medium mb-2"
          style={{
            color: `${LABEL_COLOR}`,
          }}
        >
          {label}
        </label>
      )}
      <textarea
        id={id || ""}
        name={name || ""}
        type={"text"}
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        wrap={wrap}
        required={required || false}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none sm:text-sm ${className}`}
        cols={cols}
        style={{
          borderColor: `${(focus && PRIMARY_COLOR) || ""}`,
          color: `${PRIMARY_TEXT_COLOR}`,
        }}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setFocus(false);
        }}
        onChange={onChange}
        rows={rows || "6"}
        value={value}
        ref={textareaRef}
      >
        {value}
      </textarea>
    </>
  );
};

export const Link = ({ to, text }) => {
  const [hover, setHover] = useState(false);

  return (
    <a
      href={to}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className={"font-medium"}
      style={{
        color: LINK_COLOR + `${(hover && "90") || ""}`,
      }}
    >
      {text}
    </a>
  );
};

export const InternalLink = ({ onClick, text }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className={"cursor-pointer font-medium"}
      style={{
        color: LINK_COLOR + `${(hover && "90") || ""}`,
      }}
    >
      {text}
    </div>
  );
};

export const Button = ({
  text = "",
  onClick,
  type,
  loading = false,
  active = true,
  buttonType,
  className = "",
  size = "sm",
  leftIcon,
  rightIcon,
}) => {
  const [hover, setHover] = useState(false);

  const types = {
    primary: PRIMARY_COLOR,
    secondary: SECONDARY_COLOR,
    alternatePrimary: ALTERNATE_PRIMARY_COLOR,
    subtle: SUBTLE_COLOR,
  };

  const buttonClick = () => {
    if (active) {
      onClick();
    }
  };

  let sizeClass = "";
  if (size === "sm") {
    sizeClass = "px-3 py-2 text-sm";
  } else if (size === "md") {
    sizeClass = "py-2 px-4 text-md";
  } else if (size === "lg") {
    sizeClass = "py-2 px-5  text-lg";
  } else if (size === "xl") {
    sizeClass = "py-3 px-5 text-xl";
  } else {
    sizeClass = "text-xs p-1.5 pr-3";
  }

  const BG = type ? types[type] : PRIMARY_COLOR;
  return (
    <>
      <button
        className={`${className} flex w-auto items-center justify-center ${sizeClass} rounded-md border border-transparent font-medium text-white shadow-sm transition-all focus:outline-none focus:ring-2 focus:ring-offset-2`}
        style={{
          color: `${SECONDARY_TEXT_COLOR}`,
          backgroundColor:
            BG + `${((hover || loading || !active) && "90") || ""}`,
          cursor: active ? "pointer" : "not-allowed",
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={onClick && buttonClick}
        type={buttonType}
      >
        {!loading && leftIcon && leftIcon}
        {(!loading && text) || <Spinner />}
        {!loading && rightIcon && rightIcon}
      </button>
    </>
  );
};

export const InfoCard = ({ type, message, forever }) => {
  const [showInfoCard, setShowInfoCard] = useState(false);

  useEffect(() => {
    if (message !== "") {
      setShowInfoCard(true);
      if (!forever) {
        setTimeout(() => {
          setShowInfoCard(false);
        }, 10000);
      }
    } else {
      setShowInfoCard(false);
    }
  }, [message]);

  const types = {
    success: "green",
    error: "red",
    info: "purple",
  };
  const borderType = `${
    (types[type] === "red" && "border-red-300") ||
    (types[type] === "green" && "border-green-300") ||
    (types[type] === "purple" && "border-purple-300") ||
    "border-green-300"
  }`;
  const backgroundType = `${
    (types[type] === "red" && "bg-red-50") ||
    (types[type] === "green" && "bg-green-50") ||
    (types[type] === "purple" && "bg-purple-50") ||
    "bg-green-50"
  }`;
  const textType = `${
    (types[type] === "red" && "text-red-500") ||
    (types[type] === "green" && "text-green-500") ||
    (types[type] === "purple" && "text-purple-500") ||
    "text-green-500"
  }`;

  return (
    <div
      className={`rounded-lg border ${borderType} ${backgroundType} mt-2 p-3 shadow-lg ${
        (!showInfoCard && "hidden") || ""
      }`}
    >
      <div className="flex items-center space-x-2">
        <div>
          {type === "success" && (
            <CheckIcon className={`h-6 w-6 ${textType}`} />
          )}
          {type === "error" && <XMarkIcon className={`h-6 w-6 ${textType}`} />}
          {type === "info" && (
            <ExclamationCircleIcon className={`h-6 w-6 ${textType}`} />
          )}
        </div>
        <div className="">
          <span
            className="block"
            style={{
              color: `${PRIMARY_TEXT_COLOR}`,
            }}
          >
            {message}
          </span>
        </div>
      </div>
    </div>
  );
};

export const Spinner = ({ size = "medium", className = "" }) => {
  return (
    <div role="status" className={className + " transition-all"}>
      <svg
        aria-hidden="true"
        className={`animate-spin font-black text-white ${
          size === "large"
            ? "h-14 w-14"
            : size === "small"
            ? "h-3 w-3"
            : size === "medium"
            ? "h-5 w-5"
            : "h-3 w-3"
        }`}
        style={{ fill: `${PRIMARY_COLOR}` }}
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const ImageUpload = ({
  id,
  name,
  onChange,
  label,
  multiple,
  className,
  labelClassName,
}) => {
  const [hover, setHover] = useState(false);
  const fileRef = useRef(null);

  const clickFileInput = () => {
    fileRef.current.click();
  };

  return (
    <>
      {label !== "" && (
        <label
          htmlFor={name}
          className={labelClassName + " block text-sm font-medium"}
          style={{
            color: `${LABEL_COLOR}`,
          }}
        >
          {label}
        </label>
      )}
      <div
        className={
          className +
          " flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5"
        }
      >
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
              style={{
                color: LINK_COLOR + `${(hover && "90") || ""}`,
              }}
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
            >
              <span onClick={clickFileInput}>
                Upload {(multiple && <span>files</span>) || <span>a file</span>}
              </span>
              <input
                id={id}
                name={name}
                type="file"
                className="sr-only"
                onChange={onChange}
                ref={fileRef}
                multiple={multiple}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
    </>
  );
};

export const Steps = ({ steps }) => {
  return (
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {steps &&
          steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(
                stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                "relative"
              )}
            >
              {step.status === "complete" ? (
                <>
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div
                      className="h-0.5 w-full"
                      style={{
                        backgroundColor: PRIMARY_COLOR,
                      }}
                    />
                  </div>
                  <a
                    href="/"
                    className="relative flex h-8 w-8 items-center justify-center rounded-full hover:bg-indigo-900"
                    style={{
                      backgroundColor: PRIMARY_COLOR,
                    }}
                  >
                    <CheckIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </a>
                </>
              ) : step.status === "current" ? (
                <>
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a
                    href="/"
                    className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white"
                    aria-current="step"
                    style={{
                      borderColor: PRIMARY_COLOR,
                    }}
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full"
                      aria-hidden="true"
                      style={{
                        backgroundColor: PRIMARY_COLOR,
                      }}
                    />
                    <span className="sr-only">{step.name}</span>
                  </a>
                </>
              ) : (
                <>
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a
                    href="/"
                    className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </a>
                </>
              )}
            </li>
          ))}
      </ol>
    </nav>
  );
};

// pass in a key prop with Date.now() as the value to prevent issue where same message will not make Notification appear more than once

export const Notification = ({ type, title, message }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message !== "") {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 10000);
    } else {
      setShow(false);
    }
  }, [message]);

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0  top-12 z-50 flex px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {type === "success" && (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    )}
                    {type === "error" && (
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    )}
                    {type === "info" && (
                      <InformationCircleIcon
                        className="h-6 w-6 text-yellow-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{message}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export const Dropdown = ({
  title,
  options,
  onClick,
  dropDownPosition,
  className,
}) => {
  const positions = {
    left: "",
    right: "translate-x-full",
    center: "translate-x-1/2",
    top: "-top-[600%]",
    bottom: "translate-y-full",
    topLeft: "",
    topRight: "translate-x-full",
    bottomLeft: "translate-y-full",
    bottomRight: "translate-x-full translate-y-full",
  };
  return (
    <Menu as="div" className={`${className}  relative inline-block text-left`}>
      <div>
        <Menu.Button
          className={`inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100`}
        >
          <div className="flex-shrink-0">{title}</div>
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 flex-shrink-0"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${
            dropDownPosition && positions[dropDownPosition]
          } absolute right-0  w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="w-full py-1">
            {options?.length > 0 &&
              options?.map((option, idx) => {
                return (
                  <Menu.Item key={idx}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex cursor-pointer items-center px-4 py-2 text-sm"
                        )}
                        key={idx}
                        onClick={() => {
                          onClick(option.href);
                        }}
                      >
                        {option.icon && (
                          <option.icon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                        {option.image && (
                          <img
                            src={option?.image}
                            className="mr-3 h-10 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                        {option.name}
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export const Pagination = ({
  onNext,
  onPrevious,
  start,
  to,
  totalRecords,
  limit,
  changeLimit,
}) => {
  return (
    <nav
      className="flex w-full items-center justify-between border-t border-gray-200 bg-white px-2 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{start < 1 ? 1 : start}</span>{" "}
          to <span className="font-medium">{to}</span> of{" "}
          <span className="font-medium">{totalRecords}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-start md:justify-end">
        <Dropdown
          title={limit.find((option) => option.current)?.name}
          options={limit}
          onClick={changeLimit}
          className="mr-3"
          dropDownPosition="top"
        />
        <div
          onClick={onPrevious}
          className="relative inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <div
          onClick={to ? onNext : null}
          className="relative ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      </div>
    </nav>
  );
};

export const Modal = ({
  open,
  setOpen,
  ui,
  title,
  modalStyle,
  placement,
  contentClassName,
  customCancel,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`flex min-h-full justify-center p-4 text-center sm:p-0 ${
              placement === "top" ? "mt-20 items-start" : "items-center"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  "relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-xl md:max-w-2xl",
                  modalStyle
                )}
              >
                {title !== "" && (
                  <div className="absolute flex w-full justify-between p-5">
                    <div className="text-xl font-semibold text-gray-600">
                      {title}
                    </div>
                    <div>
                      {(customCancel && (
                        <div
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          {customCancel}
                        </div>
                      )) || (
                        <XMarkIcon
                          className="h-6 w-6 cursor-pointer text-gray-500 transition-all hover:text-black"
                          onClick={() => {
                            setOpen(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={twMerge(
                    "mt-5 bg-white px-4 py-4",
                    contentClassName
                  )}
                >
                  {ui}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const Toggle = ({ title, enabled, setEnabled }) => {
  return (
    <div className="flex items-center space-x-2">
      <>
        <label htmlFor="current-tab" className="block text-sm font-medium">
          {title}
        </label>
      </>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2"
        )}
        style={{
          backgroundColor: `${(enabled && PRIMARY_COLOR) || LABEL_COLOR}`,
        }}
      >
        <span className="sr-only">Use setting</span>
        <span
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        >
          <span
            className={classNames(
              enabled
                ? "opacity-0 duration-100 ease-out"
                : "opacity-100 duration-200 ease-in",
              "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-gray-400"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={classNames(
              enabled
                ? "opacity-100 duration-200 ease-in"
                : "opacity-0 duration-100 ease-out",
              "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </div>
  );
};

export const FlyOut = ({
  title,
  titleClassNames,
  small,
  normal,
  titleUI,
  ui = true,
}) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group inline-flex items-center rounded-md text-base font-medium hover:text-gray-900 focus:outline-none"
            )}
          >
            {(!titleUI && (
              <span className="flex">
                <span className={titleClassNames}>{title}</span>
                <ChevronDownIcon
                  className={classNames(
                    open ? "text-gray-600" : "text-gray-400",
                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                  )}
                  aria-hidden="true"
                />
              </span>
            )) ||
              titleUI}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`absolute ${
                normal === true ? "" : "left-1/2 -translate-x-1/2 "
              }  z-10 mt-3 w-screen ${
                small === true ? "max-w-max" : "max-w-md"
              } transform sm:px-0`}
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white  pt-6 ">{ui}</div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
