import { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";

const BorderRadius = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [cornerRadius, setCornerRadius] = useState("");

  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleObjectCorner = (e) => {
      const obj = e.target;
      setCornerRadius(String(obj.rx || 0)); // Assuming 'rx' is the property name for corner radius
    };

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        setCornerRadius(String(obj.rx || 0));
      }
    };

    currentCanvas?.on("object:modified", handleObjectCorner);
    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("object:modified", handleObjectCorner);
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleBlur = () => {
    updateCornerRadius();
  };

  const handleChange = (e) => {
    setCornerRadius(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateCornerRadius();
    }
  };

  const updateCornerRadius = () => {
    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && cornerRadius !== "") {
      activeObjects.forEach((obj) => {
        if (obj.type === "rect") {
          obj.set("rx", parseFloat(cornerRadius));
          obj.set("ry", parseFloat(cornerRadius)); // Assuming you want the same value for both x and y radius
        }
        obj.setCoords();
      });
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Border Radius</Label>
      <Input
        value={cornerRadius}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        className="w-full border border-slate-300"
      />
    </section>
  );
};

export default BorderRadius;
