import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Modal,
  InfoCard,
} from "../../../../../../../../../../../../components/ui";
import Button from "../../../../../../../../../../../../components/ui/Button";
import InputField from "../../../../../../../../../../../../components/fields/InputField";
import ButtonGroupField from "../../../../../../../../../../../../components/fields/ButtonGroupField";
import { startCase } from "lodash";

const AddFontModal = ({ open, setOpen, addFont }) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({ message: "", type: "", title: "" });

  const [fontName, setFontName] = useState("");
  const defaultValues = { fontName: "", previewing: "default_font" };
  const { handleSubmit, control, reset, watch, setValue } =
    useForm(defaultValues);
  const watchedFontName = watch("fontName");
  const watchedPreviewing = watch("previewing", "default_font");

  const addGoogleFont = async (name) => {
    try {
      // Remove the previous <link> if exists
      const oldLink = document.getElementById("google-font-link");
      if (oldLink) {
        document.head.removeChild(oldLink);
      }

      // Create a new <link> element
      const link = document.createElement("link");

      // Set an id for this link element to find it later
      link.id = "google-font-link";

      // Form the URL for the Google Font
      const formattedName = name.replace(" ", "+");
      // &display=swap
      const url = `https://fonts.googleapis.com/css2?family=${formattedName}`;

      // Check if the font can be loaded
      const response = await fetch(url);
      if (response.ok) {
        // Set the href attribute to point to the Google Font URL
        link.href = url;

        // Set the rel attribute to "stylesheet"
        link.rel = "stylesheet";

        // Attach the <link> element to the <head> of the document
        document.head.appendChild(link);

        // Add the fontName to local state
        setFontName(name);
        setValue("previewing", "new_font");
      } else {
        // Log the URL of the Google Font that failed to load
        console.error("Failed to load Google Font:", url);

        // Log the status code of the response
        console.error("Response status code:", response.status);

        // Log the response body
        console.error("Response body:", await response.text());

        setInfo({
          message: "Failed to load the font",
          type: "error",
          title: "Error",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setInfo({ message: "", type: "", title: "" });
      setInfo({
        message: "An error occurred while fetching that font.",
        type: "error",
        title: "Error",
      });
      setTimeout(() => {
        setInfo({ message: "", type: "", title: "" });
      }, 4000);
    }
  };

  const onCancel = () => {
    setOpen(false);
    reset();
    setFontName("");
  };

  const onSubmit = (data) => {
    addFont(fontName);
    setOpen(false);
    reset();
    setFontName("");
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      addGoogleFont(watchedFontName);
    }
  };

  return (
    <>
      <Modal
        title="Import New Font"
        open={open}
        setOpen={() => setOpen(false)}
        modalStyle="md:max-w-md overflow-y-auto"
        placement="top"
        ui={
          <div>
            <div className="mt-8 text-gray-600 px-2">
              Note: Enter in Google font names with spaces between words. Many
              fonts don't work yet. This is a work in progress.
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="px-1 mt-2">
              <div className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="fontName"
                  rules={{
                    required: "Required",
                  }}
                  render={(props) => (
                    <InputField
                      {...props}
                      className="mb-4"
                      label="Name"
                      placeholder="Enter Google Font Name"
                      required
                      inputProps={{ onKeyDown: handleOnKeyDown }}
                    />
                  )}
                />
                <Button
                  className="mt-8"
                  onClick={() => addGoogleFont(watchedFontName)}
                >
                  Test
                </Button>
              </div>
              <InfoCard
                type={info.type}
                message={info.message}
                title={info.title}
              />
              <section className="border px-4 pb-4 rounded-md mt-5 min-h-[200px]">
                <Controller
                  control={control}
                  name="previewing"
                  render={(props) => (
                    <ButtonGroupField
                      {...props}
                      label="Preview:"
                      className="mb-4"
                      disabled={!fontName}
                      buttonsClassName="py-[1px] px-1"
                      options={[
                        {
                          name: "Default Font",
                          value: "default_font",
                        },
                        {
                          name: startCase(fontName) || "New Font",
                          value: "new_font",
                        },
                      ]}
                    />
                  )}
                />

                <div
                  className="text-lg"
                  style={{
                    fontFamily:
                      watchedPreviewing === "new_font" ? fontName : "inherit",
                  }}
                >
                  The quick brown fox jumps over the dog to get to his
                  destination.
                </div>
              </section>
              <div className="flex justify-end mt-10 gap-x-2">
                <Button variant="ghost" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  isLoading={loading}
                  loadingText="Saving..."
                  type="submit"
                >
                  Add
                </Button>
              </div>
            </form>
          </div>
        }
      />
    </>
  );
};

export default AddFontModal;
