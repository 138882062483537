import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, Modal } from "../../../../../components/ui";
import TableColumns from "../../../../../components/ui/table/TableColumns";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Bars4Icon,
  ChevronDownIcon,
  MinusCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { omit } from "lodash";
import Button from "../../../../../components/ui/Button";
import Dropdown from "../../../../../components/ui/Dropdown";

import { twMerge } from "tailwind-merge";
import AddNewProfileModal from "./AddNewProfileModal";

const ManageProfilesModal = ({
  profiles,
  setProfiles,
  show,
  toggle,
  onSuccess,
}) => {
  const [mounted, setMounted] = useState(false);
  const [addNewProfileModal, setAddNewProfileModal] = useState(false);
  const [editProfile, setEditProfile] = useState(null);
  useEffect(() => {
    setMounted(true);
  }, []);

  // Reorder views after drag and drop
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newViews = Array.from(profiles);
    const [removed] = newViews.splice(result.source.index, 1);
    newViews.splice(result.destination.index, 0, removed);
    setProfiles(newViews);
  };

  const onClose = () => {
    toggle();
  };

  const updateTitle = (e) => {
    const updatedProfiles = profiles?.map((profile, idx) => {
      if (idx === editProfile) {
        return { ...profile, title: e.target.value };
      } else {
        return profile;
      }
    });
    setProfiles(updatedProfiles);
  };

  return (
    <Modal
      setOpen={onClose}
      open={show}
      title="Manage Profiles"
      ui={
        <Fragment>
          <div className="px-1 mt-9">
            <form>
              <DragDropContext onDragEnd={onDragEnd}>
                <table className="w-full border divide-y divide-gray-300">
                  <TableColumns columns={["", "Name", "Permissions", "", ""]} />
                  {mounted ? (
                    <Droppable droppableId={"views"}>
                      {(provided) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="divide-y divide-gray-300 bg-white"
                        >
                          {profiles?.map((profile, index) => (
                            <Draggable
                              key={String(profile.id)}
                              draggableId={`draggable-${String(profile.id)}`}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  {...omit(
                                    provided.draggableProps,
                                    "style.position"
                                  )}
                                  {...provided.dragHandleProps}
                                  key={profile.id}
                                  ref={provided.innerRef}
                                >
                                  <td style={{ width: "5%" }} className="p-3">
                                    <Bars4Icon className="h-5 w-5  text-gray-500 cursor-grab" />
                                  </td>
                                  {(editProfile === index && (
                                    <td>
                                      <div className="mb-2 p-1">
                                        <Input
                                          type={"text"}
                                          name="title"
                                          onChange={updateTitle}
                                          value={profile.title}
                                        />
                                      </div>
                                    </td>
                                  )) || <td>{profile.title}</td>}
                                  {editProfile === index ? (
                                    <td>
                                      <Dropdown
                                        trigger={
                                          <Button
                                            variant="ghost"
                                            mode="outlined"
                                            className="flex items-center gap-x-2"
                                          >
                                            Permissions
                                            <ChevronDownIcon
                                              className="h-4 w-4 "
                                              aria-hidden="true"
                                            />
                                          </Button>
                                        }
                                      >
                                        <Dropdown.Item>Option 1</Dropdown.Item>
                                        <Dropdown.Item>Option 2</Dropdown.Item>
                                      </Dropdown>
                                    </td>
                                  ) : (
                                    <td>Permissions</td>
                                  )}

                                  <td style={{ width: "10%" }}>
                                    <Button
                                      variant="ghost"
                                      onClick={() => {
                                        setEditProfile(index);
                                      }}
                                    >
                                      <PencilSquareIcon
                                        className={twMerge("w-6 h-6")}
                                      />
                                    </Button>
                                  </td>
                                  <td style={{ width: "5%" }}>
                                    <Button
                                      variant="ghost"
                                      onClick={() => {
                                        const newViews = [...profiles];
                                        newViews.splice(index, 1);
                                        setProfiles(newViews);
                                      }}
                                    >
                                      <MinusCircleIcon className="w-6 h-6 text-red-500" />
                                    </Button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  ) : null}
                </table>
                <div className="flex justify-end mt-5">
                  <Button
                    mode="outlined"
                    onClick={() => {
                      setProfiles([...profiles, {}]);
                      setEditProfile(profiles?.length);
                    }}
                  >
                    New Profile with Current Permissions
                  </Button>
                </div>
              </DragDropContext>

              <div className="flex items-center gap-x-2 p-3 justify-end  mt-4">
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setEditProfile(null);
                    console.log(profiles?.map((v) => v.id));
                    toggle();
                  }}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>

          <AddNewProfileModal
            show={addNewProfileModal}
            toggle={() => {
              setAddNewProfileModal(false);
            }}
            onSuccess={(data) => {
              console.log("DATA: ", data);
            }}
          />
        </Fragment>
      }
      placement="top"
    />
  );
};

ManageProfilesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default ManageProfilesModal;
