import { Suspense, useEffect, useState } from "react";
import { lazily } from "react-lazily";
import { Route, Routes, useLocation } from "react-router-dom";
import { MiddleTopNav } from "../../../Navigation";
import { Sidebar } from "../../../Sidebar";
import { CogIcon, HomeIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { userStore } from "../../../../stores";
import { Spinner } from "../../../../components/ui";

import Profile from "../../../Company/components/SoftwareCompanySettings/components/Management/components/Profile";

const { SoftwareMain } = lazily(() => import("../../../Main"));
const { TimePunches } = lazily(() => import("../../../User"));

const { CompaniesAdmin } = lazily(() => import("../../../Companies"));
const { SoftwareCompanySettings } = lazily(() => import("../../../Company"));

const Software = () => {
  const user = userStore((state) => state.user);
  document.title = `${user?.company?.name} Dashboard (Software Company)`;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const [refreshPage, setRefreshPage] = useState(true);
  const [currentRoute, setCurrentRoute] = useState();
  const location = useLocation();
  const routePrefix = "/dashboard";
  useEffect(() => {
    setRefreshPage(false);
  }, []);

  useEffect(() => {
    if (refreshPage) {
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    setRefreshPage(true);
    setCurrentRoute(location.pathname);
  }, [location]);

  const routes = [
    {
      name: "Dashboard",
      icon: HomeIcon,
      current: true,
      href: "",
    },
    {
      name: "Companies",
      icon: UserGroupIcon,
      href: "/companies",
      current: false,
      enableNavigation: true,
    },
    {
      name: "Settings",
      icon: CogIcon,
      current: false,
      href: "/settings/general",
    },
  ];

  return (
    <>
      <div className="">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          collapsedMenu={collapsedMenu}
          currentRoute={currentRoute}
          routePrefix={routePrefix}
          routes={routes}
        />
        <div
          className={`duration-300 ease-in-out ${
            collapsedMenu ? "md:pl-16" : "md:pl-64 "
          }`}
        >
          <MiddleTopNav
            setSidebarOpen={setSidebarOpen}
            setCollapsedMenu={setCollapsedMenu}
          />
          <main className="flex-1">
            <div className="">
              <Routes>
                <Route
                  path="/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <SoftwareMain />
                    </Suspense>
                  }
                />
                <Route
                  path="/profile"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Profile user={user} />
                    </Suspense>
                  }
                />
                <Route
                  path="/timePunches"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <TimePunches user={user} />
                    </Suspense>
                  }
                />
                <Route
                  path="/companies/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <CompaniesAdmin />
                    </Suspense>
                  }
                />
                <Route
                  path="/settings/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <SoftwareCompanySettings company={user?.company} />
                    </Suspense>
                  }
                />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Software;
