import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/app/users",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
  },
  withCredentials: true,
});

export const assignTasksToUser = async (userId, data) => {
  return await api
    .post(`/${userId}/assignTasks`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getUser = async (userId) => {
  return await api
    .get(`/${userId}`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const updateUserNotificationSettings = async (userId, details) => {
  return await api
    .patch(`/${userId}/notification-settings`, details)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const updateUser = async (userId, details) => {
  return await api
    .patch(`/${userId}`, details)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getUserTimePunches = async (userId, data) => {
  return await api
    .get(
      "/" +
        userId +
        `/timePunches?cursor=${data.cursor}&results=${data.resultsPerPage}`
    )
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getUserTimePunchesCount = async (userId) => {
  return await api
    .get("/" + userId + "/timePunches/count")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const createUserTimePunch = async (userId, timePunchDetails) => {
  return await api
    .post("/" + userId + "/timePunches", timePunchDetails)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
