import { useNavigate, useLocation } from "react-router-dom";

import { validateInvitationToken, userJoin } from "../../services/auth";

import { validateJoinData } from "../../validator";
import { Button, Input, Notification, Link } from "../../components/ui";

import { LOGO } from "../../theme";

import { userStore } from "../../stores";
import SplashScreen from "../SplashScreen";

import { useEffect, useState } from "react";

const Invitation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const invitationToken = location.pathname.split("/")[2];
  const storeUser = userStore((state) => state.storeUser);

  const [validatingToken, setValidatingToken] = useState(false);
  const [validatingTokenError, setValidatingTokenError] = useState();
  const [tokenValid, setTokenValid] = useState(false);
  const [joiningData, setJoiningData] = useState();
  const [joinUserLoading, setJoinUserLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [info, setInfo] = useState({ type: "", message: "" });

  useEffect(() => {
    if (invitationToken) {
      setValidatingToken(true);
      validateInvitationToken({ token: invitationToken }).then((response) => {
        setValidatingToken(false);
        if (response?.status === 200) {
          setTokenValid(true);
          setJoiningData(response?.data?.invitation);
        } else {
          setValidatingTokenError(response?.data?.message);
          setInfo({
            type: "error",
            title: "Error validating invitation",
            message: response?.data?.message,
          });
        }
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: joiningData?.email,
      name: formData?.name,
      password: formData?.password,
      permissions: joiningData?.permissions,
      companyId: joiningData?.companyId,
      invitationId: joiningData?.id,
    };
    const { status, message } = await validateJoinData(data);
    if (!status) {
      setInfo({
        type: "error",
        title: "Provided data not correct",
        message: message,
      });
      return;
    }
    setJoinUserLoading(true);
    userJoin(data).then((response) => {
      setJoinUserLoading(false);
      if (response?.status === 200) {
        storeUser(response?.data?.user);
        setInfo({
          type: "success",
          title: "Joined company successfully",
          message: "You have been successfully joined the company.",
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        setInfo({
          type: "error",
          title: "Error while joining company",
          message: response?.data?.message,
        });
      }
    });
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-100 p-2">
      {!validatingToken && tokenValid && (
        <div className="md:w-1/2 flex flex-col md:p-12">
          <div className="flex justify-center">
            <div>
              <img className="h-12 w-auto" src={LOGO} alt="Workflow" />
            </div>
          </div>
          <div className="bg-white py-3">
            <Notification
              title={info.title}
              type={info.type}
              message={info.message}
            />
            <div className="flex justify-center">
              <div>
                {joiningData?.company?.logo && (
                  <img
                    className="h-20 w-auto rounded border"
                    src={joiningData?.company?.logo}
                    alt="Workflow"
                  />
                )}
              </div>
            </div>
            <div className="text-center text-xl py-3 px-2">
              You've been invited to join{" "}
              <span className="font-semibold">
                {joiningData?.company?.name}{" "}
              </span>
            </div>
            <div className="text-center text-gray-500 text-smk">
              Invited by {joiningData?.invitedBy?.name}
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex justify-center w-full"
            >
              <div className="flex flex-col w-11/12 md:w-1/2 gap-y-2">
                <div>
                  <Input
                    id={"invitation-name"}
                    type={"name"}
                    name={"name"}
                    value={formData?.name || ""}
                    label="Names"
                    placeholder={"Enter your names"}
                    autoComplete={"off"}
                    required={true}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <Input
                    id={"invitation-password"}
                    type={"password"}
                    name={"password"}
                    value={formData?.password || ""}
                    label="Password"
                    placeholder={"Set your account password"}
                    autoComplete={"off"}
                    required={true}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-5">
                  <Button
                    buttonType={"submit"}
                    text={"Create account and join"}
                    loading={joinUserLoading}
                  />
                </div>
                <div className="text-center">or</div>
                <div className="text-sm text-center">
                  <Link to={""} text={"Already have an account? Sign in"} />
                </div>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      )}
      {validatingToken && <SplashScreen />}
      {!validatingToken && validatingTokenError && (
        <div className="md:w-1/3 bg-white p-12">
          <div>
            <img className="h-12 w-auto" src={LOGO} alt="Workflow" />
          </div>
          <div className="text-red-500 font-semibold tracking-wide mt-3">
            {validatingTokenError}
          </div>
          <div className="flex justify-center">
            <div className="mt-5">
              <Button
                text={"Back Home"}
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invitation;
