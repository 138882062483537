import { useState } from "react";
import { PRIMARY_COLOR } from "../../../../../theme";

export const StatContent = ({ block, IconComponent }) => {
  const [linkHover, setLinkHover] = useState();

  return (
    <div className="">
      <dt className="">
        <div
          className="absolute rounded-md p-2 md:p-3 hidden sm:block "
          style={{
            backgroundColor: PRIMARY_COLOR,
          }}
        >
          {IconComponent ? (
            <IconComponent className="h-5 w-5 text-white" aria-hidden="true" />
          ) : (
            <span className=" text-white">-</span>
          )}
        </div>
        {/* <p className="ml-0 sm:ml-16 truncate text-sm font-medium text-gray-500"> */}
        <p className="ml-0 sm:ml-16 whitespace-wrap text-sm font-medium text-gray-500">
          {block.name}
        </p>
      </dt>
      <dd className="ml-0 sm:ml-16 flex items-end pb-6 sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900 ">{block.value}</p>

        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-2 sm:px-6">
          <div className="text-sm">
            <div
              className="cursor-pointer font-medium hover:"
              style={{
                color:
                  linkHover === block.i ? `${PRIMARY_COLOR}90` : PRIMARY_COLOR,
              }}
              onMouseEnter={() => {
                // using block.i as a key
                setLinkHover(block.i);
              }}
              onMouseLeave={() => {
                setLinkHover(null);
              }}
            >
              {" "}
              View all
              <span className="sr-only"> {block.name} stats</span>
            </div>
          </div>
        </div>
      </dd>
    </div>
  );
};
