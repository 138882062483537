import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { generatePrismaQuery } from "../../../utils";
import { Modal } from "..";
import SelectField from "../../fields/SelectField";
import FiltersField from "../../fields/FiltersField";
import NumberField from "../../fields/NumberField";
import DatePickerField from "../../fields/DatePickerField";
import InputField from "../../fields/InputField";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { isEmpty, startCase } from "lodash";
import PropTypes from "prop-types";
import Button from "../Button";

const Filters = ({
  title = "Filters",
  show,
  toggle,
  keys,
  customInput,
  onSave,
  defaultValues = [],
}) => {
  const [prismaQuery, setPrismaQuery] = useState({});

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      filters: isEmpty(defaultValues)
        ? [{ field: "", operator: "", value: "" }]
        : defaultValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filters",
  });

  const onSubmit = ({ filters }) => {
    const prismaQuery = generatePrismaQuery(filters);

    setPrismaQuery(prismaQuery);
    onSave && onSave(filters);
    toggle();
  };

  const onClose = () => {
    reset({
      filters: [
        {
          field: "",
          operator: "",
          value: "",
        },
      ],
    });
    setPrismaQuery({});
    toggle();
  };

  return (
    <Modal
      setOpen={onClose}
      open={show}
      title={title}
      placement="top"
      modalStyle={`md:max-w-4xl overflow-y-auto`}
      ui={
        <div className="px-1 mt-9">
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields?.map((field, index) => {
              const currentField = watch(`filters[${index}].field`);
              const custom = customInput?.find(
                (input) => input.key === currentField
              );
              return (
                <div key={field.id}>
                  <div className="grid md:grid-cols-3 grid-cols-4 bg-slate-50 md:bg-transparent p-5 md:p-0 items-center gap-x-2 mb-2 rounded-md">
                    <Controller
                      control={control}
                      name={`filters[${index}].field`}
                      rules={{ required: "Required" }}
                      render={(props) => (
                        <SelectField
                          {...props}
                          options={keys.map((field) => ({
                            value: field,
                            label: startCase(field),
                          }))}
                          label="Field"
                          isClearable
                          placeholder="Select a field"
                          required
                          className="md:col-span-1 col-span-2"
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`filters[${index}].operator`}
                      rules={{ required: "Required" }}
                      render={(props) => (
                        <FiltersField
                          {...props}
                          label="Operator"
                          isClearable
                          placeholder="Operators.."
                          required
                          className="md:col-span-1 col-span-2"
                        />
                      )}
                    />

                    <div className="flex gap-x-2 items-center md:col-span-1 col-span-4">
                      <Controller
                        control={control}
                        name={`filters[${index}].value`}
                        rules={{ required: "Required" }}
                        render={(props) =>
                          custom?.type === "number" ? (
                            <NumberField {...props} label="Value" required />
                          ) : custom?.type === "date" ? (
                            <DatePickerField
                              {...props}
                              label="Value"
                              required
                              isClearable
                              showTimeSelect={false}
                            />
                          ) : custom?.type === "select" ? (
                            <SelectField
                              {...props}
                              options={custom?.options || []}
                              label="Value"
                              isClearable
                              isMulti={
                                watch(`filters[${index}].operator`) === "in" ||
                                watch(`filters[${index}].operator`) === "notIn"
                              }
                              required
                            />
                          ) : (
                            <InputField
                              {...props}
                              label="Value"
                              className="w-full"
                              required
                            />
                          )
                        }
                      />

                      <Button className="mt-12" onClick={() => remove(index)}>
                        <TrashIcon className="h-5 w-5" />
                      </Button>
                    </div>
                  </div>
                  {index !== fields.length - 1 && (
                    <Controller
                      control={control}
                      name={`filters[${index}].condition`}
                      rules={{ required: "Required" }}
                      render={(props) => (
                        <SelectField
                          {...props}
                          options={[
                            { value: "and", label: "AND" },
                            { value: "or", label: "OR" },
                            { value: "not", label: "NOT" },
                          ]}
                          isClearable
                          required
                          label="Condition"
                          className="md:w-1/4 w-full py-4 md:py-0 bg-slate-50 md:bg-transparent p-3 md:p-0 rounded-md my-4 md:my-4"
                          placeholder="Condition"
                        />
                      )}
                    />
                  )}
                </div>
              );
            })}

            <Button
              className="flex items-center gap-x-2 my-3"
              mode="outlined"
              onClick={() => {
                append({});
              }}
            >
              <PlusIcon className="h-5 w-5 " />
              <span>Add Field</span>
            </Button>

            <div className="flex items-center justify-end mt-3 border-t pt-3 gap-x-2">
              <Button type="submit">Submit</Button>
            </div>
          </form>

          <pre className="bg-gray-900 p-4 rounded-lg mt-4 relative">
            <code className="text-sm text-white font-mono">
              {`const results = await prisma.model.findMany({where: ${JSON.stringify(
                prismaQuery,
                null,
                2
              )}});`}
            </code>
          </pre>
        </div>
      }
    />
  );
};

Filters.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
  customInput: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
      // add more if needed
      type: PropTypes.oneOf(["select", "date", "text", "number"]),
    })
  ),
  onSave: PropTypes.func.isRequired,
  defaultValues: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      operator: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      condition: PropTypes.string,
    })
  ),
};

export default Filters;
