import { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";
// import { Toggle } from "../../../../../../../../../../components/ui";
import Toggle from "../../../../fields/ControlToggle";
import { last } from "lodash";

const Dimensions = () => {
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [toggleW, setToggleW] = useState(false);
  const [toggleH, setToggleH] = useState(false);
  const { canvasRef, isCanvasReady, lastSelectedObject } = useCanvas();

  console.log(lastSelectedObject?.featuresGroup);
  console.log(lastSelectedObject?.type);
  // get dimensions of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const checkDimensions = (obj) => {
      const canvasWidth = currentCanvas.width;
      const canvasHeight = currentCanvas.height;
      const objWidth = obj.width * obj.scaleX;
      const objHeight = obj.height * obj.scaleY;

      setToggleW(objWidth >= canvasWidth);
      setToggleH(objHeight >= canvasHeight);
    };

    const handleObjectScaling = (e) => {
      const obj = e.target;
      setWidth(String((obj.width * obj.scaleX).toFixed(1)));
      setHeight(String((obj.height * obj.scaleY).toFixed(1)));

      // Check if the dimensions have changed
      checkDimensions(obj);
    };

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        setWidth(String((obj.width * obj.scaleX).toFixed(1)));
        setHeight(String((obj.height * obj.scaleY).toFixed(1)));

        // Initialize toggle states based on object dimensions
        checkDimensions(obj);
      } else {
        // Reset toggles if no object is selected
        setToggleW(false);
        setToggleH(false);
      }
    };

    currentCanvas?.on("object:scaling", handleObjectScaling);
    currentCanvas?.on("object:modified", handleObjectScaling);
    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("object:scaling", handleObjectScaling);
      currentCanvas?.off("object:modified", handleObjectScaling);
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, setWidth, setHeight, isCanvasReady]);

  const handleDimensionChange = (newWidth, newHeight) => {
    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects) {
      activeObjects.forEach((obj) => {
        if (newWidth !== null) {
          obj.set({ width: parseFloat(newWidth) / obj.scaleX });
        }
        if (newHeight !== null) {
          obj.set({ height: parseFloat(newHeight) / obj.scaleY });
        }
        obj.setCoords();
      });
      canvasRef.current?.renderAll();
    }
  };

  // make object fill the width or height of canvas when swittch is toggled
  useEffect(() => {
    if (toggleW || toggleH) {
      const activeObjects = canvasRef.current?.getActiveObjects();
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        const canvasWidth = canvasRef.current.width;
        const canvasHeight = canvasRef.current.height;

        if (toggleW) {
          obj.scaleToWidth(canvasWidth);
          obj.set({ left: 0 }); // Set left X-coordinate to 0
        }

        if (toggleH) {
          obj.scaleToHeight(canvasHeight);
          obj.set({ top: 0 }); // Set top Y-coordinate to 0
        }

        obj.setCoords(); // Update object's bounding box
        canvasRef.current?.renderAll(); // Re-render the canvas
      }
    }
  }, [toggleW, toggleH, canvasRef]);

  return (
    <section className="flex w-full">
      <div className="w-1/2">
        <Label>Width</Label>
        <Input
          value={width}
          onChange={(e) => {
            // Just update the state without changing the dimensions
            setWidth((e.target as HTMLInputElement).value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Update the dimensions when Enter key is pressed
              handleDimensionChange((e.target as HTMLInputElement).value, null);
            }
          }}
          className="w-[55%] py-1"
        />
        {/* dont show these toggles for featuresGroup or textbox */}
        {lastSelectedObject?.type !== "textbox" &&
          lastSelectedObject?.name !== "superFeaturesGroup" && (
            <Toggle
              title={"Fill Width"}
              enabled={toggleW}
              setEnabled={() => {
                setToggleW((prev) => !prev);
              }}
            />
          )}
      </div>
      <div className="w-1/2">
        <Label>Height</Label>
        <Input
          value={height}
          onChange={(e) => {
            // Just update the state without changing the dimensions
            setHeight((e.target as HTMLInputElement).value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Update the dimensions when Enter key is pressed
              handleDimensionChange(null, (e.target as HTMLInputElement).value);
            }
          }}
          className="w-[55%] py-1"
        />
        {lastSelectedObject?.type !== "textbox" &&
          lastSelectedObject?.name !== "superFeaturesGroup" && (
            <Toggle
              title={"Fill Height"}
              enabled={toggleH}
              setEnabled={() => {
                setToggleH((prev) => !prev);
              }}
            />
          )}
      </div>
    </section>
  );
};

export default Dimensions;
