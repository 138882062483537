import React, { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

interface ButtonConfig {
  name?: string;
  icon?: ReactElement;
  onClick: () => void;
  isActive: boolean;
}

interface ButtonGroupProps {
  buttons: ButtonConfig[];
  className?: string;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ buttons, className }) => {
  const handleButtonClick = (onClick: () => void) => {
    onClick();
  };

  return (
    <div className={twMerge("flex flex-wrap", className)}>
      {buttons.map((button, index) => (
        <button
          key={index}
          className={twMerge(
            "flex-grow border border-r-0 last:border-r rounded-l-sm last:rounded-r-sm py-[2px] px-2 shadow-sm border-slate-300 text-slate-900 text-xs",
            button.isActive ? "bg-slate-200" : "bg-white"
          )}
          onClick={() => handleButtonClick(button.onClick)}
        >
          {button.icon ? button.icon : button.name}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
