import React, { useState } from "react";
import NotificationItem from "../components/flyout/NotificationItem";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import Breadcrumb from "../../../components/ui/Breadcrumb";
import { useQuery } from "react-query";
import { getNotifications } from "../api";
import { Spinner } from "../../../components/ui";
import ScrollArea from "../../../components/ui/ScrollArea";
import TablePagination from "../../../components/ui/Pagination";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

const NotificationList = () => {
  const navigate = useNavigate();

  const [params, setParams] = useState({
    page: 1,
    size: 25,
    search: "",
  });
  const { data, isLoading } = useQuery(["notifications", params], () =>
    getNotifications(params)
  );

  const items = data?.items;
  const meta = data?.meta;

  return (
    <div className="p-5">
      <div className="flex items-center justify-between">
        <Breadcrumb
          pages={[
            {
              name: "Notifications",
              href: "/dashboard/notifications",
              asLink: true,
            },
          ]}
        />
      </div>
      <div className="border rounded-md mt-5">
        <div className="flex p-2 items-center justify-between">
          <h2 className="text-lg font-bold text-gray-600">Notifications</h2>

          <div
            className="flex hover:bg-gray-200 rounded-full p-1 transition ease-in-out"
            onClick={() => {
              navigate("/dashboard/profile?tab=notifications");
            }}
          >
            <Cog6ToothIcon
              className="h-6 w-6 cursor-pointer"
              aria-hidden="true"
              color="gray"
            />
          </div>
        </div>
        <ScrollArea rootClassName={"h-[80vh] border-y"}>
          {isLoading ? (
            <div className="flex items-center justify-center h-[50vh]">
              <Spinner size="large" />
            </div>
          ) : isEmpty(items) ? (
            <p>No notifications to display</p>
          ) : (
            items.map((notification) => (
              <NotificationItem
                notification={notification}
                key={notification.id}
              />
            ))
          )}
        </ScrollArea>
        <TablePagination
          className="static"
          meta={meta}
          loading={isLoading}
          onChangePage={(page) => {
            setParams((prev) => ({
              ...prev,
              page,
            }));
          }}
          onChangeSize={(size) => {
            setParams((prev) => ({
              ...prev,
              page: 1,
              size,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default NotificationList;
