import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PRIMARY_COLOR, getPastelColor } from "../../theme";
import { twMerge } from "tailwind-merge";
import ScrollArea from "./ScrollArea";

const Sections = ({
  title,
  tabs,
  override,
  reset,
  isPastel = false,
  className,
  navClassName,
  countClassName,
  navItemClassName,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [pathname, setPathname] = useState(location?.pathname);
  const [search, setSearch] = useState(location?.search);
  // const [params, setParams] = useState(new URLSearchParams(location?.search));

  useEffect(() => {
    setPathname(location?.pathname);
    setSearch(location?.search);
    // setParams(new URLSearchParams(search));
  }, [location]);

  const updateUrl = (href) => {
    const newParams = new URLSearchParams(search);

    if (reset) {
      return `${pathname}?${href}`;
    }

    const paramsArray = href.split("=");
    const paramName = paramsArray[0];
    const paramValue = paramsArray[1];

    if (newParams.get(paramName)) {
      newParams.set(paramName, paramValue);
    } else {
      newParams.append(paramName, paramValue);
    }

    // Merge existing params with new params
    const mergedParams = new URLSearchParams(location.search);
    for (const [key, value] of newParams) {
      mergedParams.set(key, value);
    }

    return `${pathname}?${mergedParams.toString()}`;
  };

  const primaryColor = isPastel ? getPastelColor(PRIMARY_COLOR) : PRIMARY_COLOR;

  return (
    <div
      className={twMerge(
        "relative mb-5 border-b border-gray-200 sm:pb-0",
        className
      )}
    >
      <div className="md:flex md:items-center md:justify-between">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      </div>
      <div className="">
        <div className={`${override ? "hidden" : "sm:hidden"}`}>
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full bg-transparent rounded-md border-gray-100 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
            onChange={(e) => {
              navigate(updateUrl(e.target.value));
            }}
            defaultValue={tabs?.find((tab) => tab?.current)?.href}
          >
            {tabs?.map((tab) => (
              <option
                key={tab.name}
                defaultValue={tab?.current}
                value={tab.href}
              >
                {`${tab.name} ${tab.count ? `(${tab.count})` : ""}`}
              </option>
            ))}
          </select>
        </div>
        <div
          className={`${
            override ? "sm:block" : "hidden sm:block"
          } bg-transparent`}
        >
          <ScrollArea
            rootClassName="w-auto h-auto"
            scrollBarOrientation="horizontal"
            scrollAreaOrientation="horizontal"
            thumbClassName="rounded-none"
          >
            <nav className={twMerge("-mb-px flex space-x-8 ", navClassName)}>
              {tabs?.map((tab) => {
                return (
                  <div
                    onClick={() => {
                      navigate(updateUrl(tab.href));
                    }}
                    key={tab.name}
                    style={{
                      borderColor: `${
                        tab.current ? primaryColor : "transparent"
                      }`,
                      color: `${tab.current ? primaryColor : ""}`,
                    }}
                    className={twMerge(
                      "cursor-pointer whitespace-nowrap border-b-2  px-1 py-3 text-sm font-medium",
                      tab.current
                        ? ""
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      navItemClassName
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                    {tab.count && (
                      <span
                        style={{
                          background: `${
                            tab.current ? primaryColor : "#b3b3b3"
                          }`,
                          color: "white",
                        }}
                        className={twMerge(
                          "ml-1.5 font-bold text-xs rounded-md px-2 py-1",
                          countClassName
                        )}
                      >
                        {tab.count}
                      </span>
                    )}
                  </div>
                );
              })}
            </nav>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

Sections.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      current: PropTypes.bool.isRequired,
      component: PropTypes.elementType.isRequired,
      count: PropTypes.number,
    })
  ).isRequired,
  override: PropTypes.bool,
  reset: PropTypes.bool,
  className: PropTypes.string,
  isPastel: PropTypes.bool,
  navClassName: PropTypes.string,
  countClassName: PropTypes.string,
  navItemClassName: PropTypes.string,
};

export default Sections;
