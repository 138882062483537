import {
  CheckIcon,
  ChevronLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "../../../components/ui/Button";
import { Input } from "../../../components/ui";

const NewMessage = ({ back, close }) => {
  const [selected, setSelected] = useState([1, 2]);
  const [dummyMembers, setDummyMembers] = useState([
    {
      id: 1,
      name: "Mark Vergel Banguis",
    },
    {
      id: 2,
      name: "John Doe",
    },
    {
      id: 3,
      name: "Michael Jordan",
    },
  ]);

  const onSubmit = () => {
    console.log("Selected", selected);
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div className="flex items-center justify-between border-b p-2">
        <div className="flex items-center gap-x-2">
          <Button variant="ghost" onClick={back}>
            <ChevronLeftIcon className="h-5 w-5" />
          </Button>
          <h1>New Message</h1>
        </div>
        <Button variant="ghost" onClick={close}>
          <XMarkIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
        </Button>
      </div>

      <div className="flex-1 overflow-y-auto show_table_scrollbar ">
        <div className="flex flex-col border-b p-4 gap-3">
          <h2>Select Group</h2>
          <div className="flex flex-col gap-2 w-1/2">
            <Button variant="ghost" className="text-start">
              Working Today
            </Button>
            <Button variant="ghost" className="text-start">
              Working Tomorrow
            </Button>
          </div>
        </div>
        <div className="flex flex-col border-b p-4 ">
          <div className="flex items-center justify-between">
            <h2>
              Team Member Selected
              <span className="text-gray-400 text-sm ml-2">
                {selected.length} Selected
              </span>
            </h2>
            <Button
              variant="ghost"
              className="text-start"
              onClick={() => {
                if (selected.length > 0) {
                  setSelected([]);
                } else {
                  setSelected(dummyMembers.map((member) => member.id));
                }
              }}
            >
              {selected.length > 0 ? "Clear All" : "Select All"}
            </Button>
          </div>
          <Input placeholder="Search Team Members" className="mb-3" />

          <div className="flex flex-col gap-3 px-3">
            {dummyMembers.map((member) => (
              <div
                key={member.id}
                onClick={() => {
                  if (selected.includes(member.id)) {
                    setSelected(selected.filter((id) => id !== member.id));
                  } else {
                    setSelected([...selected, member.id]);
                  }
                }}
                className="flex items-center justify-between cursor-pointer hover:bg-gray-100 rounded-md p-2 px-3"
              >
                <h3>{member.name}</h3>
                {selected.includes(member.id) && (
                  <CheckIcon className="h-5 w-5 text-green-500" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="sticky bottom-0 flex items-center gap-x-2 p-3 border-t">
        <Button
          className="w-full"
          onClick={onSubmit}
          disabled={selected.length === 0}
        >
          Start Message
        </Button>
      </div>
    </div>
  );
};

export default NewMessage;
