// The main point of having these functions in context is so that the keyboard shortcuts and controls can share these functions without prop drilling
// some functions aren't in here - like undo and redo for  example - these ones are just in thier control's file
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { options, selectorSettings } from "./canvasOptions";
import moveObjectFn from "./operations/move";
import stateOperations from "./operations/stateUndoRedo";
import layerOperations from "./operations/layer";
import zoomOperations from "./operations/zoom";
import basicOperations from "./operations/basic";
import "../../types/fabricExtensions";

const CanvasContext = createContext(null);
export default function useCanvas() {
  return useContext(CanvasContext);
}

export const CanvasProvider = ({ children, editorType, editorName }) => {
  // ref to canvas DOM element
  const canvasDOM = useRef<HTMLCanvasElement | null>(null);
  // ref to the fabric canvas that goes over the DOM canvas
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const [isCanvasReady, setCanvasReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textBackgroundType, setTextBackgroundType] = useState("transparent");
  const [selectedCar, setSelectedCar] = useState(null);
  const [tabChanged, setTabChanged] = useState(0); // used as dependancy by select field to recalculate content width
  const [shapeTypes, setShapeTypes] = useState([]); // used when adding shapes, and when dynamically showing sidebar edit UI for shapes
  const [notification, setNotification] = useState(""); // used for testing // to use, need to uncomment from bottombar index
  const [lastSelectedObject, setLastSelectedObject] = useState(null);

  const clipboardRef = useRef(null);
  const { copy, paste, duplicate, deleteItems } = basicOperations(
    canvasRef,
    clipboardRef
  );
  const { setZoom, zoomIn, zoomOut } = zoomOperations(canvasRef, canvasDOM);
  const { undo, redo, historyInit } = stateOperations(
    canvasRef,
    setNotification,
    setLoading
  );
  const { sendBackward, sendForward, sendToBack, sendToFront } =
    layerOperations(canvasRef);

  const moveObject = (direction: string) => {
    moveObjectFn(direction, canvasRef);
  };

  // canvas setup
  useEffect(() => {
    canvasRef.current = new fabric.Canvas(
      canvasDOM?.current as HTMLCanvasElement,
      options
    );
    // set selector box color
    selectorSettings(canvasRef);
    historyInit();
    setCanvasReady(true);

    return () => {
      canvasRef.current?.dispose();
    };
  }, []);

  return (
    <CanvasContext.Provider
      value={{
        // ---------setup---------
        canvasDOM, // DOM canvas Ref - just for setup in fabricEditor/index
        canvasRef, // Fabric Canvas Ref - used for all editor functions
        editorType,
        editorName,
        isCanvasReady,

        // --------controls-------------
        copy,
        paste,
        duplicate,
        moveObject,
        deleteItems,
        setZoom,
        zoomIn,
        zoomOut,
        undo,
        redo,
        sendBackward,
        sendForward,
        sendToBack,
        sendToFront,
        textBackgroundType,
        setTextBackgroundType,

        // --------other-------------
        loading,
        setLoading,

        selectedCar,
        setSelectedCar,

        lastSelectedObject,
        setLastSelectedObject,

        shapeTypes,
        setShapeTypes,

        tabChanged,
        setTabChanged,

        notification, // for now notification appears in Bottombar
        setNotification,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
