import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import { signinUser } from "../../services/auth";
import { userStore } from "../../stores";
// import useForm from "../../Hooks/useForm";
import {
  EnvelopeIcon,
  LockClosedIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";

import { Link } from "../../components/ui";
import { LOGO, PRIMARY_TEXT_COLOR, LINK_COLOR } from "../../theme";
import Button from "../../components/ui/Button";
import InputField from "../../components/fields/InputField";
import CheckboxField from "../../components/fields/CheckboxField";
import { omit } from "lodash";

const Signin = () => {
  document.title = "Sign In";
  const navigate = useNavigate();
  const storeUser = userStore((state) => state.storeUser);
  const storeCompany = userStore((state) => state.storeCompany);

  const [passwordType, setPasswordType] = useState("password");

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    const res = await signinUser(omit(data, "remember_me"));
    const response = await res.data;

    if (res.status === 400) {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "error-toast-login",
        pauseOnHover: false,
        autoClose: 2500,
        hideProgressBar: true,
      });
    } else {
      storeUser(res.user);
      storeCompany(res?.user?.company);
      toast.success("Logged in succesfuly!", {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "success-toast-login",
        pauseOnHover: false,
        autoClose: 900,
        hideProgressBar: true,
      });
      reset();
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    }
  };

  return (
    <form
      className="min-h-full grid grid-cols-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="md:col-span-1 col-span-3 flex justify-center items-center flex-col h-screen md:p-12 p-5">
        <div className="w-full mb-5">
          <img className="h-12 w-auto" src={LOGO} alt="Workflow" />
          <h2
            className="mt-6 text-2xl font-extrabold"
            style={{ color: `${PRIMARY_TEXT_COLOR}` }}
          >
            Sign in to your account
          </h2>
          <h4 className="text-gray-500 font-black text-sm mt-3">
            {`Don't have an account? `}
            <span
              style={{ color: `${LINK_COLOR}` }}
              className="cursor-pointer"
              onClick={() => {
                navigate("/signup");
              }}
            >
              Sign up
            </span>
          </h4>
        </div>

        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email format",
              },
            }}
            render={(props) => (
              <InputField
                label="Email"
                required
                leftAddon={EnvelopeIcon}
                placeholder="Provide a valid email"
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{
              required: "Required",
              minLength: {
                value: 6,
                message: "Password must be 6 characters or more.",
              },
            }}
            render={(props) => (
              <InputField
                {...props}
                label="Password"
                placeholder="Enter valid email password.."
                required
                type={passwordType}
                leftAddon={LockClosedIcon}
                rightAddon={passwordType === "text" ? EyeSlashIcon : EyeIcon}
                rightAddonClassName="cursor-pointer hover:text-red-500"
                rightAddonOnClick={() => {
                  setPasswordType((prev) =>
                    prev === "password" ? "text" : "password"
                  );
                }}
                errorClassName="static"
              />
            )}
          />
        </div>

        <div className="flex items-center w-full justify-between my-7 px-1">
          <div className="flex items-center gap-x-2">
            <Controller
              control={control}
              name="remember_me"
              render={(props) => (
                <CheckboxField {...props} className="w-auto m-0" />
              )}
            />
            <label
              htmlFor="remember_me"
              className="block text-sm mb-1.5 font-medium text-gray-800 cursor-pointer"
            >
              Remember me
            </label>
          </div>

          <div className="text-sm mb-2">
            <Link to={"/forgotPassword"} text={"Forgot your password?"} />
          </div>
        </div>

        <Button className="w-full" isLoading={isSubmitting} type="submit">
          Sign in
        </Button>
      </div>
      <div className="hidden md:block relative col-span-2">
        <img
          className="absolute inset-0 h-screen w-full object-cover"
          src="login_cover_image.jpeg"
          alt="Login Cover Image"
        />
      </div>
    </form>
  );
};

export default Signin;
