import { Suspense, useEffect, useState, lazy } from "react";
import { lazily } from "react-lazily";
import { Route, Routes, useLocation } from "react-router-dom";

import { MiddleTopNav } from "../../../Navigation";
import { Sidebar } from "../../../Sidebar";
import FixedToBottom from "../../../../components/ui/FixedToBottom";

import { CompanyMain } from "../../../Main";

import {
  ClipboardDocumentListIcon,
  CogIcon,
  CreditCardIcon,
  HomeIcon,
  ListBulletIcon,
  TicketIcon,
  RectangleStackIcon,
  BriefcaseIcon,
  CheckBadgeIcon,
  WrenchScrewdriverIcon,
  WrenchIcon,
  PuzzlePieceIcon,
  ShoppingCartIcon,
  ClipboardDocumentCheckIcon,
  CalendarDaysIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import {
  companyStore,
  secondaryNavStore,
  userStore,
  sidebar1Store,
} from "../../../../stores";
import { Spinner } from "../../../../components/ui";

const { Management } = lazily(() => import("../../../Management"));
const { Inventory } = lazily(() => import("../../../Inventory"));
const { VehiclesInService } = lazily(() =>
  import("../../../VehiclesInService")
);
const { Profile, TimePunches } = lazily(() => import("../../../User"));
const { CompanySettings } = lazily(() => import("../../../Company"));
const { NotificationList } = lazily(() => import("../../../Notifications"));
const RepairOrders = lazy(() => import("../../../RepairOrder"));
const { Lists } = lazily(() => import("../../../Lists"));
const { Reports } = lazily(() => import("../../../Reports"));
const Orders = lazy(() => import("../../../Orders"));
const HelpDesk = lazy(() => import("../../../HelpDesk"));
const { AccountsPayable, AccountsReceivable, PayReports } = lazily(() =>
  import("../../../Finance")
);
const Tasks = lazy(() => import("../../../Tasks"));
const Parts = lazy(() => import("../../../Parts"));
const Chat = lazy(() => import("../../../Chat"));
const Jobs = lazy(() => import("../../../Jobs"));
const QAContainer = lazy(() => import("../../../QA"));
const Schedule = lazy(() => import("../../../Schedule"));
const { Issues } = lazily(() => import("../../../Issues"));
const { Timesheet } = lazily(() => import("../../../Finance/Timesheet"));
const { PayrollSummariesContainers } = lazily(() =>
  import("../../../Finance/PayrollSummaries")
);

const Company = () => {
  const user = userStore((state) => state.user);
  const company = companyStore((state) => state.company);
  const setCompany = companyStore((state) => state.setCompany);

  const secondaryNavOpen = secondaryNavStore((state) => state.navOpen);
  const setSecondaryNavOpen = secondaryNavStore((state) => state.setNavOpen);
  const setSidebar1Open = sidebar1Store((state) => state.setSidebar1Open);

  document.title = `${company?.name} Dashboard`;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const [refreshPage, setRefreshPage] = useState(true);
  const [currentRoute, setCurrentRoute] = useState();
  const location = useLocation();
  const routePrefix = "/dashboard";

  useEffect(() => {
    setSidebar1Open(!collapsedMenu); // zustand store is set to match local state
  }, [collapsedMenu]);

  //These useEffects are used to handle when the url changes without explicit refresh.
  useEffect(() => {
    setRefreshPage(false);
  }, []);

  useEffect(() => {
    if (refreshPage) {
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    setRefreshPage(true);
    setCurrentRoute(location.pathname);
    setCompany(user?.company);
  }, [location]);

  const routes = [
    {
      name: "Dashboard",
      icon: HomeIcon,
      current: true,
      href: "",
      permissions: ["DASHBOARD"],
    },
    {
      name: "Inventory",
      icon: RectangleStackIcon,
      current: false,
      href: "/inventory",
      permissions: ["INVENTORY"],
    },
    {
      name: "Tasks",
      icon: ClipboardDocumentListIcon,
      current: false,
      enableNavigation: true,
      href: "/tasks",
      count: 5,
      permissions: ["TASKS"],
    },
    {
      name: "Issues",
      icon: ExclamationTriangleIcon,
      current: false,
      count: 12,
      enableNavigation: true,
      href: "/issues",
      // permissions: ["ISSUES"]
    },
    {
      name: "Jobs",
      icon: BriefcaseIcon,
      current: false,
      enableNavigation: true,
      href: "/jobs",
      permissions: ["JOBS"],
    },
    {
      name: "QA",
      icon: CheckBadgeIcon,
      count: 5,
      current: false,
      href: "/qa",
      permissions: ["QA"],
    },
    {
      name: "Vehicles in Service",
      icon: WrenchIcon,
      current: false,
      href: "/vehicles-in-service",
      permissions: ["VEHICLES_IN_SERVICE"],
    },
    {
      name: "Repair Orders",
      icon: WrenchScrewdriverIcon,
      current: false,
      href: "/repair-orders",
      permissions: ["REPAIR_ORDERS"],
    },
    {
      name: "Parts",
      icon: PuzzlePieceIcon,
      current: false,
      href: "/parts",
      enableNavigation: true,
      permissions: ["PARTS"],
    },
    {
      name: "Orders",
      icon: ShoppingCartIcon,
      current: false,
      href: "/orders",
      permissions: ["ORDERS"],
    },
    {
      name: "Help Desk",
      icon: TicketIcon,
      current: false,
      href: "/helpdesk",
      enableNavigation: true,
      permissions: ["helpdesk-inbound", "helpdesk-outbound"],
      permissions: ["HELPDESK"],
    },
    {
      name: "Schedule",
      icon: CalendarDaysIcon,
      current: false,
      enableNavigation: true,
      href: "/schedule",
      permissions: ["SCHEDULE"],
    },
    {
      name: "Finance",
      icon: CreditCardIcon,
      current: false,
      permissions: ["FINANCE"],
      children: [
        {
          name: "Accounts Payable",
          href: "/accounts-payable",
          current: false,
          permissions: ["FINANCE_ACCOUNTS_PAYABLE"],
        },
        {
          name: "Accounts Receivable",
          href: "/accounts-receivable",
          current: false,
          permissions: ["FINANCE_ACCOUNTS_RECEIVABLE"],
        },
        {
          name: "Pay Reports",
          href: "/pay-reports",
          current: false,
          permissions: ["FINANCE_PAY_REPORTS"],
        },
        {
          name: "Time Sheets",
          href: "/time_sheets",
          current: false,
          permissions: ["FINANCE_PAY_REPORTS"], //change this according to what permission
        },
        {
          name: "Payroll Summaries",
          href: "/payroll_summaries",
          current: false,
          permissions: ["FINANCE_PAY_REPORTS"], //change this according to what permission
        },
      ],
    },
    {
      name: "Lists",
      icon: ListBulletIcon,
      current: false,
      href: "/lists",
      permissions: ["LISTS"],
    },
    {
      name: "Reports",
      icon: ClipboardDocumentCheckIcon,
      current: false,
      href: "/reports/vehicleSales",
      permissions: ["REPORTS"],
    },
    {
      name: "Settings",
      icon: CogIcon,
      current: false,
      href: "/settings",
      permissions: ["SETTINGS"],
    },
  ];

  return (
    <div className="h-screen">
      <div className="h-full">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          collapsedMenu={collapsedMenu}
          currentRoute={currentRoute}
          routePrefix={routePrefix}
          routes={routes}
          secondaryNav={true}
          openSecondaryNav={() => {
            setSecondaryNavOpen(true);
          }}
        />
        <div
          className={`h-full duration-300 ease-in-out ${
            collapsedMenu ? "md:pl-16" : "md:pl-64 "
          }`}
        >
          <MiddleTopNav
            setSidebarOpen={setSidebarOpen}
            setCollapsedMenu={setCollapsedMenu}
          />
          <main className="flex-1 h-full">
            <div className="h-full">
              <Routes>
                <Route path="/*" exact element={<CompanyMain />} />
                {/* <Route
                  path="/companies/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Companies />
                    </Suspense>
                  }
                /> */}
                <Route
                  path="/inventory/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Inventory company={company} />
                    </Suspense>
                  }
                />

                <Route
                  path="/tasks/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Tasks />
                    </Suspense>
                  }
                />

                <Route
                  path="/issues/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Issues />
                    </Suspense>
                  }
                />

                <Route
                  path="/management/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Management company={user?.company} />
                    </Suspense>
                  }
                />
                <Route
                  path="/helpdesk/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <HelpDesk />
                    </Suspense>
                  }
                />
                <Route
                  path="/orders/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Orders />
                    </Suspense>
                  }
                />
                <Route
                  path="/accounts-payable/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <AccountsPayable />
                    </Suspense>
                  }
                />
                <Route
                  path="/reports/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Reports />
                    </Suspense>
                  }
                />
                <Route
                  path="/accounts-receivable/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <AccountsReceivable />
                    </Suspense>
                  }
                />
                <Route
                  path="/pay-reports/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <PayReports />
                    </Suspense>
                  }
                />
                <Route
                  path="/time_sheets/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Timesheet />
                    </Suspense>
                  }
                />

                <Route
                  path="/payroll_summaries/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <PayrollSummariesContainers />
                    </Suspense>
                  }
                />

                <Route
                  path="/vehicles-in-service/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <VehiclesInService company={company} />
                    </Suspense>
                  }
                />
                <Route
                  path="/vehicles-in-service/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <VehiclesInService company={company} />
                    </Suspense>
                  }
                />
                <Route
                  path="/repair-orders/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <RepairOrders />
                    </Suspense>
                  }
                />
                <Route
                  path="/parts/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Parts />
                    </Suspense>
                  }
                />
                <Route
                  path="/chat/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Chat />
                    </Suspense>
                  }
                />
                <Route
                  path="/jobs/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Jobs />
                    </Suspense>
                  }
                />
                <Route
                  path="/qa/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <QAContainer />
                    </Suspense>
                  }
                />
                <Route
                  path="/schedule/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Schedule />
                    </Suspense>
                  }
                />
                <Route
                  path="/lists/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Lists />
                    </Suspense>
                  }
                />
                <Route
                  path="/settings/*"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <CompanySettings
                        company={company}
                        secondaryNavOpen={secondaryNavOpen}
                        setSecondaryNavOpen={setSecondaryNavOpen}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/profile"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <Profile user={user} />
                    </Suspense>
                  }
                />
                <Route
                  path="/notifications"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <NotificationList />
                    </Suspense>
                  }
                />
                <Route
                  path="/timePunches"
                  exact
                  element={
                    <Suspense
                      fallback={
                        <div className="flex justify-center items-center h-[85vh]">
                          <Spinner size="large" />
                        </div>
                      }
                    >
                      <TimePunches />
                    </Suspense>
                  }
                />
              </Routes>
            </div>
            {/* optional content fixed to bottom of page */}
            <FixedToBottom />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Company;
