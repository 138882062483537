import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { get } from "lodash";
import Checkbox from "../ui/Checkbox";
import { Spinner } from "../ui";

const CheckboxField = ({
  label,
  field,
  formState,
  className,
  labelClassName,
  required,
  disabled,
}) => {
  const hasError =
    formState.errors[field.name] || get(formState.errors, field.name); // for nested fields;

  return (
    <div className={twMerge("mt-3 w-full", className)}>
      {label && (
        <label
          htmlFor={field.name}
          className={twMerge(
            "my-2 block text-sm font-medium text-gray-800 cursor-pointer",
            labelClassName
          )}
        >
          {label}
          {required && (
            <span className="text-red-500 ml-1" aria-label="required">
              *
            </span>
          )}
        </label>
      )}

      <Checkbox
        {...field}
        id={field.name}
        checked={field.value}
        disabled={disabled || formState.isLoading}
        size="md"
        className="inline"
      />

      {hasError && (
        <p className="text-sm text-end text-red-500 mt-1 absolute right-0">
          {hasError?.message}
        </p>
      )}
    </div>
  );
};
CheckboxField.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  errorClassName: PropTypes.string,
};

export default CheckboxField;
