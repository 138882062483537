import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ControlButton from "../../fields/ControlButton";
import { Modal } from "../../../../../../../../../../components/ui";
import Button from "../../../../../../../../../../components/ui/Button";
import InputField from "../../../../../../../../../../components/fields/InputField";
import useCanvas from "../../Context";
import { startCase } from "lodash";
import TooltipWrapper from "../../fields/TooltipWrapper";

const Save = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { editorType, editorName } = useCanvas();

  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState();

  const dummyValue = { editorName: startCase(editorName) };

  const { handleSubmit, control, reset, setValue, watch } = useForm();

  useEffect(() => {
    setDataLoading(true);
    try {
      reset(dummyValue);
    } catch (error) {
      setError(error);
    } finally {
      setDataLoading(false);
    }
  }, [reset]);

  const onSubmit = async (data) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);

      onClose();
    }, 500);
  };

  const onClose = () => {
    setOpen(false);
    reset();
    navigate(
      editorType === "window_sticker"
        ? "/dashboard/settings/merchandising?tab=documents"
        : "/dashboard/settings/merchandising?tab=photos&subtab=overlays"
    );
  };

  return (
    <div>
      <TooltipWrapper title={`Save ${editorType}`}>
        <ControlButton
          className="py-[2px] text-sm whitespace-nowrap"
          onClick={() => setOpen(true)}
        >
          Save
        </ControlButton>
      </TooltipWrapper>
      <Modal
        title={`Save ${startCase(editorType)}`}
        open={open}
        setOpen={() => setOpen(false)}
        modalStyle={`md:max-w-md overflow-y-auto`}
        placement="top"
        ui={
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="px-1 mt-10">
              <Controller
                control={control}
                name="editorName"
                rules={{
                  required: "Required",
                }}
                render={(props) => (
                  <InputField
                    {...props}
                    className="mb-4"
                    label={`Name`}
                    placeholder=""
                    required
                  />
                )}
              />

              <div className="flex justify-end mt-10 gap-x-2 ">
                <>
                  <Button variant="ghost" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>

                  <Button
                    isLoading={loading}
                    loadingText="Saving..."
                    type="submit"
                  >
                    Save
                  </Button>
                </>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
};

export default Save;
