import { useEffect } from "react";
import useCanvas from "../../Context/index";

const useKeyboardShortcuts = () => {
  const { deleteItems, moveObject, copy, paste, undo, redo } = useCanvas();

  const isMac = () => navigator.userAgent.includes("Mac");

  //  note: the more specific functions have to go first and must return early to prevent further checks
  //    e.g. (CMD + Shift + Z) must go before (CMD + Z) so that it doesn't trigger (CMD + Z) first
  const handleKeyDown = async (e) => {
    if (e.key === "Delete") {
      deleteItems();
    }
    if (e.key === "ArrowUp") {
      moveObject("up");
    }
    if (e.key === "ArrowDown") {
      moveObject("down");
    }
    if (e.key === "ArrowLeft") {
      moveObject("left");
    }
    if (e.key === "ArrowRight") {
      moveObject("right");
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "c") {
      await copy();
    }
    // ----- redo -------  - -
    if (isMac()) {
      if (e.metaKey && e.shiftKey && e.key === "z") {
        // MAC -  CMD + SHIFT + Z
        redo();
        return; // Early return to prevent further checks
      }
    } else {
      if ((e.ctrlKey || e.metaKey) && e.key === "y") {
        // Not MAC - Ctrl + Y
        redo();
        return; // Early return to prevent further checks
      }
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "v") {
      paste();
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "z") {
      undo();
    }

    // When adding new conditions, make sure to add their shortcuts to the list in Bottombar/shortcuts for the user to see
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};

export default useKeyboardShortcuts;
