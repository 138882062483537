// had to make a jsx file in order to use the Modal
import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Select from "../../../../fields/ControlSelect";
import AddFontModal from "./AddFontModal";

const Font = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentFont, setCurrentFont] = useState("Arial");
  const [open, setOpen] = useState(false);
  const [fontOptions, setFontOptions] = useState([
    { label: "Arial", value: "Arial" },
    { label: "Helvetica", value: "Helvetica" },
    { label: "Verdana", value: "Verdana" },
    { label: "Trebuchet MS", value: "Trebuchet MS" },
    { label: "Gill Sans", value: "Gill Sans" },
    { label: "Times New Roman", value: "Times New Roman" },
    { label: "Georgia", value: "Georgia" },
    { label: "Courier New", value: "Courier New" },
    { label: "Monaco", value: "Monaco" },
  ]);

  // get font family of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];

        if (obj.type === "i-text" || obj.type === "textbox") {
          const styles = obj.getSelectionStyles(0, obj.text.length, true);
          const fontFrequencies = {};

          // Count the occurrences of each font
          styles.forEach((style) => {
            const fontFamily =
              style.fontFamily || obj.fontFamily || "Times New Roman";
            fontFrequencies[fontFamily] =
              (fontFrequencies[fontFamily] || 0) + 1;
          });

          // Find the most common font
          let mostCommonFont = "Times New Roman";
          let maxCount = -1;

          for (const [font, count] of Object.entries(fontFrequencies)) {
            if (count > maxCount) {
              mostCommonFont = font;
              maxCount = count;
            }
          }

          setCurrentFont(mostCommonFont);
        } else if (obj.fontFamily) {
          setCurrentFont(obj.fontFamily);
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleFontChange = (newFont) => {
    // console.log("New font selected:", newFont); // Debug line
    setCurrentFont(newFont);

    const activeObjects = canvasRef.current?.getActiveObjects();
    // console.log("Active objects:", activeObjects); // Debug line

    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];
      //   console.log("Selected object:", obj); // Debug line

      if (obj.type === "i-text" || obj.type === "textbox") {
        const selectionStart = obj.selectionStart;
        const selectionEnd = obj.selectionEnd;
        // console.log("Selection starts at:", selectionStart, "ends at:", selectionEnd); // Debug line

        // Check if any text is selected inside the text box
        if (selectionStart !== selectionEnd) {
          obj.setSelectionStyles(
            { fontFamily: newFont },
            selectionStart,
            selectionEnd
          );
        } else {
          // Loop through all characters to override any inline styles
          for (let i = 0; i < obj.text.length; i++) {
            obj.setSelectionStyles({ fontFamily: newFont }, i, i + 1);
          }
        }
      } else {
        obj.set("fontFamily", newFont);
      }

      obj.setCoords();
      obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Font</Label>
      <Select
        options={fontOptions}
        onChange={handleFontChange}
        value={currentFont}
        addNewOption={{
          title: "Import New Font",
          onClick: () => {
            setOpen(true);
          },
        }}
      />
      <AddFontModal
        open={open}
        setOpen={setOpen}
        addFont={(newFont) => {
          setFontOptions((prevFonts) => [
            ...prevFonts,
            { label: newFont || "----", value: newFont || "----" },
          ]);
          handleFontChange(newFont);
        }}
      />
    </section>
  );
};

export default Font;
