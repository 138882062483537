import { Fragment, useState, useEffect, createElement } from "react";

import {
  Bars3Icon,
  ClockIcon,
  MagnifyingGlassIcon,
  PowerIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

import { appStore, userStore } from "../../stores";
import { logoutUser } from "../../services/auth";

import { Modal, Spinner } from "../../components/ui";
import { lazily } from "react-lazily";
import { NotificationFlyout } from "../Notifications";
import ChatDrawer from "../Chat/Drawer";
import TimePunchModal from "../../components/ui/TimePunchModal";
import { ensurePermission } from "../../Hooks/withPermissions";
import Dropdown from "../../components/ui/Dropdown";

const { GlobalSearch } = lazily(() => import("../Search"));

export const MiddleTopNav = ({ setSidebarOpen, setCollapsedMenu }) => {
  const user = userStore((state) => state.user);
  const [signingOut, setSigningOut] = useState(false);
  const [userNavigation, setUserNavigation] = useState([]);
  const [showTimePunchModal, setShowTimePunchModal] = useState(false);
  const showSearch = appStore((state) => state.showSearch);
  const storeShowSearch = appStore((state) => state.storeShowSearch);

  const logout = () => {
    setSigningOut(true);

    logoutUser().then((response) => {
      if (response?.status === 200) {
        setSigningOut(true);
        window.location.href = "/signin";
      }
    });
  };

  const navigation = [
    { name: "Your Profile", href: "/dashboard/profile", icon: UserCircleIcon },
    {
      name: "Clock Hours",
      function: () => {
        setShowTimePunchModal(true);
      },
      icon: ClockIcon,
    },
    {
      name: "Sign out",
      function: logout,
      icon: PowerIcon,
      className: "hover:bg-red-100",
      loading: signingOut,
    },
  ];

  useEffect(() => {
    const navigationFilteredPermissions = navigation.filter((nav) => {
      if (nav?.permission) {
        const userPermissions =
          user?.permissions && Object.keys(user?.permissions);
        if (
          userPermissions?.some((permission) =>
            nav?.permission.includes(permission)
          )
        ) {
          return nav;
        }
      } else {
        return nav;
      }
    });
    setUserNavigation(navigationFilteredPermissions);
  }, [user]);

  return (
    <Fragment>
      <div className="sticky top-0 z-10 flex-shrink-0 flex items-center h-16 bg-white shadow">
        <button
          type="button"
          className="ml-2 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset"
          onClick={() => {
            setSidebarOpen((prevState) => !prevState);
            setCollapsedMenu((prevState) => !prevState);
          }}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex">
            <form className="w-full flex md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  autoComplete={"off"}
                  type="search"
                  name="search"
                  onClick={() => {
                    storeShowSearch(true);
                  }}
                />
              </div>
            </form>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            {ensurePermission("CHAT") && <ChatDrawer />}

            <NotificationFlyout />

            {/* Profile dropdown */}

            <Dropdown
              trigger={
                <Fragment>
                  <span className="sr-only">Open user menu</span>
                  <div
                    className={`w-10 h-10 rounded-full bg-[#569012] flex items-center justify-center text-white font-bold text-lg`}
                  >
                    {(user?.photo && (
                      <img
                        src={user?.photo}
                        className="rounded-full w-10 h-10"
                      />
                    )) ||
                      (user?.name && (
                        <span>
                          {user?.name?.split(" ")?.length > 0 && (
                            <span>{user?.name?.split(" ")[0]?.charAt(0)}</span>
                          )}
                        </span>
                      ))}
                  </div>
                </Fragment>
              }
            >
              {userNavigation.map((item) => (
                <Dropdown.Item
                  key={item.name}
                  className={item.className}
                  onClick={item.function}
                >
                  {(item.href && (
                    <a
                      href={item.href}
                      className={
                        "flex items-center gap-x-2  text-sm text-gray-700"
                      }
                    >
                      {createElement(item.icon, {
                        className: "h-5 w-5",
                      })}
                      {item.name}
                    </a>
                  )) ||
                    (item.function && (
                      <div
                        className={
                          "flex items-center gap-x-2 text-sm text-gray-700 cursor-pointer"
                        }
                      >
                        {item.loading ? (
                          <Spinner />
                        ) : (
                          createElement(item.icon, {
                            className: "h-5 w-5",
                          })
                        )}
                        {item.name}
                      </div>
                    ))}
                </Dropdown.Item>
              ))}
            </Dropdown>
            {/* <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <div
                    className={`w-10 h-10 rounded-full bg-[#569012] flex items-center justify-center text-white font-bold text-lg`}
                  >
                    {(user?.photo && (
                      <img
                        src={user?.photo}
                        className="rounded-full w-10 h-10"
                      />
                    )) ||
                      (user?.name && (
                        <span>
                          {user?.name?.split(" ")?.length > 0 && (
                            <span>{user?.name?.split(" ")[0]?.charAt(0)}</span>
                          )}
                        </span>
                      ))}
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) =>
                        (item.href && (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </a>
                        )) ||
                        (item.function && (
                          <div
                            onClick={item.function}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            {item.name}
                          </div>
                        ))
                      }
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu> */}
          </div>
        </div>
      </div>

      <TimePunchModal
        show={showTimePunchModal}
        toggle={() => {
          setShowTimePunchModal(false);
        }}
      />

      <Modal
        title={""}
        open={showSearch}
        setOpen={storeShowSearch}
        ui={
          <GlobalSearch
            closeModal={() => {
              storeShowSearch(false);
            }}
          />
        }
        modalStyle={"md:max-w-7xl"}
        placement="top"
      />
    </Fragment>
  );
};
