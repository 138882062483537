// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCNJx1GKkupBcG0itLE09ibDww_Hwc0vfI",
  authDomain: "chromelot-prod.firebaseapp.com",
  projectId: "chromelot-prod",
  storageBucket: "chromelot-prod.appspot.com",
  messagingSenderId: "177070379176",
  appId: "1:177070379176:web:1c82194089d446383d329d",
  measurementId: "G-Z4PE48TDD6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
