import useCanvas from "../../Context/index";
import { fabric } from "fabric";
import ImageUPload from "./components/ImageUpload";
import Coordinates from "./components/basic/Coordinates";
import Dimensions from "./components/basic/Dimensions";
import StackIndex from "./components/basic/StackIndex";
import Rotation from "./components/basic/Rotation";
import Font from "./components/text/Font";
import FontSize from "./components/text/FontSize";
import FillText from "./components/notWorkingYet/FillText";
import FontWeight from "./components/text/FontWeight";
import LineHeight from "./components/text/LineHeight";
import AlignHorizontal from "./components/text/AlignHorizontal";
import TextColorPicker from "./components/text/TextColor";
import TextBackgroundType from "./components/text/TextBackgroundType";
import BackgroundColor from "./components/text/BackgroundColor";
import Borders from "./components/notWorkingYet/Borders";
import BorderWidth from "./components/shapes/BorderWidth";
import BorderRadius from "./components/shapes/BorderRadius";
import BorderColor from "./components/shapes/BorderColor";
import FeaturesGroupWidth from "./components/features/Width";
import VerticalSpacing from "./components/features/VerticalSpacing";
import Columns from "./components/features/Columns";
import ColumnSpacing from "./components/features/ColumnSpacing";
import NumFeatures from "./components/features/NumFeatures";
import FeaturesFontSize from "./components/features/FontSize";
import FeaturesFontWeight from "./components/features/FontWeight";
import FeaturesTextColor from "./components/features/TextColor";
import InsertRealFeatures from "./components/features/InsertRealFeatures";

const EditControls = () => {
  const { editorType, textBackgroundType, shapeTypes, lastSelectedObject } =
    useCanvas();

  return (
    <>
      {/*     {editorType === "window-sticker" ? (
        <ImageUPload /> // select field
      ) : (
        <ImageUPload />
      )} */}
      {lastSelectedObject?.name !== "superFeaturesGroup" && (
        <>
          <Coordinates />
          <Dimensions />
          <Rotation />
          <StackIndex />
        </>
      )}

      {/* ------------ textbox options ---------------*/}
      {(lastSelectedObject?.type === "textbox" ||
        lastSelectedObject?.type === "i-text") && (
        <>
          <Font />
          <FontSize />
          {/* not working yet */}
          {/* <FillText /> */}
          <FontWeight />
          <LineHeight />
          <AlignHorizontal />
          <TextColorPicker />

          <TextBackgroundType />

          {textBackgroundType === "filled" && <BackgroundColor />}
          {/* not working yet */}
          {/* <Padding /> */}
        </>
      )}
      {/* -------- shape options -------- */}
      {shapeTypes?.includes(lastSelectedObject?.type) && (
        <>
          <BackgroundColor />
          <BorderWidth />
          <BorderRadius />
          <BorderColor />
        </>
      )}

      {/* not working yet */}
      {/* <Borders />  */}

      {lastSelectedObject?.name === "superFeaturesGroup" && (
        <>
          <InsertRealFeatures />
          <Coordinates />
          <FeaturesGroupWidth />
          <Rotation />
          <StackIndex />
          <Columns />
          <ColumnSpacing />
          <NumFeatures />
          <VerticalSpacing />
          <FeaturesFontSize />
          <FeaturesFontWeight />
          <FeaturesTextColor />
        </>
      )}
    </>
  );
};

export default EditControls;
