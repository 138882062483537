import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../../components/ui";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputField from "../../../../../components/fields/InputField";
import Button from "../../../../../components/ui/Button";
import SelectField from "../../../../../components/fields/SelectField";
import Filters from "../../../../../components/ui/table/Filters";
import { getValueLabel } from "../../../../../components/fields/FiltersField";
import { omit, startCase } from "lodash";
import {
  Bars4Icon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { twMerge } from "tailwind-merge";

const AddNewProfileModal = ({ show, toggle, onSuccess }) => {
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: async () => {
      return {
        title: "",
        sortBy: {
          field: "",
          order: "",
        },
        filters: [],
        columns: [
          {
            field: "name",
            label: "Name",
            value: true,
          },
          {
            field: "age",
            label: "Age",
            value: true,
          },
          {
            field: "date_added",
            label: "Date Added",
            value: true,
          },
        ],
      };
    },
  });

  const filters = useFieldArray({
    control,
    name: "filters",
  });

  const columns = useFieldArray({
    control,
    name: "columns",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newColumns = Array.from(watch("columns"));
    const [removed] = newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, removed);

    setValue("columns", newColumns);
  };

  const onSubmit = (data) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, 1000);
    }).then((data) => {
      onSuccess && onSuccess(data);
      onClose();
    });
  };

  const onClose = () => {
    reset();
    toggle();
  };

  return (
    <Modal
      open={show}
      setOpen={toggle}
      title="Add New View"
      placement="top"
      modalStyle={`md:max-w-lg overflow-y-auto`}
      ui={
        <Fragment>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-9 px-2">
            <Controller
              control={control}
              name="title"
              rules={{
                required: "Required",
              }}
              render={(props) => (
                <InputField
                  label="View Title"
                  placeholder="Enter a title for this view"
                  className="w-1/2s"
                  {...props}
                />
              )}
            />

            <div className="mt-7">
              <h2 className="mb-2 block text-sm font-medium text-gray-800">
                View Sorting
              </h2>
              <div className="flex items-center gap-x-2 p-2 rounded-md">
                <h2 className="block text-sm font-medium text-gray-800 w-1/3">
                  Sort by
                </h2>
                <Controller
                  control={control}
                  name="sortBy.field"
                  rules={{ required: "Required" }}
                  render={(props) => (
                    <SelectField
                      {...props}
                      options={[
                        {
                          value: "name",
                          label: "Name",
                        },
                        {
                          value: "date_added",
                          label: "Date Added",
                        },
                      ]}
                      label=""
                      required
                      placeholder="Select a field"
                      className="md:col-span-1 col-span-2 m-0"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="sortBy.order"
                  rules={{ required: "Required" }}
                  render={(props) => (
                    <SelectField
                      {...props}
                      options={[
                        {
                          value: "asc",
                          label: "Ascending",
                        },
                        {
                          value: "desc",
                          label: "Descending",
                        },
                      ]}
                      label=""
                      required
                      placeholder="Select order"
                      className="md:col-span-1 col-span-2 m-0"
                    />
                  )}
                />
              </div>
            </div>

            <div className="mt-7">
              <h2 className="mb-2 block text-sm font-medium text-gray-800">
                Filter Sorting
              </h2>
              <div className="grid grid-cols-2 gap-2">
                {watch("filters")?.map((filter, index) => (
                  <div
                    key={index}
                    className="border p-1 pl-2 flex items-center justify-between bg-gray-50 rounded-md text-sm"
                  >
                    <div className="">
                      {`${startCase(filter.field)} is ${getValueLabel(
                        filter.operator
                      )}
                    ${filter.value}`}
                    </div>
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => {
                        filters.remove(index);
                      }}
                    >
                      <TrashIcon className="h-5 w-5 text-red-500" />
                    </Button>
                  </div>
                ))}
              </div>

              <div className="flex items-center-gap-x-2 justify-end mt-3">
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => setShowFiltersModal(true)}
                  className="flex items-center gap-x-2 pr-3.5"
                >
                  <PlusIcon className="h-5 w-5" />
                  Add
                </Button>
              </div>
            </div>

            <div className="mt-7">
              <h2 className="mb-2 block text-sm font-medium text-gray-800">
                View Columns
              </h2>

              <div className="border  rounded-md p-3">
                <h2 className="mb-2 block text-sm font-medium text-gray-800">
                  Visible Columns
                </h2>
                <DragDropContext onDragEnd={onDragEnd}>
                  {mounted ? (
                    <Droppable droppableId={"columns"}>
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="flex flex-col gap-3 p-3 border"
                        >
                          {columns.fields.map((field, index) => {
                            return (
                              field.value && (
                                <Draggable
                                  key={String(field.id)}
                                  draggableId={`draggable-${String(field.id)}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={twMerge(
                                        "flex items-center gap-x-2  bg-gray-50",
                                        snapshot.isDragging
                                          ? "bg-gray-200 px-2 rounded-sm"
                                          : "bg-white"
                                      )}
                                      {...omit(
                                        provided.draggableProps,
                                        "style.position"
                                      )}
                                      {...provided.dragHandleProps}
                                      key={field.id}
                                      ref={provided.innerRef}
                                    >
                                      <Bars4Icon className="h-5 w-5  text-gray-500 cursor-grab" />
                                      <h2>
                                        {watch(`columns[${index}].label`)}
                                      </h2>
                                      <Button
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => {
                                          columns.update(index, {
                                            ...field,
                                            value: !field.value,
                                          });
                                        }}
                                        className="ml-auto"
                                      >
                                        <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                                      </Button>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            );
                          })}
                        </div>
                      )}
                    </Droppable>
                  ) : null}
                </DragDropContext>

                <div className="py-3 ">
                  <h2 className="mb-2 block text-sm font-medium text-gray-800">
                    Hidden Columns
                  </h2>
                  <div className="flex flex-col gap-2 p-3 border">
                    {columns.fields.map((field, index) => {
                      return (
                        !field.value && (
                          <div className="flex items-center justify-between">
                            <h2>{watch(`columns[${index}].label`)}</h2>
                            <Button
                              size="sm"
                              variant="ghost"
                              onClick={() => {
                                columns.update(index, {
                                  ...field,
                                  value: !field.value,
                                });
                              }}
                              className="ml-auto"
                            >
                              <EyeIcon className="h-5 w-5 text-gray-500" />
                            </Button>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-2 mt-7">
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
          <Filters
            show={showFiltersModal}
            toggle={() => setShowFiltersModal(false)}
            keys={["age", "name", "date_added"]}
            onSave={(filters) => {
              filters.append(filters);
            }}
          />
        </Fragment>
      }
    />
  );
};

AddNewProfileModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default AddNewProfileModal;
