export function parseLayout(layoutString) {
  let parsedLayout = JSON.parse(layoutString);

  // Convert x, y, w, h to numbers if they are not
  parsedLayout = parsedLayout.map((item) => ({
    ...item,
    x: Number(item.x),
    y: Number(item.y),
    w: Number(item.w),
    h: Number(item.h),
  }));

  return parsedLayout;
}

export function generateTwoColumnLayout(layout) {
  let currentY = [0, 0]; // Array to keep track of the current y value for each column

  return layout.map((item, index) => {
    const column = index % 2; // Current column (0 or 1)
    const newItem = {
      ...item,
      x: column * item.w, // Adjust x to account for the width of the item
      y: currentY[column],
    };

    currentY[column] += item.h; // Update the y value for the current column

    return newItem;
  });
}

export function generateThreeColumnLayout(layout) {
  let currentY = [0, 0, 0]; // Array to keep track of the current y value for each column

  return layout.map((item, index) => {
    const column = index % 3; // Current column (0, 1 or 2)
    const newItem = {
      ...item,
      x: column * item.w, // Adjust x to account for the width of the item
      y: currentY[column],
    };

    currentY[column] += item.h; // Update the y value for the current column

    return newItem;
  });
}
