import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import useCanvas from "../../Context";
import Button from "../../fields/ControlButton";
import TooltipWrapper from "../../fields/TooltipWrapper";

const Undo = () => {
  const { undo } = useCanvas();

  return (
    <TooltipWrapper title="Undo">
      <Button onClick={() => undo()} className="py-1">
        <ArrowUturnLeftIcon className="w-4 h-4" />
      </Button>
    </TooltipWrapper>
  );
};

export default Undo;
