import { Routes, Route } from "react-router-dom";
import SoftwareDashboard from "./SoftwareDashboard";
import DashboardEditor from "../Dashboard/DashboardEditor";

const SoftwareMain = ({ company }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<SoftwareDashboard />} />
        <Route path="dashboard-editor" element={<DashboardEditor />} />
      </Routes>
    </>
  );
};

export default SoftwareMain;
