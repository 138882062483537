import { MapPinIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import { PRIMARY_COLOR } from "../../../../theme";
import { twMerge } from "tailwind-merge";
import { markNotificationAsSeen } from "../../api";

const NotificationItem = ({ notification }) => {
  return (
    <div
      className={twMerge(
        "p-2 flex items-start justify-between w-full cursor-pointer transition ease-in-out hover:rounded-md hover:bg-gray-100 relative",
        !notification.seen && "bg-gray-100 hover:bg-gray-200"
      )}
      onClick={async () => {
        await markNotificationAsSeen(notification.id);
        if (notification.entityType === "inventory_location") {
          window.open(`/dashboard/settings/inventorySettings`, "_blank");
        }
        // add more cases here
      }}
    >
      {notification.entityType === "inventory_location" && (
        <MapPinIcon className="h-6 w-6 mr-2" color={PRIMARY_COLOR} />
      )}
      {/* 
        Add more appropriate icons here
        */}
      <div className="flex flex-col flex-1">
        <div className="flex items-start justify-between">
          <div
            className={twMerge(
              "text-sm font-semibold  text-gray-900 truncate",
              !notification.seen && "font-bold"
            )}
          >
            {notification.title}
          </div>
        </div>
        <div className="mt-1">
          <div
            className={twMerge(
              "text-sm text-gray-500",
              !notification.seen && "font-bold"
            )}
          >
            {notification.description}
          </div>
          <div
            className={twMerge(
              "text-sm text-gray-500",
              !notification.seen && "font-bold"
            )}
          >
            {moment(notification.createdAt).fromNow()}
          </div>
        </div>
      </div>

      {!notification.seen && (
        <div className="h-2.5 w-2.5 bg-blue-400 absolute top-2 right-2 rounded-full" />
      )}
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    seen: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
  }),
};

export default NotificationItem;
