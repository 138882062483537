import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/app/company",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
  },
  withCredentials: true,
});

export const getCompanyStats = async () => {
  return await api
    .get("/stats")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyTasks = async (data) => {
  return await api
    .get(`/tasks?cursor=${data.cursor}&results=${data.resultsPerPage}`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyTasksCount = async (data) => {
  return await api
    .get(`/tasks/count`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyEmployees = async () => {
  return await api
    .get("/employees")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyUserEmployees = async () => {
  return await api
    .get("/employees")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyUserInvitations = async () => {
  return await api
    .get("/invitations")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
export const inviteUserToCompany = async (userDetails) => {
  return await api
    .post("/inviteUser", userDetails)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const searchGlobally = async (searchParams) => {
  return await api
    .post("/search/global", searchParams)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getHierachicalPermissions = async () => {
  return await api
    .get("/hierarchicalPermissions")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
