import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Button from "./Button";
import {
  Menu,
  MenuList,
  MenuButton as ButtonMenu,
  MenuItem,
} from "@reach/menu-button";
import { twMerge } from "tailwind-merge";

const MenuButton = ({
  actionIcon,
  actionIconPosition = "right",
  title,
  options = [
    {
      // name
      // icon
      // onClick
      // disabled
    },
  ],
  variant = "primary",
  mode = "solid",
  size = "md",
  textClassName,
  menuItemsClassName,
  menuItemClassName,
  className,
}) => {
  return (
    <Menu>
      <ButtonMenu as="div">
        <Button
          variant={variant}
          mode={mode}
          size={size}
          className={twMerge("flex items-center  gap-x-2", className)}
        >
          {actionIconPosition === "left" && actionIcon}
          {title && (
            <div className={twMerge("text-sm font-semibold", textClassName)}>
              {title}
            </div>
          )}
          {actionIconPosition === "right" && actionIcon}
        </Button>
      </ButtonMenu>

      <MenuList
        className={twMerge(
          "bg-white rounded-md my-2 border overflow-hidden mr-2",
          menuItemsClassName
        )}
      >
        {isEmpty(options) ? (
          <div className="px-2 py-1 text-gray-400">No options</div>
        ) : (
          options.map((option, idx) => (
            <MenuItem
              key={idx}
              onSelect={!option.disabled && option.onClick}
              className={twMerge(
                "w-full text-start flex items-center justify-start text-md hover:bg-gray-100 cursor-pointer p-3",
                option.disabled && "opacity-50 cursor-not-allowed",
                option.className ? option.className : menuItemClassName
              )}
            >
              {option.icon && option.icon}
              {option.name}
            </MenuItem>
          ))
        )}
      </MenuList>
    </Menu>
  );
};

MenuButton.propTypes = {
  actionIcon: PropTypes.element,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.element,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ),
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
    "ghost",
  ]),
  mode: PropTypes.oneOf(["solid", "outlined"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  textClassName: PropTypes.string,
  menuItemsClassName: PropTypes.string,
  menuItemClassName: PropTypes.string,
  className: PropTypes.string,
  actionIconPosition: PropTypes.oneOf(["left", "right"]),
};

export default MenuButton;
