// Use this function to generate a query object that can be used to query the database
const generatePrismaQuery = (
  filters = [
    // {
    //     field: "sample_field",
    //     operator: "contains",
    //     value: "samplevValue",
    //     condition: "and", "or", "not", // optional
    // }
  ]
) => {
  const query = {};

  filters.forEach((condition) => {
    const { field, operator, value, condition: logicalOperator } = condition;

    if (logicalOperator === "and") {
      query.AND = query.AND || [];
      query.AND.push({ [field]: { [operator]: value } });
    } else if (logicalOperator === "or") {
      query.OR = query.OR || [];
      query.OR.push({ [field]: { [operator]: value } });
    } else if (logicalOperator === "not") {
      query.NOT = query.NOT || [];
      query.NOT.push({ [field]: { [operator]: value } });
    } else {
      if (!query.AND) {
        query.AND = [];
      }
      query.AND.push({ [field]: { [operator]: value } });
    }
  });

  return query;
};

const dirtyValues = (dirtyFields, allValues) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
};

export { generatePrismaQuery, dirtyValues };
