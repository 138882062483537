import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Input, Notification } from "../../../../components/ui";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { firebaseUploadImg } from "../../../../services/upload";
import { getDownloadURL } from "firebase/storage";

import { logoutUser } from "../../../../services/auth";
import { updateUser } from "../../../../services/users";

import { PRIMARY_COLOR } from "../../../../theme";
import { userStore } from "../../../../stores";
import Button from "../../../../components/ui/Button";

import AppearanceInfo from "../AppearanceInfo";
import Dropdown from "../../../../components/ui/Dropdown";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const GeneralInfo = ({ user }) => {
  const location = useLocation();
  const isProfile = location.pathname.split("/").includes("profile");
  const navigate = useNavigate();
  const loggedInUser = userStore((state) => state.user);

  const [userDetails, setUserDetails] = useState();
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [info, setInfo] = useState({ message: "", type: "", title: "" });
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhoto, setEditPhoto] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const logoRef = useRef(null);

  useEffect(() => {
    if (user) {
      setUserDetails({
        name: user?.name,
        email: user?.email,
        photo: user?.photo,
        emailVerified: user?.emailVerified,
        password: "",
      });
    }
  }, [user]);

  const setUserPhoto = (e) => {
    const file = e.target.files[0];
    setIsUploadingImg(true);
    const uploadTask = firebaseUploadImg(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(progress);
      },
      (err) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setIsUploadingImg(false);
          setEditPhoto(true);
          setUserDetails((prevState) => ({ ...prevState, photo: url }));
        });
      }
    );
  };

  const updateUserDetail = (details) => {
    setInfo({ message: "", type: "" });
    updateUser(user?.id, details).then((response) => {
      if (response?.status === 200) {
        setInfo({ message: response?.data?.message, type: "success" });
        setUserDetails((prevState) => ({
          ...prevState,
          ...response?.data?.updatedData,
        }));
        resetUpdate(Object.keys(response?.data?.updatedData)[0]);
      } else {
        setInfo({ message: response?.data?.message, type: "error" });
      }
    });
  };

  const resetUpdate = (field) => {
    if (field === "name") {
      setEditName(false);
    }
    if (field === "email") {
      setEditEmail(false);
    }
    if (field === "photo") {
      setEditPhoto(false);
    }
    if (field === "password") {
      setEditPassword(false);
      setTimeout(() => {
        if (loggedInUser?.id === user?.id) {
          logoutUser().then((response) => {
            navigate("/signin");
          });
        }
      }, 1000);
    }
  };
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col py-3">
        <div className="w-2/3">
          <Notification
            type={info.type}
            message={info.message}
            title={info.title}
          />
        </div>
        <div className="text-2xl text-wide text-gray-800 px-5">Profile</div>
      </div>
      <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-3 px-5 py-3">
        <div className="block font-medium text-gray-500 py-3">Name</div>
        <div className="py-3">
          {(editName && (
            <Input
              id={"name"}
              type="text"
              name="name"
              value={userDetails?.name}
              label=""
              onChange={(e) => {
                setUserDetails((prevState) => ({
                  ...prevState,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          )) || <span>{userDetails?.name}</span>}
        </div>
        <div className="cursor-pointer flex md:justify-end items-center">
          {(editName && (
            <div className="flex gap-x-2 items-center">
              <Button
                variant="success"
                onClick={() => {
                  updateUserDetail({ name: userDetails?.name });
                }}
              >
                Update
              </Button>
              <Button
                variant="light"
                onClick={() => {
                  setEditName(false);
                }}
              >
                Cancel
              </Button>
            </div>
          )) || (
            <Button
              variant="light"
              onClick={() => {
                setEditName(true);
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-3 px-5 py-3">
        <div className="block font-medium text-gray-500 py-3">Email</div>
        <div className="py-3">
          {(editEmail && (
            <Input
              id={"email"}
              type="email"
              name="email"
              value={userDetails?.email}
              label=""
              onChange={(e) => {
                setUserDetails((prevState) => ({
                  ...prevState,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          )) || (
            <span className="">
              {userDetails?.email}{" "}
              {(userDetails?.emailVerified && (
                <span className="text-green-500 px-2 py-1 bg-green-200 rounded-full text-xs">
                  Verified
                </span>
              )) || (
                <span className="text-red-500 px-2 py-1 bg-red-200 rounded-full text-xs">
                  Not verified
                </span>
              )}
            </span>
          )}
        </div>
        <div className="cursor-pointer flex md:justify-end items-center">
          {(editEmail && (
            <div className="flex gap-x-2 items-center">
              <Button
                variant="success"
                onClick={() => {
                  updateUserDetail({ email: userDetails?.email });
                }}
              >
                Update
              </Button>
              <Button
                variant="light"
                onClick={() => {
                  setEditEmail(true);
                }}
              >
                Cancel
              </Button>
            </div>
          )) || (
            <Button
              variant="light"
              onClick={() => {
                setEditEmail(true);
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-3 px-5 py-3">
        <div className="block font-medium text-gray-500 py-3">
          Profile photo
        </div>
        <div>
          {(!isUploadingImg && userDetails?.photo && (
            <img
              src={userDetails?.photo}
              className="rounded-full w-12 h-12 object-fit"
            />
          )) ||
            (isUploadingImg && (
              <div className="shrink-0 w-12 h-12 relative group cursor-pointer">
                <CircularProgressbar
                  className="w-full h-full"
                  value={uploadProgress}
                  text={`${uploadProgress}%`}
                  styles={buildStyles({
                    trailColor: `${PRIMARY_COLOR}40`,
                    pathColor: PRIMARY_COLOR,
                    textColor: PRIMARY_COLOR,
                    backgroundColor: PRIMARY_COLOR,
                  })}
                />
              </div>
            )) || (
              <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center">
                <div>
                  <img
                    src={
                      "https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg"
                    }
                    className="rounded-full w-12 h-12 object-fit"
                  />
                </div>
              </div>
            )}
        </div>

        <div className="cursor-pointer flex md:justify-end py-3">
          <div className="flex gap-x-2">
            {(!editPhoto && (
              <Button
                variant="light"
                onClick={() => {
                  logoRef.current.click();
                }}
              >
                Edit
              </Button>
            )) || (
              <div className="flex gap-x-2 items-center">
                <Button
                  variant="success"
                  onClick={() => {
                    updateUserDetail({ photo: userDetails?.photo });
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="light"
                  onClick={() => {
                    setEditPhoto(false);
                    setUserDetails((prevState) => ({
                      ...prevState,
                      photo:
                        user?.photo ||
                        "https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg",
                    }));
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <input
            type="file"
            className="hidden"
            ref={logoRef}
            onChange={setUserPhoto}
          />
        </div>
      </div>
      <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-3 px-5 py-3">
        <div className="block font-medium text-gray-500 py-3">Password</div>
        <div className="py-3">
          {(editPassword && (
            <Input
              id={"password"}
              type="password"
              name="password"
              value={userDetails?.password}
              label=""
              onChange={(e) => {
                setUserDetails((prevState) => ({
                  ...prevState,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          )) || <span>**********</span>}
        </div>
        <div className="cursor-pointer flex md:justify-end py-3">
          {(editPassword && (
            <div className="flex gap-x-2 items-center">
              <Button
                variant="success"
                onClick={() => {
                  window.confirm(
                    "Are you sure you wan't to change your password? You will be immediately logged out"
                  ) && updateUserDetail({ password: userDetails.password });
                }}
              >
                Update
              </Button>
              <Button
                variant="light"
                onClick={() => {
                  setEditPassword(false);
                }}
              >
                Cancel
              </Button>
            </div>
          )) || (
            <Button
              variant="light"
              onClick={() => {
                window.confirm(
                  "Are you sure you wan't to change your password? You will be immediately logged out"
                ) && setEditPassword(true);
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </div>

      <AppearanceInfo user={user} />
      {!isProfile && (
        <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-3 px-5 py-3">
          <div className="block font-medium text-gray-500 py-3">Status</div>
          <div className="py-3">
            {editStatus ? (
              <Dropdown
                trigger={
                  <Button
                    size="sm"
                    variant="success"
                    className="flex items-center gap-x-2 pl-3.5"
                  >
                    Active
                    <ChevronDownIcon className="w-3.5 h-3.5" />
                  </Button>
                }
              >
                <Dropdown.Item>Deactivate</Dropdown.Item>
                <Dropdown.Item>Activate</Dropdown.Item>
              </Dropdown>
            ) : (
              <Button size="sm" variant="success">
                Active
              </Button>
            )}
          </div>
          <div className="cursor-pointer flex md:justify-end py-3">
            {(editStatus && (
              <div className="flex gap-x-2 items-center">
                <Button
                  variant="success"
                  onClick={() => {
                    alert("clicked");
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="light"
                  onClick={() => {
                    setEditStatus(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )) || (
              <Button
                variant="light"
                onClick={() => {
                  setEditStatus(true);
                }}
              >
                Edit
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralInfo;
