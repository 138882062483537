import React, { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";
import { fabric } from "fabric";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

const FeaturesGroupVerticalSpacing = () => {
  const { canvasRef, isCanvasReady, lastSelectedObject } = useCanvas();
  const [verticalSpacing, setVerticalSpacing] = useState<string>("");

  useEffect(() => {
    setVerticalSpacing(lastSelectedObject?.verticalSpacing);
  }, [canvasRef.current]);

  const handleChange = (newSpacing) => {
    if (
      lastSelectedObject &&
      lastSelectedObject.name === "superFeaturesGroup"
    ) {
      console.log(newSpacing);
      updateSuperFeaturesGroup(canvasRef.current, true, lastSelectedObject, {
        verticalSpacing: newSpacing,
      });
    }
    setVerticalSpacing(newSpacing);
  };

  return (
    <section>
      <Label>Vertical Spacing</Label>
      <Input
        value={verticalSpacing}
        onChange={(e) => {
          setVerticalSpacing(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleChange(verticalSpacing);
          }
        }}
        min={1}
        className="w-full"
        type="number"
      />
    </section>
  );
};

export default FeaturesGroupVerticalSpacing;
