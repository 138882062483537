import { fabric } from "fabric";
import { useRef, useState } from "react";
import useCanvas from "../../../Context";
import Button from "../../../fields/ControlButton";
import { twMerge } from "tailwind-merge";

const Image = ({ className }) => {
  const { canvasRef, loading, setLoading } = useCanvas();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && canvasRef.current) {
      setLoading(true);
      setFileName(file.name);

      const reader = new FileReader();
      reader.onload = (f) => {
        fabric.Image.fromURL(
          f.target?.result as string,
          (img) => {
            img.scaleToWidth(canvasRef.current.getWidth() / 2);
            canvasRef.current.add(img);
            setLoading(false);
          },
          {
            // additional options here, if needed
          }
        );
      };
      reader.readAsDataURL(file);
    } else {
      setLoading(false); // Set loading state back to false in case of failure
    }
  };

  const simulateFileInputClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />
      <Button
        className={twMerge("text-xs", className)}
        onClick={simulateFileInputClick}
      >
        Image
      </Button>
    </>
  );
};

export default Image;
