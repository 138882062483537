import { useEffect, useState } from "react";
import useCanvas from "../../Context";
import Button from "../../fields/ControlButton";
import TooltipWrapper from "../../fields/TooltipWrapper";

const GroupUngroup = () => {
  const { canvasRef, isCanvasReady, lastSelectedObject } = useCanvas();
  const [localGroupCreatedState, setLocalGroupCreatedState] = useState();
  const [objectSelected, setObjectSelected] = useState(false);

  useEffect(() => {
    const canvas = canvasRef?.current;
    if (!canvas) return;

    const handleObjectSelected = () => setObjectSelected(true);
    const handleObjectDeselected = () => setObjectSelected(false);

    canvas.on("selection:created", handleObjectSelected);
    canvas.on("selection:cleared", handleObjectDeselected);

    return () => {
      canvas.off("selection:created", handleObjectSelected);
      canvas.off("selection:cleared", handleObjectDeselected);
    };
  }, [canvasRef.current]);

  const handleGroupItems = () => {
    if (!isCanvasReady) {
      console.log("Canvas is not ready");
      return;
    }

    const canvas = canvasRef?.current;
    if (!canvas) {
      console.log("Canvas is undefined");
      return;
    }

    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
      console.log("No active object selected");
      return;
    }

    if (activeObject.type === "activeSelection") {
      activeObject.toGroup();
      canvas.requestRenderAll();
    }
  };

  const handleUngroupItems = () => {
    if (!isCanvasReady) {
      console.log("Canvas is not ready");
      return;
    }

    const canvas = canvasRef?.current;
    if (!canvas) {
      console.log("Canvas is undefined");
      return;
    }

    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "group") {
      activeObject.toActiveSelection();
      canvas.discardActiveObject(); // Deselect all objects
      canvas.requestRenderAll();
    }
  };

  return (
    <div className="flex items-center gap-2">
      <TooltipWrapper title="Group Selected Items">
        <Button
          className="py-[2px] text-sm whitespace-nowrap"
          onClick={() => {
            setLocalGroupCreatedState(true);
            handleGroupItems();
          }}
        >
          Group
        </Button>
      </TooltipWrapper>

      {(lastSelectedObject?.type === "group" || localGroupCreatedState) && // only display ungroup if a group is selected
        objectSelected && ( // make sure an object is selected
          <>
            <div className="hidden">sdfg</div>
            <TooltipWrapper title="Ungroup a Group">
              <Button
                className="py-[2px] text-sm whitespace-nowrap"
                onClick={() => {
                  setLocalGroupCreatedState(false);
                  handleUngroupItems();
                }}
              >
                Ungroup
              </Button>
            </TooltipWrapper>
          </>
        )}
    </div>
  );
};

export default GroupUngroup;
