export const dummyOpen = [
  {
    id: 1,
    date_submitted: "2023-08-14",
    type: "Request",
    time_requested: "2023-08-14T10:00:00",
    comments: "Please review and approve.",
  },
  {
    id: 2,
    date_submitted: "2023-08-13",
    type: "Inquiry",
    time_requested: "2023-08-14T10:00:00",
    comments: "Need more information about the project.",
  },
];

export const dummyClosed = [
  {
    id: 1,
    time_requested: "2023-08-14T10:00:00",
    responder: "John Doe",
    comments: "Response sent.",
    results: "Approved",
    comments: "Everything looks good.",
  },
  {
    id: 2,
    time_requested: "2023-08-14T10:00:00",
    responder: "Jane Smith",
    comments: "Follow-up required.",
    results: "Pending",
    comments: "Waiting for additional information.",
  },
];
