import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Spinner } from "../../../../../../components/ui";
import { Modal } from "../../../../../../components/ui";
import Button from "../../../../../../components/ui/Button";
import ButtonGroupField from "../../../../../../components/fields/ButtonGroupField";
import SelectField from "../../../../../../components/fields/SelectField";
import InputField from "../../../../../../components/fields/InputField";
import ColorPicker from "./ColorPicker";
import { v4 as uuidv4 } from "uuid";

import { iconOptions } from "../iconMap";

const AddCustomWidgetModal = ({ open, closeModal, setCustomWidgets }) => {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState();

  const [color, setColor] = useState("#D4DEDA");

  const dummyValue = {
    count_or_total: "count",
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    setDataLoading(true);
    try {
      reset(dummyValue);
    } catch (error) {
      setError(error);
    } finally {
      setDataLoading(false);
    }
  }, [reset]);

  const onSubmit = async (data) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      console.log(data);
      const newWidget = {
        id: uuidv4(),
        name: data.widgetName,
        type: "summary",
        chartType: null,
        icon: data.icon,
        /* dataToAccess: data.dataToAccess,
        viewToAccess: data.viewToAccess,
        countOrTotal: data.count_or_total,
        columnToTotal: data.columnToTotal,
        color: color, */
      };

      // Update the customWidgets state to include the new widget
      setCustomWidgets((prevCustomWidgets) => [
        ...prevCustomWidgets,
        newWidget,
      ]);

      onClose();
    }, 500);
  };

  const onClose = () => {
    closeModal();
    reset();
  };

  return (
    <Modal
      title={"Add Custom Widget"}
      open={open}
      setOpen={closeModal}
      modalStyle={`md:max-w-md overflow-y-auto`}
      placement="top"
      ui={
        <div>
          {dataLoading ? (
            <div className="flex justify-center my-5 items-center h-full">
              <Spinner size="large" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="px-1 mt-12">
              <Controller
                control={control}
                name="widgetName"
                rules={{
                  required: "Required",
                }}
                render={(props) => (
                  <InputField
                    {...props}
                    className="mb-4"
                    label="Widget Name"
                    placeholder=""
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name="dataToAccess"
                rules={{
                  required: "Required",
                }}
                render={(props) => (
                  <SelectField
                    {...props}
                    options={[
                      {
                        label: "Invoices",
                        value: "invoices",
                      },
                      {
                        label: "Inventory",
                        value: "inventory",
                      },
                    ]}
                    label="Data to Access"
                    isClearable
                    required
                  />
                )}
              />
              <Controller
                control={control}
                name="viewToAccess"
                /*  rules={{
                  required: "Required",
                }} */
                render={(props) => (
                  <SelectField
                    {...props}
                    options={[
                      {
                        label: "Late Invoices",
                        value: "invoice_total",
                      },
                      {
                        label: "Paid Invoices",
                        value: "paidInvoices",
                      },
                    ]}
                    label="View to Access"
                    isClearable
                    // required
                  />
                )}
              />
              <Controller
                control={control}
                name="count_or_total"
                /*   rules={{
              required: "Required",
            }} */
                render={(props) => (
                  <div className="w-[50%]">
                    <ButtonGroupField
                      // buttonsClassNames={["basis-1/3", "basis-2/3"]}
                      buttonsClassName={"py-1.5"}
                      options={[
                        {
                          name: "Count",
                          value: "count",
                        },
                        {
                          name: "Total",
                          value: "total",
                        },
                      ]}
                      {...props}
                      label="Display"
                      //   required
                      className=""
                    />
                  </div>
                )}
              />

              {watch("count_or_total") === "total" ? (
                <Controller
                  control={control}
                  name="columnToTotal"
                  /*  rules={{
                  required: "Required",
                }} */
                  render={(props) => (
                    <SelectField
                      {...props}
                      options={[
                        {
                          label: "Invoice Total",
                          value: "invoice_total",
                        },
                        {
                          label: "Pay Reports Total",
                          value: "pay_reports_total",
                        },
                      ]}
                      label="Column to Total"
                      isClearable
                      // required
                    />
                  )}
                />
              ) : (
                <div></div>
              )}
              <Controller
                control={control}
                name="icon"
                rules={{
                  required: "Required",
                }}
                render={(props) => (
                  <SelectField
                    {...props}
                    options={iconOptions}
                    label="Icon"
                    isClearable
                    required
                  />
                )}
              />
              <div>
                <h4 className="my-2 mt-3 block text-sm font-medium text-gray-800">
                  Color
                </h4>
                <ColorPicker color={color} setColor={setColor} />
              </div>

              <div className="flex justify-end mt-10 gap-x-2 ">
                <>
                  <Button variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    isLoading={loading}
                    loadingText="Saving..."
                    type="submit"
                  >
                    Save
                  </Button>
                </>
              </div>
            </form>
          )}
        </div>
      }
    />
  );
};

export default AddCustomWidgetModal;
