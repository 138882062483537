import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import { createElement, forwardRef } from "react";

// For more props, please visit
// https://www.radix-ui.com/primitives/docs/components/accordion
const RadixAccordion = ({ children, type, ...rest }) => {
  return (
    <Accordion.Root
      type={type}
      defaultValue={type === "multiple" ? [rest.defaultValue] : "item-0"}
      {...rest}
    >
      {children}
    </Accordion.Root>
  );
};

export const AccordionItem = forwardRef(
  ({ children, className, disabled, value, ...rest }, forwardedRef) => (
    <Accordion.Item
      className={twMerge(
        "w-full AccordionItem",
        disabled && "cursor-not-allowed",
        className
      )}
      disabled={disabled}
      value={String(value)}
      ref={forwardedRef}
      {...rest}
    >
      {children}
    </Accordion.Item>
  )
);
export const AccordionHeader = forwardRef(
  (
    { children, className, iconPosition = "right", disabled, ...rest },
    forwardedRef
  ) => (
    <Accordion.Header className="AccordionHeader group">
      <Accordion.Trigger
        className={twMerge(
          "AccordionTrigger group:data-state-open:bg-gray-100 p-2 cursor-pointer w-full flex items-center justify-start hover:bg-gray-100",
          disabled && "cursor-not-allowed opacity-50",
          className
        )}
        disabled={disabled}
        ref={forwardedRef}
        {...rest}
      >
        {iconPosition === "left" && (
          <ChevronDownIcon
            className="AccordionChevron w-5 h-5 text-gray-500 mr-2"
            aria-hidden
          />
        )}
        {children}
        {iconPosition === "right" && (
          <ChevronDownIcon
            className="AccordionChevron w-5 h-5 ml-auto text-gray-500 mr-1"
            aria-hidden
          />
        )}
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

export const AccordionContent = forwardRef(
  ({ children, className }, forwardedRef) => (
    <Accordion.Content
      ref={forwardedRef}
      className={twMerge("AccordionContent", className)}
    >
      {children}
    </Accordion.Content>
  )
);

RadixAccordion.propTypes = {
  type: PropTypes.oneOf(["single", "multiple"]),
  defaultValue: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AccordionItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

AccordionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
};

AccordionContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

RadixAccordion.defaultProps = {
  defaultValue: "item-0", // Set a default value
};

export default RadixAccordion;
