import React, {
  useState,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  Ref,
  useEffect,
} from "react";
import { twMerge } from "tailwind-merge";
// import { PRIMARY_COLOR } from "../../../../../../../../../theme";

interface InputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  readOnly?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  min?: number;
  max?: number;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  type: string;
}

const Input: React.FC<Partial<InputProps>> = ({
  placeholder,
  className,
  onChange,
  value,
  inputRef,
  min,
  max,
  onBlur,
  onKeyDown,
  type = "text",
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        min={min}
        max={max}
        className={twMerge(
          "border rounded-sm py-1 pl-1.5 pr-[1px] text-xs border-slate-300 shadow-sm",
          focus ? "text-gray-900" : "text-gray-700",
          className
        )}
        style={
          {
            //   borderColor: `${(focus && PRIMARY_COLOR) || ""}`,
          }
        }
        onFocus={(e) => {
          setFocus(true);
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setFocus(false);
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        ref={inputRef}
      />
    </>
  );
};

export default Input;
