import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ColorPicker from "../../../../fields/ControlColorPicker";

const BackgroundColor = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentColor, setCurrentColor] = useState("#FFFFFF"); // Default background color

  // get text background color of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        console.log(obj.type, obj.backgroundColor);
        if (obj.type !== "image") {
          if (obj.type === "textbox" || obj.type === "iText") {
            setCurrentColor(obj?.backgroundColor || "#FFFFFF");
          } else {
            setCurrentColor(obj?.fill || "#FFFFFF");
          }
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleColorChange = (newColor) => {
    setCurrentColor(newColor);

    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];
      if (obj.type !== "image") {
        if (obj.type === "textbox" || obj.type === "iText") {
          obj.set("backgroundColor", newColor);
        } else {
          obj.set("fill", newColor);
        }
        obj.setCoords();
        obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
        canvasRef.current?.renderAll();
      }
    }
  };

  return (
    <section className="">
      <Label>Background Color</Label>
      <ColorPicker value={currentColor} onChange={handleColorChange} />
    </section>
  );
};

export default BackgroundColor;
