// AKA sendTo

import { backgroundImageName } from "../../utils/constants";
const layerOperations = (canvasRef) => {
  const sendToBack = () => {
    const activeObject = canvasRef.current?.getActiveObject();
    if (activeObject) {
      activeObject.sendToBack();

      // after an object is sentToBack, resend the background image to the back (where it should stay)
      const backgroundImage = canvasRef?.current
        ?.getObjects()
        .find((obj) => obj.get("name") === backgroundImageName);
      if (backgroundImage) {
        backgroundImage.sendToBack();
      }
      // discarding the active object so that it doesn't "stay at front" from being selected
      canvasRef.current?.discardActiveObject().renderAll();
    }
  };

  const sendBackward = () => {
    const activeObject = canvasRef.current?.getActiveObject();
    if (activeObject) {
      activeObject.sendBackwards();
      canvasRef.current?.discardActiveObject().renderAll();
    }
  };

  const sendForward = () => {
    const activeObject = canvasRef.current?.getActiveObject();
    if (activeObject) {
      activeObject.bringForward();
      canvasRef.current?.discardActiveObject().renderAll();
    }
  };

  const sendToFront = () => {
    const activeObject = canvasRef.current?.getActiveObject();
    if (activeObject) {
      activeObject.bringToFront();
      canvasRef.current?.discardActiveObject().renderAll();
    }
  };

  return { sendToBack, sendBackward, sendForward, sendToFront };
};

export default layerOperations;
