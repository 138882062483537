import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import Button from "../../../components/ui/Button";
import { TrashIcon } from "@heroicons/react/24/outline";

const ChatBubble = ({ message }) => {
  const [showTime, setShowTime] = useState(false);
  const chatBoxRef = useRef(null);

  // Scroll the chat box to the bottom on initial render and whenever a new message is received
  useEffect(() => {
    chatBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [message]);

  return (
    <div
      className={twMerge(
        "flex mb-3 w-full items-start gap-x-2 group",
        message.isMe ? "justify-end" : "justify-start"
      )}
      ref={chatBoxRef} // Attach the ref to the outer div container
    >
      {message.isMe && (
        <Button
          className="px-1 border-gray-500 hidden  group-hover:flex"
          variant="ghost"
          size="sm"
          onClick={() => {
            alert(`MessageID: ${message.id}}`);
          }}
        >
          <TrashIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        </Button>
      )}
      <div
        className={twMerge(
          "group rounded-md p-3 max-w-xs relative",
          message.isMe ? "bg-gray-500 text-gray-100" : "bg-gray-200"
        )}
        onClick={() => {
          setShowTime((prev) => !prev);
        }}
      >
        <h2 className="">{message.text}</h2>
        {showTime && (
          <p
            className={twMerge(
              "text-xs mt-2",
              message.isMe ? "text-end" : "text-start"
            )}
          >
            {moment(message.createdAt).format("LLL")}
          </p>
        )}
      </div>
      {!message.isMe && (
        <Button
          className="px-1 border-gray-500 hidden  group-hover:flex"
          variant="ghost"
          size="sm"
          onClick={() => {
            alert(`MessageID: ${message.id}`);
          }}
        >
          <TrashIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        </Button>
      )}
    </div>
  );
};

export default ChatBubble;
