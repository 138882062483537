import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Skeleton = ({ className }) => {
  return (
    <div
      className={twMerge(
        "animate-pulse bg-gray-200 h-4 w-fill rounded",
        className
      )}
    />
  );
};

Skeleton.propTypes = {
  className: PropTypes.string,
};

export default Skeleton;
