import { useEffect } from "react";
import useCanvas from "../../Context";
import { specialWrapperStart, specialWrapperEnd } from "../constants";

const useReplaceDynamicFieldsWithVehicleData = () => {
  const { canvasRef, isCanvasReady, selectedCar } = useCanvas();

  // we start with this useEffect that runs when the selected car changes
  useEffect(() => {
    const canvas = canvasRef?.current;
    const allObjects = canvasRef?.current?.getObjects();

    if (!selectedCar) {
      allObjects?.forEach((obj) => {
        if (obj.dynamic && obj.type === "textbox") {
          const wrappedCarFields = `${specialWrapperStart}${obj.carFields}${specialWrapperEnd}`;
          resetTextBox(obj, wrappedCarFields);
        }
      });
      return;
    }

    function handleObjectAdded(e) {
      allObjects?.forEach((obj) => {
        if (obj.dynamic && obj.type === "textbox") {
          if (!obj.firstReplacementDone) {
            // this first replacement function is consitent and should be the only one needed when using this automatically in the app.
            // we should only need to replace the fields once
            replaceTextWithSpecialCode(obj, selectedCar);
          } else {
            // this second function is less consistent because it replaces the whole field since it no longer has the code to fin

            replaceWholeText(obj, selectedCar);
          }
        }
      });

      canvas?.renderAll();
    }

    // if obejct added to canvas run to see if we need to replace fields
    canvas?.on("object:added", handleObjectAdded);

    return () => {
      canvas?.off("object:added", handleObjectAdded);
    };
  }, [isCanvasReady, canvasRef, selectedCar]);
};

// -----------   if the textbox has specialCode - i.e. {( )} -  called from above and from Add/Text
export const replaceTextWithSpecialCode = (obj, selectedCar) => {
  if (!selectedCar) return;
  const textToInsert = getVehicleData(obj, selectedCar);
  //   console.log(obj);
  //   console.log(textToInsert);
  const startIdx = obj.text.indexOf(specialWrapperStart);
  const endIdx = obj.text.indexOf(specialWrapperEnd);

  if (startIdx !== -1 && endIdx !== -1) {
    const before = obj.text.substring(0, startIdx);
    const after = obj.text.substring(endIdx + specialWrapperEnd.length);
    const replacedText = before + textToInsert + after;
    if (textToInsert) {
      obj.set({ firstReplacementDone: true });
      obj.set({ text: replacedText });
      obj.dirty = true;
    }
  }
};

// -------------   if the textbox doesn't have the special code - needs work for when user edits this textbox
function replaceWholeText(obj, selectedCar) {
  if (!selectedCar) console.log("here");
  //   console.log(obj);
  //   console.log(selectedCar);
  const textToInsert = getVehicleData(obj, selectedCar);
  //   console.log(textToInsert);
  if (textToInsert) {
    obj.set({ text: textToInsert });
    obj.dirty = true;
  }
}

// ---------- when no vehicle is selected -------------------------------
function resetTextBox(obj, textToInsert) {
  console.log(textToInsert);
  obj.set({ text: textToInsert });
  obj.dirty = true;
}

const getVehicleData = (obj, selectedCar) => {
  let debuggingSet = [];
  let fieldsToInsert = obj?.carFields?.map((field) => {
    // this finds the vehcile's value to insert based on the field from the textbox
    let fieldValue = selectedCar[field];

    // for debugging
    debuggingSet.push({
      carFieldFromTextbox: field,
      selectedCarField: Object.keys(selectedCar).find((key) => key === field),
      selectedCarValue: fieldValue,
    });
    console.log(debuggingSet);

    if (field.toLowerCase() === "mileage") {
      fieldValue = Number(fieldValue).toLocaleString("en-US");
    }
    if (field.toLowerCase() === "internetprice") {
      fieldValue = `$${Number(fieldValue).toLocaleString("en-US")}`;
    }

    return fieldValue;
  });
  console.log(fieldsToInsert);
  return fieldsToInsert.join(" ");
};

export default useReplaceDynamicFieldsWithVehicleData;
