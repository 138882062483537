import { fabric } from "fabric";

const moveObjectFn = (
  direction: string,
  canvasRef: React.RefObject<fabric.Canvas>
) => {
  const canvas = canvasRef.current;
  const activeObjects = canvas?.getActiveObjects();
  const activeSelection = canvas?.getActiveObject();

  if (activeObjects && activeSelection) {
    activeObjects.forEach((obj) => {
      if (direction === "up") obj.top -= 10;
      if (direction === "down") obj.top += 10;
      if (direction === "left") obj.left -= 10;
      if (direction === "right") obj.left += 10;
      obj.setCoords();

      //  "manualMove" is a custom event name decalred here and listened for in Coordinates control
      canvas?.fire("object:manualMove", { target: obj });

      // this one is for things like Undo to listen to
      canvas?.fire("object:modified", { target: obj });
    });

    // Discard the old selection and create a new one to reflect the change
    if (activeSelection instanceof fabric.ActiveSelection) {
      canvas.discardActiveObject();
      const newGroup = new fabric.ActiveSelection(activeObjects, {
        canvas: canvas,
      });
      canvas.setActiveObject(newGroup);
    }

    canvas.renderAll();
  }
};

export default moveObjectFn;
