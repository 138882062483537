import TableColumns from "../../../../components/ui/table/TableColumns";
import TableBody from "../../../../components/ui/table/TableBody";
import Button from "../../../../components/ui/Button";
import { Spinner } from "../../../../components/ui";
import { Controller, useForm } from "react-hook-form";
import {
  getUser,
  updateUserNotificationSettings,
} from "../../../../services/users";
import CheckboxField from "../../../../components/fields/CheckboxField";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

const Notifications = ({ user }) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isLoading, isDirty, isSubmitting },
  } = useForm({
    defaultValues: async () => {
      const response = await getUser(user?.id);

      // just in case its empty or not set yet, will not happend likely but just in case
      return isEmpty(response.data?.user?.notificationSetting)
        ? defaultSettings
        : response.data?.user?.notificationSetting;
    },
  });

  const onSubmit = (data) => {
    return updateUserNotificationSettings(user.id, data)
      .then((res) => {
        reset(res.data);
        toast.success("Updated Successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success-toast",
          pauseOnHover: false,
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error-toast",
          pauseOnHover: false,
          autoClose: 1000,
          hideProgressBar: true,
        });
      });
  };

  const isTherePushSelected = Object.keys(watch()).some(
    (key) => watch()[key].push === false
  );

  const isThereEmailSelected = Object.keys(watch()).some(
    (key) => watch()[key].email === false
  );

  const isThereInAppSelected = Object.keys(watch()).some(
    (key) => watch()[key].inApp === false
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table className="w-full">
        <TableColumns
          columnClassName="border-y-0"
          columns={[
            "Notification type",
            <div className="flex flex-col">
              <div>
                Push{" "}
                <span className="text-gray-600 text-xs whitespace-nowrap">
                  (Mobile App)
                </span>
              </div>
              <div
                className="text-xs text-blue-700 hover:cursor-pointer underline gray-underline"
                onClick={() => {
                  const keys = Object.keys(watch());
                  keys.forEach((key) =>
                    setValue(
                      `${key}.push`,
                      isTherePushSelected ? true : false,
                      {
                        shouldDirty: true,
                      }
                    )
                  );
                }}
              >
                {isTherePushSelected ? "Select All" : "Deselect All"}
              </div>
            </div>,

            <div className="flex flex-col">
              <div>In-App</div>
              <div
                className="text-xs text-blue-700 hover:cursor-pointer underline gray-underline"
                onClick={() => {
                  const keys = Object.keys(watch());
                  keys.forEach((key) =>
                    setValue(
                      `${key}.inApp`,
                      isThereInAppSelected ? true : false,
                      {
                        shouldDirty: true,
                      }
                    )
                  );
                }}
              >
                {isThereInAppSelected ? "Select All" : "Deselect All"}
              </div>
            </div>,

            <div className="flex flex-col">
              <div>Email</div>
              <div
                className="text-xs text-blue-700 hover:cursor-pointer underline gray-underline"
                onClick={() => {
                  const keys = Object.keys(watch());
                  keys.forEach((key) =>
                    setValue(
                      `${key}.email`,
                      isThereEmailSelected ? true : false,
                      {
                        shouldDirty: true,
                      }
                    )
                  );
                }}
              >
                {isThereEmailSelected ? "Select All" : "Deselect All"}
              </div>
            </div>,
          ]}
        />
        <TableBody>
          {isLoading ? (
            <tr>
              <td colSpan={5} align="center" className="p-20">
                <Spinner size="large" />
              </td>
            </tr>
          ) : (
            Object.keys(watch()).map((key) => {
              return (
                <tr key={key}>
                  <td className="whitespace-nowrap py-4 pl-4 text-left text-sm font-medium text-gray-900 ">
                    {formatNotificationType(key)}
                  </td>
                  <td className="py-4 pl-4 w-[12%]">
                    <Controller
                      control={control}
                      name={`${key}.push`}
                      render={(props) => (
                        <CheckboxField className="m-0 w-auto" {...props} />
                      )}
                    />
                  </td>
                  <td className="py-4 pl-4 w-[12%]">
                    <Controller
                      control={control}
                      name={`${key}.inApp`}
                      render={(props) => (
                        <CheckboxField className="m-0 w-auto" {...props} />
                      )}
                    />
                  </td>
                  <td className="py-4 pl-4 w-[12%]">
                    <Controller
                      control={control}
                      name={`${key}.email`}
                      render={(props) => (
                        <CheckboxField className="m-0 w-auto" {...props} />
                      )}
                    />
                  </td>
                </tr>
              );
            })
          )}
        </TableBody>
      </table>

      <div className="flex justify-end gap-x-2 p-4 border-t">
        {isDirty && (
          <Button
            variant="ghost"
            onClick={() => {
              reset();
            }}
          >
            Cancel
          </Button>
        )}

        <Button
          type="submit"
          disabled={!isDirty}
          isLoading={isSubmitting}
          loadingText="Saving..."
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const defaultSettings = {
  jobUnassigned: {
    push: false,
    email: false,
    inApp: false,
  },
  newJobAssigned: {
    push: false,
    email: false,
    inApp: false,
  },
  taskUnassigned: {
    push: false,
    email: false,
    inApp: false,
  },
  newTaskAssigned: {
    push: false,
    email: false,
    inApp: false,
  },
  newInventoryLocation: {
    push: false,
    email: false,
    inApp: false,
  },
  newDefaultInventoryLocation: {
    push: false,
    email: false,
    inApp: false,
  },
  repairOrderUnassigned: {
    push: false,
    email: false,
    inApp: false,
  },
  newRepairOrderAssigned: {
    push: false,
    email: false,
    inApp: false,
  },
  helpDeskTicketUnassigned: {
    push: false,
    email: false,
    inApp: false,
  },
  newHelpDeskTicketAssigned: {
    push: false,
    email: false,
    inApp: false,
  },
};

const formatNotificationType = (type) => {
  const notificationTypes = {
    newJobAssigned: "A new job is assigned to you",
    jobUnassigned: "A job you've been assigned is dismissed",
    taskUnassigned: "A task you've been assigned is dismissed",
    newTaskAssigned: "A new task is assigned to you",
    newInventoryLocation: "A new inventory location is created",
    newDefaultInventoryLocation: "A new default inventory location is assigned",
    repairOrderUnassigned: "A repair order you've been assigned is dismissed",
    newRepairOrderAssigned: "A new repair order is assigned to you",
    helpDeskTicketUnassigned:
      "A help desk ticket you've been assigned is dismissed",
    newHelpDeskTicketAssigned: "A new help desk ticket is assigned to you",
  };

  return notificationTypes[type] || "Unknown notification type";
};

export default Notifications;
