import { useEffect, useState } from "react";
import Button from "../../../fields/ControlButton";
import TooltipWrapper from "../../../fields/TooltipWrapper";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  NoSymbolIcon,
} from "@heroicons/react/20/solid";
import useCanvas from "../../../Context";
import { fabric } from "fabric";
import { backgroundImageName } from "../../../utils/constants";

const BackgroundImagePreview = () => {
  const { canvasRef, isCanvasReady, selectedCar, loading, setLoading } =
    useCanvas();
  const [imageIndex, setImageIndex] = useState(0);
  const [imageArray, setImageArray] = useState([]);

  useEffect(() => {
    if (!selectedCar) {
      return handleRemoveBackground();
    }
    if (selectedCar?.Image) {
      setImageArray(JSON.parse(selectedCar.Image));
    }
  }, [isCanvasReady, selectedCar]);

  useEffect(() => {
    if (!selectedCar) return;

    const canvas = canvasRef.current;

    if (isCanvasReady && imageArray && imageArray.length > 0) {
      // Remove existing background image
      const backgroundImage = canvas
        .getObjects()
        .find((obj) => obj.get("name") === backgroundImageName);
      if (backgroundImage) {
        canvas.remove(backgroundImage);
      }

      // Set loading state to true as image starts loading
      setLoading(true);

      requestAnimationFrame(() => {
        fabric.Image.fromURL(imageArray[imageIndex], function (img) {
          img.scaleToWidth(canvas.width);
          img.scaleToHeight(canvas.height);

          img.set({
            left: 0,
            top: 0,
            name: "backgroundImage",
            selectable: false,
          });

          canvas.add(img);
          canvas.sendToBack(img);

          // Set loading state back to false after image is loaded
          setLoading(false);
        });
      });
    }
  }, [isCanvasReady, imageArray, imageIndex, setLoading]);

  const handleRemoveBackground = () => {
    const canvas = canvasRef?.current;
    const backgroundImage = canvas
      ?.getObjects()
      .find((obj) => obj.get("name") === "backgroundImage");
    if (backgroundImage) {
      canvas.remove(backgroundImage);
    }
  };

  const goPrevImage = () => {
    if (loading) return; // if anything is loading don't allow this action
    setImageIndex((prev) => (prev === 0 ? imageArray.length - 1 : prev - 1));
  };

  const goNextImage = () => {
    if (loading) return; // if anything is loading don't allow this action
    setImageIndex((prev) => (prev === imageArray.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="flex items-center gap-2">
      <TooltipWrapper title="Previous Image">
        <Button onClick={goPrevImage}>
          <ArrowLeftIcon className="w-4 h-4 text-gray-600" />
        </Button>
      </TooltipWrapper>
      <TooltipWrapper title="Next Image">
        <Button onClick={goNextImage}>
          <ArrowRightIcon className="w-4 h-4 text-gray-600" />
        </Button>
      </TooltipWrapper>
      <div className="flex items-center">
        <TooltipWrapper title="Remove Image">
          <Button onClick={handleRemoveBackground}>
            <NoSymbolIcon className="w-4 h-4 text-slate-400" />
          </Button>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default BackgroundImagePreview;
