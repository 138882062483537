import { validateEmailExists } from "../../validator";
import { passwordReset } from "../../services/auth";
import { Button, Input, InfoCard } from "../../components/ui";

import { LOGO } from "../../theme";
import useForm from "../../Hooks/useForm";

const ResetPassword = () => {
  document.title = "Forgot Password - Reset Password";
  const apiFn = async () => {
    return passwordReset({ email: formData?.email });
  };

  const [formData, loading, info, handleChange, handleSubmit] = useForm(
    apiFn,
    validateEmailExists
  );

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-100 p-2">
      <form onSubmit={handleSubmit} className="md:w-1/3 bg-white p-12">
        <div>
          <InfoCard type={info?.type} message={info?.message} />
        </div>
        <div className="mt-3">
          <img className="h-12 w-auto" src={LOGO} alt="Workflow" />
        </div>
        <div className="text-gray-600 text-2xl font-semibold tracking-wide mt-3">
          Forgot Password
        </div>
        <div className="mt-3 text-gray-500">
          Enter your registered email address to change your Chromelot account
          password.
        </div>
        <div>
          <div className="mt-3">
            <Input
              id={"signin-email"}
              type={"email"}
              name={"email"}
              value={formData?.email || ""}
              label=""
              placeholder={"Enter your email address"}
              autoComplete={"email"}
              required={true}
              onChange={handleChange}
            />
          </div>
        </div>
        {info?.type !== "success" && (
          <div className="mt-5">
            <Button text={"Next"} loading={loading} buttonType={"submit"} />
          </div>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
