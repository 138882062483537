import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import Button from "../../../../fields/ControlButton";
import useCanvas from "../../../../Context";
import TooltipWrapper from "../../../../fields/TooltipWrapper";

const StackIndex = () => {
  const { sendBackward, sendForward, sendToBack, sendToFront } = useCanvas();
  return (
    <section className="flex justify-between mt-2 w-[85%]">
      <TooltipWrapper title="Send to Back">
        <Button onClick={() => sendToBack()}>
          <ChevronDoubleLeftIcon className="w-5 h-5 text-gray-600" />
        </Button>
      </TooltipWrapper>
      <TooltipWrapper title="Send Backward">
        <Button onClick={() => sendBackward()}>
          <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
        </Button>
      </TooltipWrapper>
      <TooltipWrapper title="Send Forward">
        <Button onClick={() => sendForward()}>
          <ChevronRightIcon className="w-5 h-5 text-gray-600" />
        </Button>
      </TooltipWrapper>
      <TooltipWrapper title="Send to Front">
        <Button onClick={() => sendToFront()}>
          <ChevronDoubleRightIcon className="w-5 h-5 text-gray-600" />
        </Button>
      </TooltipWrapper>
    </section>
  );
};

export default StackIndex;
