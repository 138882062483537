import React from "react";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import ScrollArea from "./ScrollArea";

const Drawer = ({
  show,
  onClose,
  title,
  content,
  buttons,
  rawContent,
  customContentClassName,
  customDialogPanelClassName,
  showButtons = true,
  inverseTransition = false,
}) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-100 sm:duration-700"
                enterFrom={`${
                  inverseTransition ? "translate-y-full" : "translate-x-full"
                }`}
                enterTo={`${
                  inverseTransition ? "translate-y-0" : "translate-x-0"
                }`}
                leave="transform transition ease-in-out duration-100 sm:duration-700"
                leaveFrom={`${
                  inverseTransition ? "translate-y-0" : "translate-x-0"
                }`}
                leaveTo={`${
                  inverseTransition ? "translate-y-full" : "translate-x-full"
                }`}
              >
                <Dialog.Panel
                  className={twMerge(
                    "pointer-events-auto w-screen max-w-md",
                    customDialogPanelClassName
                  )}
                >
                  {rawContent ? (
                    content
                  ) : (
                    <div className="flex flex-col h-screen bg-white shadow-xl">
                      <div className="p-4 border-b">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <ScrollArea rootClassName="flex-1">
                        <div
                          className={twMerge(
                            "relative px-4 sm:px-6 mt-5",
                            customContentClassName
                          )}
                        >
                          {/* Your content */}
                          {content}
                        </div>
                      </ScrollArea>

                      {showButtons && (
                        <div className="px-4 py-3 sm:px-6 border-t">
                          {buttons}
                        </div>
                      )}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.element,
  buttons: PropTypes.element,
  customContentClassName: PropTypes.string,
  rawContent: PropTypes.bool,
  customDialogPanelClassName: PropTypes.string,
  showButtons: PropTypes.bool,
  inverseTransition: PropTypes.bool,
};

Drawer.defaultProps = {
  showButtons: true,
  inverseTransition: false,
};

export default Drawer;
