export const dummyConversations = [
  {
    id: 1,
    name: "John Doe",
    lastMessage: "Hello! How are you?",
    seen: false,
  },
  {
    id: 2,
    name: "Jane Doe",
    lastMessage: "Hello! How are you?",
    seen: true,
  },
  {
    id: 3,
    name: "Alice Johnson",
    lastMessage: "Hey there!",
    seen: true,
  },
  {
    id: 4,
    name: "Bob Smith",
    lastMessage: "Hi, how's it going?",
    seen: false,
  },
  {
    id: 5,
    name: "Eve Williams",
    lastMessage: "Long time no see!",
    seen: true,
  },
  {
    id: 6,
    name: "Michael Brown",
    lastMessage: "What's up?",
    seen: false,
  },
  {
    id: 7,
    name: "Sophia Lee",
    lastMessage: "Hello!",
    seen: true,
  },
  {
    id: 8,
    name: "William Davis",
    lastMessage: "Hi, how are you?",
    seen: false,
  },
  {
    id: 9,
    name: "Olivia Martinez",
    lastMessage: "Hey! How's your day?",
    seen: true,
  },
  {
    id: 10,
    name: "James Rodriguez",
    lastMessage: "What are you up to?",
    seen: false,
  },
  {
    id: 29,
    name: "Olivia Martinezss",
    lastMessage: "Hey! How's your day?",
    seen: true,
  },
  {
    id: 130,
    name: "James Rodriguezs",
    lastMessage: "What are you up to?",
    seen: false,
  },
];

export const conversations = [
  {
    id: 1,
    conversationId: 1,
    messages: [
      {
        id: 1,
        senderId: 1,
        text: "Hello! How are you?",
        createdAt: "2020-08-01T12:00:00.000Z",
        isMe: false,
      },
      {
        id: 2,
        senderId: 2,
        text: "I'm fine, thanks!",
        createdAt: "2020-08-01T12:01:00.000Z",
        isMe: true,
      },
      {
        id: 3,
        senderId: 1,
        text: "I'm having some trouble with my car. It's making a strange noise whenever I start the engine. I'm a bit worried about it.",
        createdAt: "2023-07-25T10:00:00.000Z",
        isMe: false,
      },
      {
        id: 4,
        senderId: 2,
        text: "I see. Don't worry; we'll figure it out. Can you describe the noise? Is it a clicking sound, a grinding noise, or something else?",
        createdAt: "2023-07-25T10:02:00.000Z",
        isMe: true,
      },
      {
        id: 5,
        senderId: 1,
        text: "It's more like a high-pitched whining sound when I turn the ignition. It's been happening for a few days now.",
        createdAt: "2023-07-25T10:05:00.000Z",
        isMe: false,
      },
      {
        id: 6,
        senderId: 2,
        text: "Alright. That helps narrow it down. Based on your description, it could be an issue with the serpentine belt or the alternator. I recommend bringing your car in for a thorough inspection.",
        createdAt: "2023-07-25T10:07:00.000Z",
        isMe: true,
      },
      {
        id: 7,
        senderId: 1,
        text: "Thanks for the advice. I'll schedule an appointment for tomorrow morning. Hope it's nothing serious.",
        createdAt: "2023-07-25T10:10:00.000Z",
        isMe: false,
      },
      {
        id: 8,
        senderId: 2,
        text: "You're welcome! It's always better to get it checked early to avoid any potential issues. We'll take good care of your car.",
        createdAt: "2023-07-25T10:12:00.000Z",
        isMe: false,
      },
    ],
  },
  {
    id: 2,
    conversationId: 2,
    messages: [
      {
        id: 12,
        senderId: 13,
        text: "Hello! How are you?",
        createdAt: "2020-08-01T12:00:00.000Z",
      },
      {
        id: 23,
        senderId: 23,
        text: "I'm fine, thanks! duuuuuuuh you're cute",
        createdAt: "2020-08-01T12:01:00.000Z",
      },
    ],
  },
];
