import React, { useState, useEffect } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
  generateThreeColumnLayout,
  generateTwoColumnLayout,
} from "./components/editor/layoutUtils";
import { twMerge } from "tailwind-merge";

import { StatContent } from "./components/StatContent";
import Button from "../../../../components/ui/Button";

import PieChart from "../../../../components/ui/charts/PieChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import BarChart from "../../../../components/ui/charts/BarChart";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { ICON_MAP } from "./components/iconMap";
import EmptyDashboard from "./components/EmptyDashboard";

// this has to go outside of the function
const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardLayout = ({ layout, setLayout, editMode }) => {
  const [smLayout, setSmLayout] = useState();
  const [xxsLayout, setXxsLayout] = useState();

  useEffect(() => {
    if (layout?.blocks?.length) {
      setSmLayout(
        layout?.blocks?.length ? generateThreeColumnLayout(layout.blocks) : []
      );
      setXxsLayout(
        layout?.blocks?.length ? generateTwoColumnLayout(layout.blocks) : []
      );
    }
  }, [layout]);

  const handleRemoveItem = (e, i) => {
    e.stopPropagation();
    const newBlocks = layout?.blocks?.filter((item) => item.i !== i) || [];
    const newLayout = {
      ...layout,
      blocks: newBlocks,
    };
    setLayout(newLayout);
    if (layout?.name === "customTest") {
      localStorage.setItem("dashboard-layout", JSON.stringify(newLayout));
    }
  };

  if (!editMode && (!layout.blocks || layout.blocks.length === 0)) {
    return <EmptyDashboard />;
  }

  return (
    <section
      className={twMerge(
        "col-span-8 lg:col-span-10 mr-3 mt-1 ml-1 rounded-md ",
        editMode && "bg-gray-200"
      )}
    >
      <ResponsiveGridLayout
        className="layout "
        layouts={{
          lg: layout.blocks,
          md: smLayout,
          sm: xxsLayout,
          xxs: xxsLayout,
        }}
        isResizable={false}
        isDraggable={editMode}
        // rowHeight={}
        margin={[14, 14]} // [Horizontal Margin, Vertical Margin]
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 4, md: 3, sm: 2, xs: 2, xxs: 2 }}
        // onLayoutChange={handleLayoutChange}
        // need to implement: only handleLayoutChange when dragged or resized, (not from rezsizing screen, which happens by default)
        /*  onResizeStop={(layout) => {
                  console.log("Resizing stopped");
                  handleLayoutChange(layout);
                }} */
        onDragStop={(layout) => {
          console.log("Dragging stopped");
          //   handleSaveLayout(layout);
        }}
        draggableCancel=".draggableCancel"
      >
        {layout?.blocks?.length &&
          layout.blocks.map((block) => {
            // Map the string in block.icon back to its corresponding icon component
            const IconComponent = ICON_MAP[block.icon];

            return (
              <div
                key={block.i}
                className="relative overflow-hidden h-full rounded-lg shadow bg-white px-4 pb-12 pt-5 sm:px-6 sm:pt-6"
                // style={{ boxShadow: "0 1px 2px 2px rgba(0, 0, 0, 0.07)" }}
              >
                {block.type === "summary" && (
                  <div>
                    <StatContent block={block} IconComponent={IconComponent} />

                    {editMode && (
                      <RemoveBlockXButton
                        block={block}
                        handleRemoveItem={handleRemoveItem}
                      />
                    )}
                  </div>
                )}
                {block.type === "chart" && (
                  <div>
                    <p className="ml-16 pb-3 truncate text-sm font-medium text-gray-500">
                      {block.name}
                    </p>
                    {block.chartType === "pie" && (
                      <PieChart
                        labels={[
                          "Less than 3 Days",
                          "3-10 Days",
                          "10-20 Days",
                          "More than 20 Days",
                        ]}
                        seriesData={[25, 30, 20, 10]}
                        width="400"
                      />
                    )}
                    {block.chartType === "bar" && <BarChart />}
                    {block.chartType === "line" && (
                      <LineChart
                        categories={["M", "T", "W", "Th", "F", "S", "Su"]}
                        seriesData={[
                          "3",
                          "5.4",
                          "10",
                          "11.4",
                          "7.7",
                          "8",
                          "10",
                        ]}
                        width="95%"
                        height="210"
                        useMapping={true}
                        mapping={{
                          M: "Monday",
                          T: "Tuesday",
                          W: "Wednesday",
                          Th: "Thursday",
                          F: "Friday",
                          S: "Saturday",
                          Su: "Sunday",
                        }}
                      />
                    )}

                    {editMode && (
                      <RemoveBlockXButton
                        block={block}
                        handleRemoveItem={handleRemoveItem}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </ResponsiveGridLayout>
    </section>
  );
};

export default DashboardLayout;

const RemoveBlockXButton = ({ block, handleRemoveItem }) => {
  return (
    <Button
      size="sm"
      variant="ghost"
      className="remove draggableCancel"
      style={{
        cursor: "pointer",
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 2,
      }}
      onClick={(e) => handleRemoveItem(e, block.i)}
    >
      <XMarkIcon className="h-7 w-7" />
    </Button>
  );
};
