const companyDashboardLayouts = [
  {
    name: "myTasksDefault",
    label: "My Tasks",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Tasks Assigned",
        value: 0,
        icon: "UserPlus",
      },

      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Late Tasks",
        value: 0,
        icon: "ExclamationCircle",
      },
      {
        i: "c",
        x: 2,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Upcoming Jobs",
        value: 0,
        icon: "Briefcase",
      },
    ],
  },
  {
    name: "companyTasksDefault",
    label: "Company Tasks",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Tasks Due",
        value: 0,
        icon: "Clock",
      },

      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Late Tasks",
        value: 0,
        icon: "ExclamationCircle",
      },
      {
        i: "c",
        x: 2,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Blocked Tasks",
        value: 0,
        icon: "Ban",
      },
      {
        i: "d",
        x: 3,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Unassigned Tasks",
        value: 0,
        icon: "UserGroup",
      },
      {
        i: "e",
        x: 0,
        y: 1,
        w: 2,
        h: 2,
        type: "chart",
        chartType: "pie",
        name: "Tasks by Category",
        value: 0,
        icon: "BoltIcon",
      },
    ],
  },
  {
    name: "vehiclePrepDefault",
    label: "Vehicle Prep",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Live Inventory",
        value: 0,
        icon: "Play",
      },

      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Front Line Ready",
        value: 0,
        icon: "Key",
      },
      {
        i: "c",
        x: 2,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "In Prep",
        value: 0,
        icon: "Wrench",
      },
      {
        i: "d",
        x: 3,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "In Staging",
        value: 0,
        icon: "VideoCamera",
      },
      {
        i: "e",
        x: 0,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Pending Check In",
        value: 0,
        icon: "ArrowLeftRectangle",
      },
      {
        i: "f",
        x: 1,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Pending Check Out",
        value: 0,
        icon: "ArrowRightRectangle",
      },
      {
        i: "g",
        x: 2,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "In Transit",
        value: 0,
        icon: "Truck",
      },

      {
        i: "h",
        x: 0,
        y: 2,
        w: 2,
        h: 2,
        type: "chart",
        chartType: "pie",
        name: "Vehicles In Prep",
        value: 0,
        icon: "ArrowPathLoop",
      },
    ],
  },
  {
    name: "financeDefault",
    label: "Finance",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Invoices Due",
        value: 0,
        icon: "BarsArrowDown",
      },

      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Invoices Due",
        value: 0,
        icon: "BarsArrowDown",
      },
      {
        i: "c",
        x: 2,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Pay Reports Owed",
        value: 0,
        icon: "DocumentArrowUp",
      },
      {
        i: "d",
        x: 3,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Owed",
        value: "$5,000",
        icon: "Banknotes",
      },
      {
        i: "e",
        x: 0,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Invoices Owed",
        value: 0,
        icon: "BarsArrowUp",
      },
      {
        i: "f",
        x: 1,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Owed",
        value: 0,
        icon: "Banknotes",
      },

      {
        i: "g",
        x: 0,
        y: 2,
        w: 2,
        h: 2,
        type: "chart",
        chartType: "line",
        name: "Vehicles In Prep",
        value: 0,
        icon: "ArrowPathLoop",
      },
    ],
  },
  {
    name: "main",
    label: "Main",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Dealer Customers",
        value: 0,
        icon: "Truck",
      },
      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Accounts Payable",
        value: 0,
        icon: "EnvelopeOpen",
      },
      {
        i: "c",
        x: 2,
        y: 2,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Users",
        value: 0,
        icon: "CursorArrowRipple",
      },
      {
        i: "d",
        x: 3,
        y: 3,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Pending Tasks",
        value: 0,
        icon: "EnvelopeOpen",
      },
    ],
  },
  {
    name: "customTest",
    label: "Custom Test",
    blocks: [
      {
        i: "d",
        x: 3,
        y: 3,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Pending Tasks",
        value: 0,
        icon: "EnvelopeOpen",
      },
    ],
  },
];

const softwareDashboardLayouts = [
  {
    name: "softwareMain",
    label: "Main",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Dealer Customers",
        value: 0,
        icon: "Truck",
      },
      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Paying Dealer Customers",
        value: 0,
        icon: "EnvelopeOpen",
      },
      {
        i: "c",
        x: 2,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Multi Rooftops",
        value: 0,
        icon: "CursorArrowRipple",
      },
      {
        i: "d",
        x: 3,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Monthly Revenue",
        value: 0,
        icon: "EnvelopeOpen",
      },
      {
        i: "e",
        x: 0,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Annual Revenue",
        value: 0,
        icon: "EnvelopeOpen",
      },
    ],
  },

  {
    name: "financeDefault",
    label: "Finance",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Invoices Due",
        value: 0,
        icon: "BarsArrowDown",
      },

      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Invoices Due",
        value: 0,
        icon: "BarsArrowDown",
      },
      {
        i: "c",
        x: 2,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Pay Reports Owed",
        value: 0,
        icon: "DocumentArrowUp",
      },
      {
        i: "d",
        x: 3,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Owed",
        value: "$5,000",
        icon: "Banknotes",
      },
      {
        i: "e",
        x: 0,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Invoices Owed",
        value: 0,
        icon: "BarsArrowUp",
      },
      {
        i: "f",
        x: 1,
        y: 1,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Owed",
        value: 0,
        icon: "Banknotes",
      },

      {
        i: "g",
        x: 0,
        y: 2,
        w: 2,
        h: 2,
        type: "chart",
        chartType: "line",
        name: "Vehicles In Prep",
        value: 0,
        icon: "ArrowPathLoop",
      },
    ],
  },
  {
    name: "other",
    label: "Other",
    blocks: [
      {
        i: "a",
        x: 0,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Dealer Customers",
        value: 0,
        icon: "Truck",
      },
      {
        i: "b",
        x: 1,
        y: 0,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Accounts Payable",
        value: 0,
        icon: "EnvelopeOpen",
      },
      {
        i: "c",
        x: 2,
        y: 2,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Total Users",
        value: 0,
        icon: "UserGroup",
      },
      {
        i: "d",
        x: 3,
        y: 3,
        w: 1,
        h: 1.2,
        type: "summary",
        name: "Pending Tasks",
        value: 0,
        icon: "EnvelopeOpen",
      },
    ],
  },
  {
    name: "cserviceCompaniesDefault",
    label: "Service Companies",
    blocks: [],
  },
  {
    name: "customTest",
    label: "CustomTest",
    blocks: [],
  },
];

export { companyDashboardLayouts, softwareDashboardLayouts };

// make new defaults here for software dashboard
