import { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";
// import { Toggle } from "../../../../../../../../../../components/ui";

const Rotation = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [rotation, setRotation] = useState("");

  // get rotation of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleObjectRotation = (e) => {
      const obj = e.target;
      setRotation(String(obj.angle.toFixed(1))); // Update rotation state
    };

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];

        setRotation(String(obj.angle.toFixed(1))); // Initialize rotation state
      }
    };

    currentCanvas?.on("object:rotating", handleObjectRotation);
    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("object:rotating", handleObjectRotation); // Unsubscribe from rotation event
    };
  }, [canvasRef.current, setRotation, isCanvasReady]);

  const handleChange = (value) => {
    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && value) {
      activeObjects.forEach((obj) => {
        obj.rotate(parseFloat(value)); // Set new rotation
        obj.setCoords();
      });
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Rotation</Label>
      <Input
        value={rotation}
        onChange={(e) => {
          setRotation(e.target.value);
          handleChange(e.target.value);
        }}
        className="w-full border border-slate-300"
      />
    </section>
  );
};

export default Rotation;
