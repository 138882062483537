import React from "react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import Button from "./Button";
import Dropdown from "./Dropdown";
import Loading from "../fields/Loading";
import PropTypes from "prop-types";

const Pagination = ({
  meta,
  loading,
  onChangePage = () => {},
  onChangeSize = () => {},
  indicatorClassName,
  buttonContainerClassName,
  className,
}) => {
  return (
    <div
      className={twMerge(
        "flex items-center bg-white gap-x-2 p-3.5 px-5 justify-between border-t",
        className
      )}
    >
      <h2
        className={twMerge(
          "font-semibold text-gray-500 text-sm",
          indicatorClassName
        )}
      >
        Showing {meta?.from} - {meta?.to} of {meta?.total_filtered} results
      </h2>

      <div className="flex items-center gap-x-10">
        <Dropdown
          trigger={
            <Button
              variant="ghost"
              mode="outlined"
              className="flex items-center gap-x-2"
            >
              Show {meta?.size}
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            </Button>
          }
        >
          {[10, 25, 50, 100, 500, 1000].map((size) => (
            <Dropdown.Item
              key={size}
              className={twMerge(
                "w-24 text-center",
                size === meta?.size ? "bg-gray-200" : ""
              )}
              onClick={() => {
                onChangeSize(size);
              }}
            >
              {size}
            </Dropdown.Item>
          ))}
        </Dropdown>

        <div className={twMerge("flex gap-x-2", buttonContainerClassName)}>
          <Button
            variant="ghost"
            disabled={meta?.page <= 1}
            onClick={() => {
              meta?.page > 1 && onChangePage(meta?.page - 1);
            }}
          >
            <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
          </Button>

          {loading ? (
            <Loading />
          ) : (
            renderPageNavigationButtons(meta, onChangePage)
          )}

          <Button
            variant="ghost"
            disabled={meta?.page >= meta?.last_page}
            onClick={() => {
              meta?.page < meta?.last_page && onChangePage(meta?.page + 1);
            }}
          >
            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
        </div>
      </div>
    </div>
  );
};

// Function to render page navigation buttons
function renderPageNavigationButtons(meta, onChangePage) {
  const currentPage = meta?.page;
  const lastPage = meta?.last_page;

  if (lastPage <= 3) {
    // If there are 3 or fewer pages, render all page buttons
    return Array.from({ length: lastPage }, (_, index) => (
      <Button
        key={index}
        variant={currentPage === index + 1 ? "primary" : "ghost"} // Highlight current page
        onClick={() => {
          if (currentPage === index + 1) return;
          onChangePage(index + 1);
        }}
      >
        {index + 1}
      </Button>
    ));
  }

  if (currentPage === 1) {
    // If the current page is the first page, show the first 3 pages
    return Array.from({ length: 3 }, (_, index) => (
      <Button
        key={index}
        variant={currentPage === index + 1 ? "primary" : "ghost"}
        onClick={() => {
          if (currentPage === index + 1) return;
          onChangePage(index + 1);
        }}
      >
        {index + 1}
      </Button>
    ));
  }

  if (currentPage === lastPage) {
    // If the current page is the last page, show the last 3 pages
    return Array.from({ length: 3 }, (_, index) => (
      <Button
        key={index}
        variant={currentPage === lastPage - 2 + index ? "primary" : "ghost"}
        onClick={() => {
          if (currentPage === lastPage - 2 + index) return;
          onChangePage(index + 1);
        }}
      >
        {lastPage - 2 + index}
      </Button>
    ));
  } else {
    // If the current page is in the middle, show the previous and next page
    return (
      <>
        <Button variant="ghost" onClick={() => onChangePage(currentPage - 1)}>
          {currentPage - 1}
        </Button>
        <Button
          onClick={() => {
            if (currentPage === currentPage) return;
            onChangePage(currentPage);
          }}
        >
          {currentPage}
        </Button>
        <Button variant="ghost" onClick={() => onChangePage(currentPage + 1)}>
          {currentPage + 1}
        </Button>
      </>
    );
  }
}

Pagination.propTypes = {
  meta: PropTypes.shape({
    count: PropTypes.number,
    total_filtered: PropTypes.number,
    page: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,
    size: PropTypes.number,
    last_page: PropTypes.number,
    search: PropTypes.string,
  }),
  loading: PropTypes.bool,
  onChangePage: PropTypes.func.isRequired,
  onChangeSize: PropTypes.func.isRequired,
  indicatorClassName: PropTypes.string,
  buttonContainerClassName: PropTypes.string,
  className: PropTypes.string,
};
export default Pagination;
