import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";

const FontSize = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentFontSize, setCurrentFontSize] = useState("12");

  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        if (obj.fontSize) {
          setCurrentFontSize(obj.fontSize.toString());
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleFontSizeChange = (e) => {
    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];
      obj.set("fontSize", parseInt(currentFontSize, 10));
      obj.setCoords();
      obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Font Size</Label>
      <Input
        value={currentFontSize}
        onChange={(e) => setCurrentFontSize(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleFontSizeChange(e);
          }
        }}
        onBlur={(e) => handleFontSizeChange(e)}
        min={1}
        className="w-full"
        type="number"
      />
    </section>
  );
};

export default FontSize;
