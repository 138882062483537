import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ColorPicker from "../../../../fields/ControlColorPicker";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

const FeaturesTextColor = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentColor, setCurrentColor] = useState("#000000");
  const debouncedColor = useDebounce(currentColor, 500);

  // get text color of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        if (obj.name === "superFeaturesGroup") {
          setCurrentColor(obj.fill || "#000000");
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleColorChange = (newColor) => {
    setCurrentColor(newColor);

    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];
      if (obj.name === "superFeaturesGroup") {
        updateSuperFeaturesGroup(canvasRef.current, true, obj, {
          textOptions: {
            ...obj?.textOptions,
            fill: newColor,
          },
        });

        obj.setCoords();
        obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
        canvasRef.current?.renderAll();
      }
    }
  };

  return (
    <section className="">
      <Label>Text Color</Label>
      <ColorPicker value={currentColor} onChange={handleColorChange} />
    </section>
  );
};

export default FeaturesTextColor;

const useDebounce = (value, milliSeconds) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};
