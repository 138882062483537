import React from "react";
import { twMerge } from "tailwind-merge";
import Button from "../../../components/ui/Button";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Conversation = ({ conversation, onClick }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  return (
    <div
      key={conversation.id}
      className={twMerge(
        "group flex justify-between border-t items-center p-3 cursor-pointer duration-100 ease-in-out transition hover:bg-gray-100",
        // conversation.seen ? "bg-white" : "bg-slate-50",
        conversation.id === Number(params.id) && "bg-gray-100"
      )}
      onClick={() => {
        onClick
          ? onClick()
          : navigate(`/dashboard/chat/${conversation.id}`, {
              state: {
                from: state?.from,
              },
            });
      }}
    >
      <div className="flex flex-col">
        <h2
          className={twMerge(
            "font-semibold",
            !conversation.seen && "font-bold"
          )}
        >
          {conversation.name}
        </h2>
        <p
          className={twMerge(conversation.seen ? "text-gray-600" : "font-bold")}
        >
          {conversation.lastMessage}
        </p>
      </div>
      {!conversation.seen && (
        <span className="rounded-full h-3 w-3 bg-red-500 group-hover:hidden" />
      )}

      <Button
        variant="ghost"
        mode="outlined"
        size="sm"
        className="rounded-full px-1 border-gray-500 group-hover:flex hidden"
        onClick={() => {
          alert("Clicked");
        }}
      >
        <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" />
      </Button>
    </div>
  );
};

export default Conversation;
