import { Suspense, createElement, useEffect, useState } from "react";
import TableColumns from "../../../../components/ui/table/TableColumns";
import TableBody from "../../../../components/ui/table/TableBody";
import Badge from "../../../../components/ui/Badge";
import { Spinner } from "../../../../components/ui";
import { dummyClosed, dummyOpen } from "./dummy";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Sections from "../../../../components/ui/Sections";
import Button from "../../../../components/ui/Button";
import { TrashIcon } from "@heroicons/react/24/outline";
import NewTimeOffRequestModal from "./NewTimeOffRequestModal";

const TimeOffRequest = ({ user }) => {
  const defaultTabs = [
    {
      name: "Open",
      value: "open",
      href: `status=open`,
      current: true,
      component: Table,
      data: {
        status: "open",
      },
    },
    {
      name: "Closed",
      value: "closed",
      href: `status=closed`,
      current: false,
      component: Table,
      data: {
        status: "closed",
      },
    },
  ];
  const location = useLocation();

  const [tabs, setTabs] = useState(defaultTabs);
  const [showAddModal, setShowAddModal] = useState(false);

  document.title = `Time off Request | ${
    tabs?.find((tab) => tab.current)?.name
  }`;

  useEffect(() => {
    document.title = `Time off Request | ${
      tabs?.find((tab) => tab.current)?.name
    }`;
  }, [tabs]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentTab = params.get("status");

    if (currentTab) {
      const newTabs = tabs.map((tab) => {
        if (tab.value === currentTab) {
          tab.current = true;
          return tab;
        } else {
          tab.current = false;
          return tab;
        }
      });
      setTabs(newTabs);
    }
    //eslint-disable-next-line
  }, [location]);

  return (
    <div className="">
      <div className="flex items-center justify-between px-5">
        <Sections tabs={tabs} className="mb-0" override />

        <Button onClick={() => setShowAddModal(true)}>
          New Time Off Request
        </Button>
      </div>

      <div className="relative overflow-y-auto show_table_scrollbar h-[90vh]">
        {tabs
          .filter((tab) => tab.current)
          .map((tab, idx) => {
            return (
              <Suspense
                fallback={
                  <div
                    key={idx}
                    className="w-full flex justify-center items-center h-[70vh]"
                  >
                    <Spinner size="large" />
                  </div>
                }
                key={idx}
              >
                {/* this is what renders the component */}
                {createElement(tab.component, tab.data)}
              </Suspense>
            );
          })}
      </div>
      <NewTimeOffRequestModal
        show={showAddModal}
        toggle={() => setShowAddModal(false)}
      />
    </div>
  );
};

const Table = ({ status }) => {
  const [timeOffData, setTimeOffData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setTimeOffData(status === "open" ? dummyOpen : dummyClosed);
      setLoading(false);
    }, 1000);
  }, [timeOffData, status]);

  return (
    <table className="w-full">
      <TableColumns
        columnClassName="border-none"
        columns={
          status === "open"
            ? ["Date Submitted", "Type", "Time Requested", "Comments", "Delete"]
            : ["Time Requested", "Comments", "Responder", "Results", "Comments"]
        }
      />

      <TableBody className="divide-gray-200">
        {loading ? (
          <tr>
            <td colSpan={7} align="center" className="h-[70vh]">
              <Spinner size="large" />
            </td>
          </tr>
        ) : (
          timeOffData?.map((data) => {
            return status === "open" ? (
              <tr className="">
                <td className="p-4">
                  {moment(data.date_submitted).format("ll")}
                </td>
                <td className="">{data.type}</td>
                <td className="">
                  {moment(data.time_requested).format("hh:mm A")}
                </td>
                <td className="border-r">{data.comments}</td>
                <td
                  className=" hover:bg-gray-100 cursor-pointer"
                  align="center"
                >
                  <TrashIcon className="h-5 w-5 text-red-500" />
                </td>
              </tr>
            ) : (
              <tr className="">
                <td className="p-4">
                  {moment(data.time_requested).format("hh:mm A")}
                </td>
                <td>{data.comments}</td>
                <td className="">{data.responder}</td>
                <td className="">
                  {data.result === "approved" ? (
                    <Badge variant="green" title="Approved" />
                  ) : (
                    <Badge variant="red" title="Declined" />
                  )}
                </td>
                <td>{data.comments}</td>
              </tr>
            );
          })
        )}
      </TableBody>
    </table>
  );
};
export default TimeOffRequest;
