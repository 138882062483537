import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Select from "../../../../fields/ControlSelect";

const LineHeight = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentLineHeight, setCurrentLineHeight] = useState("1"); // Default line height

  const lineHeightOptions = [
    { label: "1", value: "1" },
    { label: "1.1", value: "1.1" },
    { label: "1.2", value: "1.2" },
    { label: "1.4", value: "1.4" },
    { label: "1.6", value: "1.6" },
    { label: "1.8", value: "1.8" },
    { label: "2", value: "2" },
  ];

  // get line height of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];

        if (obj.type === "i-text" || obj.type === "textbox") {
          console.log(obj.lineHeight);
          if (obj.lineHeight) {
            setCurrentLineHeight(obj.lineHeight.toString());
          }
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleLineHeightChange = (newLineHeight) => {
    setCurrentLineHeight(newLineHeight);

    const activeObjects = canvasRef.current?.getActiveObjects();

    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];

      if (obj.type === "i-text" || obj.type === "textbox") {
        obj.set("lineHeight", parseFloat(newLineHeight));
      }

      obj.setCoords();
      obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Line Height</Label>
      <Select
        options={lineHeightOptions}
        onChange={handleLineHeightChange}
        value={currentLineHeight}
      />
    </section>
  );
};

export default LineHeight;
