import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

function Badge({ title, variant = "gray", size = "md", className }) {
  const getBadgeClass = () => {
    switch (variant) {
      case "gray":
        return "bg-gray-50 text-gray-600 ring-gray-500/10";
      case "red":
        return "bg-red-50 text-red-700 ring-red-600/10";
      case "yellow":
        return "bg-yellow-50 text-yellow-800 ring-yellow-600/20";
      case "green":
        return "bg-green-50 text-green-700 ring-green-600/20";
      case "blue":
        return "bg-blue-50 text-blue-700 ring-blue-700/10";
      case "indigo":
        return "bg-indigo-50 text-indigo-700 ring-indigo-700/10";
      case "purple":
        return "bg-purple-50 text-purple-700 ring-purple-700/10";
      case "pink":
        return "bg-pink-50 text-pink-700 ring-pink-700/10";
      case "orange":
        return "bg-orange-50 text-orange-700 ring-orange-700/10";
      case "teal":
        return "bg-teal-50 text-teal-700 ring-teal-700/10";
      case "cyan":
        return "bg-cyan-50 text-cyan-700 ring-cyan-700/10";
      case "lime":
        return "bg-lime-50 text-lime-700 ring-lime-700/10";
      case "amber":
        return "bg-amber-50 text-amber-700 ring-amber-700/10";
      default:
        return "bg-gray-50 text-gray-600 ring-gray-500/10";
    }
  };

  const getSizeClass = () => {
    switch (size) {
      case "sm":
        return "text-xs px-2 py-1";
      case "md":
        return "text-sm px-2.5 py-0.5";
      case "lg":
        return "text-base px-3 py-1";
      default:
        return "text-sm px-2.5 py-0.5";
    }
  };

  const badgeClass = twMerge(
    `inline-flex items-center rounded-md font-medium ring-1 ring-inset ${getBadgeClass()} ${getSizeClass()}`,
    className
  );

  return <span className={badgeClass}>{title}</span>;
}

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    "gray",
    "red",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
    "orange",
    "teal",
    "cyan",
    "lime",
    "amber",
  ]).isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  className: PropTypes.string,
};

Badge.defaultProps = {
  size: "md",
  className: "",
};

export default Badge;
