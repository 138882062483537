import React from "react";
import useCanvas from "../../../../Context";
import Button from "../../../../fields/ControlButton";
import useCsvData from "../../../../../data/useCSVdata";
import { fabric } from "fabric";
import { first } from "lodash";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

const InsertRealFeatures = () => {
  const { canvasRef, lastSelectedObject } = useCanvas();
  const { jsonData, loading } = useCsvData();
  if (loading) {
    return <div>Loading...</div>; // Return a loading indicator when data is being fetched
  }
  //   console.log(jsonData);

  const firstVehicleOptions = jsonData[0]?.Options?.split(",");
  //   console.log(combineAmFmCdPlayer(firstVehicleOptions));

  const handleButtonClick = () => {
    if (
      lastSelectedObject &&
      lastSelectedObject.name === "superFeaturesGroup"
    ) {
      updateSuperFeaturesGroup(canvasRef.current, true, lastSelectedObject, {
        realFeaturesArray: firstVehicleOptions,
      });
    }
  };

  return (
    <div>
      <Button className="text-xs w-full mt-3" onClick={handleButtonClick}>
        Insert Real Features
      </Button>
    </div>
  );
};

export default InsertRealFeatures;
