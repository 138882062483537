import useCanvas from "../../Context";
import Button from "../../fields/ControlButton";
import TooltipWrapper from "../../fields/TooltipWrapper";

const Delete = () => {
  const { deleteItems } = useCanvas();

  return (
    <TooltipWrapper title="Delete Selected Item(s)">
      <Button
        onClick={deleteItems}
        className="py-[2px] text-sm whitespace-nowrap"
      >
        Delete
      </Button>
    </TooltipWrapper>
  );
};

export default Delete;
