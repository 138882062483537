import { fabric } from "fabric";

// Extend the toObject method for Group to include new properties
fabric.Group.prototype.toObject = (function (toObject) {
  return function () {
    const baseObject = toObject.call(this);
    if (this.name === "superFeaturesGroup") {
      baseObject.numColumns = this.numColumns;
      baseObject.columnSpacing = this.columnSpacing;
      baseObject.targetSuperGroupWidth = this.targetSuperGroupWidth;
      baseObject.totalNumFeatures = this.numOfFeatures;
      baseObject.verticalSpacing = this.verticalSpacing;
      baseObject.bulletTextSpacing = this.bulletTextSpacing;
      baseObject.bulletLeftMargin = this.bulletLeftMargin;
      baseObject.loremArray = this.loremArray;
      baseObject.realFeaturesArray = this.realFeaturesArray;
      baseObject.textOptions = this.textOptions;
    }
    // Include groupType in the saved object
    baseObject.name = this.name;
    return baseObject;
  };
})(fabric.Group.prototype.toObject);
