import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

interface ColorPickerProps {
  id?: string;
  className?: string;
  onChange?: (color: string) => void;
  value?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  id,
  className,
  onChange,
  value,
}) => {
  const [hexValue, setHexValue] = useState(value || "#ffffff");

  useEffect(() => {
    setHexValue(value || "#ffffff");
  }, [value]);

  const handleColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setHexValue(newColor);

    if (onChange) {
      onChange(newColor);
    }
  };

  const handleHexInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newHex = e.target.value;
    setHexValue(newHex);

    if (newHex.startsWith("#") && newHex.length === 7) {
      if (onChange) {
        onChange(newHex);
      }
    }
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={hexValue}
        onChange={handleHexInputChange}
        className={twMerge(
          "border rounded-sm py-1.5 pl-1.5 text-xs border-slate-300 shadow-sm w-full z-10",
          className
        )}
      />
      <input
        type="color"
        id={id || ""}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        onChange={handleColorChange}
        value={hexValue}
      />
    </div>
  );
};

export default ColorPicker;
