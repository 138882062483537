import { userStore } from "../../stores";

export const withPermissions = (Component, permission) => {
  function ProtectedComponent(props) {
    const user = userStore((state) => state.user);

    if (user?.permissions?.page?.includes(permission)) {
      return <Component {...props} />;
    } else {
      return (
        <div className="p-5 flex justify-center items-center h-screen relative">
          <div className="flex flex-col text-center items-center space-y-2">
            <h1 className="text-4xl font-bold text-gray-700 flex items-center">
              403
            </h1>
            <h2 className="text-2xl font-bold text-gray-700">Access Denied</h2>
            <span className="text-gray-400 font-semibold">
              Sorry, but you don't have permission to access this page.
            </span>
            <span className="text-gray-400 font-semibold">
              Try to contact your administrator.
            </span>
          </div>
        </div>
      );
    }
  }
  return ProtectedComponent;
};

export const ensurePermission = (permission) => {
  const user = userStore((state) => state.user);
  return user?.permissions?.page?.includes(permission);
};
