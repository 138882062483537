import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Landing from "./containers/Landing";
// import Signup from './containers/signup'
import Signin from "./containers/Signin";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPasword";
import FourOhFour from "./containers/404";
import SplashScreen from "./containers/SplashScreen";
import Invitation from "./containers/Invitation";

import { SoftwareDashboard, CompanyDashboard } from "./containers/Dashboard";

import { refreshSession } from "./services/auth";

import { userStore } from "./stores";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  setPrimaryColor,
  setSecondaryColor,
} from "./theme";
import PublicGallery from "./containers/PublicGallery";
import FabricEditor from "./containers/Company/components/CompanySettings/components/Merchandising/FabricEditor";

function App() {
  const loadingUser = userStore((state) => state.loadingUser);
  const setLoadingUser = userStore((state) => state.setLoadingUser);
  const user = userStore((state) => state.user);
  const storeCompany = userStore((state) => state.storeCompany);
  const storeUser = userStore((state) => state.storeUser);
  const removeUser = userStore((state) => state.removeUser);

  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    if (!user) {
      refreshSession().then((response) => {
        if (response.status === 200) {
          storeUser(response.data.user);
          storeCompany(response?.data?.user?.company);
        } else {
          removeUser();
          setRedirectTo("/signin");
        }
        setLoadingUser(false);
      });
    }
    setLoadingUser(true);
    configureTheme();
    // eslint-disable-next-line
  }, [user]);

  const configureTheme = () => {
    const primaryColorStorage = window.localStorage.getItem(
      "primaryColorStorage"
    );
    const secondaryColorStorage = window.localStorage.getItem(
      "secondaryColorStorage"
    );
    const primaryTextColorStorage = window.localStorage.getItem(
      "primaryTextColorStorage"
    );
    const alternateTextColorStorage = window.localStorage.getItem(
      "alternateTextColorStorage"
    );
    const secondaryTextColorStorage = window.localStorage.getItem(
      "secondaryTextColorStorage"
    );
    const linkColorStorage = window.localStorage.getItem("linkColorStorage");
    const labelColorStorage = window.localStorage.getItem("labelColorStorage");

    setPrimaryColor(
      user?.primaryColor || user?.company?.primaryColor || PRIMARY_COLOR
    );
    setSecondaryColor(
      user?.secondaryColor || user?.company?.secondaryColor || SECONDARY_COLOR
    );

    if (primaryColorStorage !== PRIMARY_COLOR) {
      window.localStorage.setItem("primaryColorStorage", PRIMARY_COLOR);
    }
    if (secondaryColorStorage !== SECONDARY_COLOR) {
      window.localStorage.setItem("secondaryColorStorage", SECONDARY_COLOR);
    }
  };
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/signin" />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/signin" element={<Signin />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/public-gallery/:id" element={<PublicGallery />} />
        <Route
          path="/window-sticker-editor/:editorName"
          element={<FabricEditor isStickerEditor={true} />}
        />
        <Route
          path="/overlay-editor/:editorName"
          element={<FabricEditor isStickerEditor={false} />}
        />

        <Route path="/acceptInvitation/:token" element={<Invitation />} />
        <Route path="/404" element={<FourOhFour />} />
        {user && user?.company?.companyType?.name !== "software" && (
          <>
            <Route path="/dashboard/*" element={<CompanyDashboard />} />
          </>
        )}
        {user && user?.company?.companyType?.name === "software" && (
          <>
            <Route path="/dashboard/*" element={<SoftwareDashboard />} />
          </>
        )}
        {!user && loadingUser && <Route path="*" element={<SplashScreen />} />}
        <Route path="*" element={<FourOhFour redirectTo={redirectTo} />} />
      </Routes>
    </Router>
  );
}

export default App;
