import React, { useState, useRef, useEffect } from "react";
import * as RadixPopover from "@radix-ui/react-popover";
import useCanvas from "../Context";
import { twMerge } from "tailwind-merge";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/solid";

interface AddNewOptionProps {
  title: string;
  onClick: () => void; // Define the function that opens the modal
}

interface SelectProps<T> {
  className?: string;
  triggerClassName?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  options: Array<{ label: string; value: string }>;
  value?: string; // initial value
  highlightSelected?: boolean;
  addNewOption?: AddNewOptionProps;
}

const Select: React.FC<SelectProps<any>> = ({
  className,
  triggerClassName,
  placeholder,
  onChange,
  options,
  value,
  highlightSelected,
  addNewOption,
}) => {
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [contentWidth, setContentWidth] = useState(0);
  const triggerRef = useRef(null);
  const { tabChanged } = useCanvas();

  useEffect(() => {
    const initialOption = options.find((option) => option.value === value);
    setSelectedOption(initialOption || null);
  }, [value, options]);

  // -------------------------     Content Width functions     -------------------------
  const updateContentWidth = () => {
    if (triggerRef.current) {
      const width =
        (triggerRef?.current?.getBoundingClientRect()?.width || 200) + 18;
      setContentWidth(width);
    }
  };
  // Initial width update
  useEffect(() => {
    updateContentWidth();
  }, [triggerRef, tabChanged]);

  // Update width when window is resized
  useEffect(() => {
    window.addEventListener("resize", updateContentWidth);
    return () => {
      window.removeEventListener("resize", updateContentWidth);
    };
  }, []);
  // ---------------------------------------------------------------------------------------

  const defaultClasses =
    "relative w-full flex justify-between border rounded-sm py-[2px] px-2 shadow-sm border-slate-300 text-slate-900 bg-white text-sm";
  const mergedClasses = twMerge(defaultClasses, className);

  const handleSelect = (option: { label: string; value: string }) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option.value);
    }
  };

  return (
    <div className={mergedClasses}>
      <RadixPopover.Root>
        <RadixPopover.Trigger
          ref={triggerRef} // possibel cause of console error about nested ref in button
          className={twMerge(
            "flex gap-1 justify-between items-center w-full text-xs text-gray-900",
            triggerClassName
          )}
        >
          {selectedOption
            ? selectedOption.label
            : placeholder
            ? placeholder
            : "Select an Option"}
          <ChevronDownIcon
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </RadixPopover.Trigger>
        <RadixPopover.Portal className="w-full">
          <RadixPopover.Content
            style={{ width: contentWidth ? `${contentWidth}px` : "200px" }}
            className="bg-white shadow-lg p-0 rounded-md z-[9999] max-h-[400px] overflow-auto"
          >
            <div className="flex flex-col flex-grow gap-1 items-start justify-start text-start w-full p-0">
              {options.map((option, index) => (
                <RadixPopover.Close
                  key={index}
                  onClick={() => handleSelect(option)}
                  className={twMerge(
                    "px-3 py-1 flex-grow text-xs w-full text-start hover:bg-slate-200 ",
                    highlightSelected && selectedOption?.value === option?.value
                      ? "bg-slate-100"
                      : ""
                  )}
                >
                  <div className="flex flex-grow w-full">
                    <span>{option.label}</span>
                  </div>
                </RadixPopover.Close>
              ))}
              {/* New "Add New Option" Button */}
              {addNewOption && (
                <button
                  onClick={addNewOption.onClick} // open modal function
                  className="px-3 py-1 text-xs w-full text-start hover:bg-slate-200 bg-slate-50 flex items-center justify-between"
                >
                  {addNewOption.title}{" "}
                  <PlusIcon className="h-4 w-4 text-gray-700" />
                </button>
              )}
            </div>
            {/* <RadixPopover.Arrow fill="gray" style={{ width: "20px", height: "4px" }} /> */}
            <RadixPopover.Arrow fill="none" />
          </RadixPopover.Content>
        </RadixPopover.Portal>
      </RadixPopover.Root>
    </div>
  );
};

export default Select;
