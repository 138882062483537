import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";
import Select from "../../../../fields/ControlSelect";

const FeaturesFontWeight = () => {
  const { canvasRef, lastSelectedObject, isCanvasReady } = useCanvas();
  const [fontWeight, setFontWeight] = useState("");

  /*   useEffect(() => {
    setFontWeight(lastSelectedObject?.textOptions?.fontWeight || "");
  }, [lastSelectedObject]); */

  const fontWeightOptions = [
    { label: "100 - Thin", value: "100" },
    { label: "200 - Extra Light", value: "200" },
    { label: "300 - Light", value: "300" },
    { label: "400 - Normal", value: "400" },
    { label: "500 - Medium", value: "500" },
    { label: "600 - Semi Bold", value: "600" },
    { label: "700 - Bold", value: "700" },
    { label: "800 - Extra Bold", value: "800" },
    { label: "900 - Heavy", value: "900" },
  ];

  // get font weight of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;
    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];

        if (obj.name === "superFeaturesGroup") {
          setFontWeight(obj.fontWeight || "400");
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady, lastSelectedObject]);

  const handleChange = (newFontWeight) => {
    setFontWeight(newFontWeight);
    if (
      lastSelectedObject &&
      lastSelectedObject.name === "superFeaturesGroup"
    ) {
      updateSuperFeaturesGroup(canvasRef.current, true, lastSelectedObject, {
        textOptions: {
          ...lastSelectedObject?.textOptions,
          fontWeight: newFontWeight,
        },
      });
    }
  };

  return (
    <section className="">
      <Label>Font Weight</Label>
      <Select
        options={fontWeightOptions}
        onChange={handleChange}
        value={fontWeight}
      />
    </section>
  );
};

export default FeaturesFontWeight;
