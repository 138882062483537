import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ButtonGroup from "../../../../fields/ControlButtonGroup";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

const Columns = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentCols, setCurrentCols] = useState();

  useEffect(() => {
    const activeObject = canvasRef.current.getActiveObject();
    setCurrentCols(activeObject?.numColumns);
  }, [canvasRef.current, isCanvasReady]);

  const handleColumnChange = (newNumColumns) => {
    const activeObject = canvasRef.current.getActiveObject();

    if (activeObject && activeObject.name === "superFeaturesGroup") {
      updateSuperFeaturesGroup(canvasRef.current, true, activeObject, {
        numColumns: newNumColumns,
      });
    }
    setCurrentCols(newNumColumns);
  };
  const buttons = [
    {
      name: "1",
      onClick: () => handleColumnChange(1),
      isActive: currentCols === 1,
    },
    {
      name: "2",
      onClick: () => handleColumnChange(2),
      isActive: currentCols === 2,
    },
    {
      name: "3",
      onClick: () => handleColumnChange(3),
      isActive: currentCols === 3,
    },
    {
      name: "4",
      onClick: () => handleColumnChange(4),
      isActive: currentCols === 4,
    },
  ];

  return (
    <section className="">
      <Label>Columns</Label>
      <ButtonGroup buttons={buttons} />
    </section>
  );
};

export default Columns;
