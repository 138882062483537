import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

const FeaturesFontSize = () => {
  const { canvasRef, lastSelectedObject, isCanvasReady } = useCanvas();
  const [currentFontSize, setCurrentFontSize] = useState("12");

  useEffect(() => {
    setCurrentFontSize(lastSelectedObject?.textOptions?.fontSize);
  }, [canvasRef.current]);

  const handleChange = () => {
    if (
      lastSelectedObject &&
      lastSelectedObject.name === "superFeaturesGroup"
    ) {
      console.log({ ...lastSelectedObject?.textOptions });
      updateSuperFeaturesGroup(canvasRef.current, true, lastSelectedObject, {
        textOptions: {
          ...lastSelectedObject?.textOptions,
          fontSize: currentFontSize,
        },
      });
    }
  };
  return (
    <section className="">
      <Label>Font Size</Label>
      <Input
        value={currentFontSize}
        onChange={(e) => setCurrentFontSize(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleChange();
          }
        }}
        onBlur={() => handleChange()}
        min={1}
        className="w-full"
        type="number"
      />
    </section>
  );
};

export default FeaturesFontSize;
