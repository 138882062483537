const basicOperations = (canvasRef, clipboardRef) => {
  const copy = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const canvas = canvasRef?.current;
      const activeObject = canvas?.getActiveObject();

      if (activeObject) {
        activeObject.clone((cloned) => {
          clipboardRef.current = cloned;
          resolve();
        });
      } else {
        reject("No active object to copy.");
      }
    });
  };

  const paste = () => {
    const canvas = canvasRef?.current;
    const clipboard = clipboardRef.current;
    if (clipboard && canvas) {
      clipboard.clone(function (clonedObj) {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === "activeSelection") {
          clonedObj.canvas = canvas;
          clonedObj.forEachObject(function (obj) {
            canvas.add(obj);
          });
          clonedObj.setCoords();
        } else {
          canvas.add(clonedObj);
        }
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
      });
    }
  };
  const duplicate = async () => {
    try {
      await copy();
      paste();
    } catch (err) {
      console.log("Failed to duplicate:", err);
    }
  };

  const deleteItems = () => {
    const canvas = canvasRef?.current;
    const activeObjects = canvas.getActiveObjects();
    if (activeObjects) {
      activeObjects.forEach((obj) => {
        canvas.remove(obj);
      });
      canvas.discardActiveObject(); // Clear the active selection
      canvas.renderAll();
    }
  };

  return { copy, paste, duplicate, deleteItems };
};

export default basicOperations;
