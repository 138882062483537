import React from "react";
import { Modal } from "../../../../../../components/ui";
import { Input } from "../../../../../../components/ui";
import Button from "../../../../../../components/ui/Button";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const DeleteWidgetModal = ({ open, closeModal, customWidgets, modal }) => {
  return (
    <Modal
      title={""}
      open={open}
      modalStyle={`md:max-w-md overflow-y-auto`}
      placement="top"
      setOpen={closeModal}
      ui={(() => {
        const widgetName =
          customWidgets.find((w) => w.id === modal.id)?.name || "unknown";

        return (
          <>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {`Delete ${widgetName}`}
                </h3>
                <div className="mt-2 text-sm text-gray-500">
                  <p className="">
                    Are you sure you want to delete {widgetName}?
                  </p>
                  <p>This action cannot be undone.</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 flex gap-3 justify-end">
              <Button size="sm" variant="ghost" onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button size="sm" variant="" onClick={() => closeModal()}>
                Delete
              </Button>
            </div>
          </>
        );
      })()}
    />
  );
};

export default DeleteWidgetModal;
