import { fabric } from "fabric";

export const options = {
  backgroundColor: "white", // objects only show on a white canvas - will have to make the canvas transparent when saving for overlays
  selectionColor: "blue",
  selectionLineWidth: 2,
  hoverCursor: "pointer",
  moveCursor: "move",
  isDrawingMode: false,
  enableRetinaScaling: true,
  //   lockUniScaling: true,

  selectionBackgroundColor: "#444444",
};

export const selectorSettings = (canvasRef: React.RefObject<fabric.Canvas>) => {
  const canvas = canvasRef.current;
  if (canvas) {
    canvas.selectionColor = "rgba(0,0,0,0.1)";
    canvas.selectionBorderColor = "#444444";
    canvas.selectionLineWidth = 1;
  }
};

// these are used by dom canvas ( fabricEditor/index ) and setZoom function
export const aspectRatio = 1.5;
export const initialCanvasWidth = 1100;
export const initialCanvasHeight = initialCanvasWidth / aspectRatio;
