import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Select from "../../../../fields/ControlSelect";

const FontWeight = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentFontWeight, setCurrentFontWeight] = useState("400"); // Default font-weight

  const fontWeightOptions = [
    { label: "100 - Thin", value: "100" },
    { label: "200 - Extra Light", value: "200" },
    { label: "300 - Light", value: "300" },
    { label: "400 - Normal", value: "400" },
    { label: "500 - Medium", value: "500" },
    { label: "600 - Semi Bold", value: "600" },
    { label: "700 - Bold", value: "700" },
    { label: "800 - Extra Bold", value: "800" },
    { label: "900 - Heavy", value: "900" },
  ];

  // get font weight of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];

        if (obj.type === "i-text" || obj.type === "textbox") {
          setCurrentFontWeight(obj.fontWeight || "400");
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleFontWeightChange = (newFontWeight) => {
    setCurrentFontWeight(newFontWeight);

    const activeObjects = canvasRef.current?.getActiveObjects();

    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];

      if (obj.type === "i-text" || obj.type === "textbox") {
        obj.set("fontWeight", newFontWeight);
      }

      obj.setCoords();
      obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Font Weight</Label>
      <Select
        options={fontWeightOptions}
        onChange={handleFontWeightChange}
        value={currentFontWeight}
      />
    </section>
  );
};

export default FontWeight;
