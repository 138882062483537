import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Checkbox = ({
  className,
  checkboxClassName,
  label,
  disabled,
  checked,
  size,
  variant,
  id,
  labelPosition,
  ...rest
}) => {
  const checkboxId =
    id || `checkbox-${Math.random().toString(36).substr(2, 9)}`;

  const getSizeClass = () => {
    switch (size) {
      case "xs":
        return "h-3 w-3";
      case "sm":
        return "h-4 w-4";
      case "md":
        return "h-5 w-5";
      case "lg":
        return "h-6 w-6";
      default:
        return "h-4 w-4";
    }
  };

  return (
    <div className={twMerge("flex items-center gap-x-2", className)}>
      {label && labelPosition === "left" && (
        <label htmlFor={checkboxId} className="cursor-pointer text-gray-700">
          {label}
        </label>
      )}
      <input
        {...rest}
        id={checkboxId}
        type="checkbox"
        className={twMerge(
          getSizeClass(),
          checkboxClassName,
          "transition-all ease-in-out duration-150 cursor-pointer"
        )}
        disabled={disabled}
        checked={checked}
      />
      {label && labelPosition === "right" && (
        <label htmlFor={checkboxId} className="cursor-pointer text-gray-700">
          {label}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  labelPosition: PropTypes.oneOf(["left", "right"]),
};

Checkbox.defaultProps = {
  labelPosition: "right",
  size: "sm",
};

export default Checkbox;
