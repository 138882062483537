import { Routes, Route } from "react-router-dom";
import CompanyDashboard from "./CompanyDashboard";
import DashboardEditor from "../Dashboard/DashboardEditor";
import { withPermissions } from "../../../../Hooks/withPermissions";

const CompanyMain = ({ company }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CompanyDashboard />} />
        <Route path="dashboard-editor" element={<DashboardEditor />} />
      </Routes>
    </>
  );
};

export default withPermissions(CompanyMain, "DASHBOARD");
