import { useState } from "react";
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from "../../theme";
import { twMerge } from "tailwind-merge";
import { get } from "lodash";
import PropTypes from "prop-types";
import Loading from "./Loading";

const TextAreaField = ({
  label,
  field,
  formState,
  className,
  textAreaClassName,
  labelClassName,
  placeholder,
  required,
  isDisabled,
  rows = 4,
  errorClassName,
}) => {
  const [focus, setFocus] = useState(false);
  const hasError =
    formState.errors[field.name] || get(formState.errors, field.name); // for nested fields;

  return (
    <div className={twMerge("relative", className)}>
      {label && (
        <label
          htmlFor={field.name}
          className={twMerge(
            "my-2 block text-sm font-medium text-gray-800",
            labelClassName
          )}
        >
          {label}
          {required && (
            <span className="text-red-500 ml-1" aria-label="required">
              *
            </span>
          )}
        </label>
      )}
      <div className="relative">
        <textarea
          {...field}
          style={{
            borderColor: hasError ? "red" : focus ? PRIMARY_COLOR : "",
            borderWidth: "1px",
            borderStyle: "solid",
            outline: "none",
            color: PRIMARY_TEXT_COLOR,
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          rows={rows}
          disabled={isDisabled || formState.isLoading}
          placeholder={placeholder}
          className={twMerge(
            "block mt-2 p-3 pt-2 w-full rounded-md text-gray-900 border-none bg-transparent focus:border-none focus:ring-0",
            textAreaClassName,
            formState.isLoading && "bg-gray-50"
          )}
        />

        {formState.isLoading && (
          <div className="absolute top-0 right-0 p-3">
            <Loading className="" />
          </div>
        )}
      </div>

      {hasError && (
        <p
          className={twMerge(
            "text-sm text-end text-red-500 mt-1 absolute right-0",
            errorClassName
          )}
        >
          {hasError?.message}
        </p>
      )}
    </div>
  );
};

TextAreaField.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  className: PropTypes.string,
  textAreaClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  rows: PropTypes.number,
  errorClassName: PropTypes.string,
};

TextAreaField.defaultProps = {
  //   label: "Text Area",
  rows: 4,
};

export default TextAreaField;
