import { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";

const BorderWidth = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [strokeWidth, setStrokeWidth] = useState("");

  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleObjectStroke = (e) => {
      const obj = e.target;
      setStrokeWidth(String(obj.strokeWidth || 0));
    };

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        setStrokeWidth(String(obj.strokeWidth || 0));
      }
    };

    currentCanvas?.on("object:modified", handleObjectStroke);
    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("object:modified", handleObjectStroke);
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleBlur = () => {
    updateStrokeWidth();
  };

  const handleChange = (e) => {
    setStrokeWidth(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateStrokeWidth();
    }
  };

  const updateStrokeWidth = () => {
    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && strokeWidth !== "") {
      activeObjects.forEach((obj) => {
        obj.set("strokeWidth", parseFloat(strokeWidth));
        obj.setCoords();
      });
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="">
      <Label>Border Width</Label>
      <Input
        value={strokeWidth}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        className="w-full border border-slate-300"
      />
    </section>
  );
};

export default BorderWidth;
