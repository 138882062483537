import useCanvas from "../../Context/index";
import Text from "./components/Text";
import Label from "../../fields/ControlLabel";
import Image from "./components/Image";
import Shapes from "./components/Shapes";
import CarToPreview from "./components/CarToPreview";
import BackgroundImagePreview from "./components/BackgroundImagePreview";
import useGetCars from "../../utils/helpers/getCars";
import FeaturesGroup from "./components/FeaturesGroup";

const AddControls = () => {
  const { editorType, lastSelectedObject } = useCanvas();
  const { cars, reloadCars, loadingCars } = useGetCars();

  return (
    <>
      <div>
        <Label containerClassName="mb-1">Car to Preview:</Label>
        <CarToPreview
          cars={cars}
          reloadCars={reloadCars}
          loadingCars={loadingCars}
        />
        {/* <InsertRealFeatures /> */}
      </div>
      <div>
        <Label containerClassName="mb-1">Image to Preview:</Label>
        <BackgroundImagePreview />
      </div>
      <div>
        <Label containerClassName="mb-1">Static Elements</Label>
        <div className="flex flex-col gap-2">
          <Image className="w-full " />
          <Text buttonTitle="Text" staticContent="" dynamic={false} />
          <Text
            buttonTitle="Sample Text"
            staticContent="Lorem ipsum dolor sit namet, consectetur nadipiscing elit."
            dynamic={false}
          />
          <Shapes />
        </div>
      </div>
      {/* {editorType === "window-sticker" && <div>thumbnail</div>} */}

      <div>
        <Label containerClassName="mb-1">Data Fields</Label>
        <div className="flex flex-col gap-2">
          <Text
            buttonTitle="Year, Make, Model"
            carFields={["Year", "Make", "Model"]}
            dynamic={true}
          />
          <Text
            buttonTitle="Year, Make, Model, VIN"
            carFields={["Year", "Make", "Model", "VIN"]}
            dynamic={true}
          />
          <Text buttonTitle="Mileage" carFields={["Mileage"]} dynamic={true} />
          <Text
            buttonTitle="Internet Price"
            carFields={["InternetPrice"]}
            dynamic={true}
          />
          <Text
            buttonTitle="Company Name"
            dynamic={true}
            carFields={["Company Name"]}
          />
          <FeaturesGroup />
          <Text
            buttonTitle="Custom Car Field 1"
            staticContent="Custom Car Field 1"
            carFields={["Custom Car Field 1"]}
          />
          <Text
            buttonTitle="Custom Car Field 2"
            staticContent="Custom Car Field 2"
            carFields={["Custom Car Field 1"]}
          />
        </div>
      </div>
    </>
  );
};

export default AddControls;
