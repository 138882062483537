import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const TableBody = ({ children, className }) => {
  return (
    <tbody
      className={twMerge("divide-y p-5 divide-gray-300 bg-white", className)}
    >
      {children}
    </tbody>
  );
};

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TableBody;
