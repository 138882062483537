import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ButtonGroup from "../../../../fields/ControlButtonGroup";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

const ColumnSpacing = () => {
  const { canvasRef, isCanvasReady, lastSelectedObject } = useCanvas();
  const [currentSpacing, setCurrentSpcing] = useState();

  useEffect(() => {
    setCurrentSpcing(lastSelectedObject?.columnSpacing);
  }, [canvasRef.current]);

  const handleChange = (newSpacing) => {
    const activeObject = canvasRef.current.getActiveObject();
    if (activeObject && activeObject.name === "superFeaturesGroup") {
      updateSuperFeaturesGroup(canvasRef.current, true, activeObject, {
        columnSpacing: newSpacing,
      });
    }
    setCurrentSpcing(newSpacing);
  };

  const buttons = [
    {
      name: "1",
      onClick: () => handleChange(10),
      isActive: currentSpacing === 10,
    },
    {
      name: "2",
      onClick: () => handleChange(25),
      isActive: currentSpacing === 25,
    },
    {
      name: "3",
      onClick: () => handleChange(50),
      isActive: currentSpacing === 50,
    },
    {
      name: "4",
      onClick: () => handleChange(75),
      isActive: currentSpacing === 75,
    },
  ];

  return (
    <section className="">
      <Label>Column Spacing</Label>
      <ButtonGroup buttons={buttons} />
    </section>
  );
};

export default ColumnSpacing;
