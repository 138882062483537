import React from "react";
import PropTypes from "prop-types";
import { sidebar1Store } from "../../stores";
import { twMerge } from "tailwind-merge";

const FixedToBottom = ({ children }) => {
  const sidebarOpen = sidebar1Store((state) => state.sidebar1Open);
  //   console.log(sidebarOpen);
  if (!children) return null;

  const contentWidth = sidebarOpen ? "md:pl-64" : "md:pl-16";

  return (
    <div
      className={twMerge(
        "fixed bottom-0 w-full right-0  duration-300 ease-in-out",
        contentWidth
      )}
    >
      {children}
    </div>
  );
};

FixedToBottom.propTypes = {
  children: PropTypes.node,
};

export default FixedToBottom;
