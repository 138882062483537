import React, { Fragment } from "react";
import Popover from "../../../../components/ui/Popover";
import { Spinner } from "../../../../components/ui";
import ScrollArea from "../../../../components/ui/ScrollArea";
import NotificationItem from "./NotificationItem";
import { getNotifications } from "../../api";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { isEmpty } from "lodash";

const NotificationBody = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["notifications"], () =>
    getNotifications({
      page: 1,
      limit: 25,
    })
  );

  const items = data?.items;
  return (
    <Fragment>
      <div className="flex items-center justify-between gap-x-2 px-4 py-2">
        <h2 className="text-lg font-bold text-gray-600">Notifications</h2>
        <Popover.CloseOnClickItem
          className="flex hover:bg-gray-200 rounded-full p-1 transition ease-in-out"
          onClick={() => {
            navigate("/dashboard/profile?tab=notifications");
          }}
        >
          <Cog6ToothIcon
            className="h-6 w-6 cursor-pointer"
            aria-hidden="true"
            color="gray"
          />
        </Popover.CloseOnClickItem>
      </div>
      {isLoading ? (
        <div className="flex w-[400px] items-center justify-center h-[50vh] border-y">
          <Spinner size="large" />
        </div>
      ) : isEmpty(items) ? (
        <div className="flex w-[400px] items-center justify-center h-[50vh] border-y">
          <p>No notifications to display</p>
        </div>
      ) : (
        <div className="md:h-[50vh] h-[72vh] border-y">
          <ScrollArea rootClassName={"h-full p-2 pr-4"}>
            {items?.map((notification) => (
              <Popover.CloseOnClickItem
                className="w-full flex text-start"
                key={notification.id}
              >
                <NotificationItem notification={notification} />
              </Popover.CloseOnClickItem>
            ))}
          </ScrollArea>
        </div>
      )}
      <Popover.CloseOnClickItem
        className="hover:bg-gray-100 w-full px-4 py-2 text-lg text-center"
        onClick={() => {
          navigate("/dashboard/notifications");
        }}
      >
        See all notifications
      </Popover.CloseOnClickItem>
    </Fragment>
  );
};

NotificationBody.propTypes = {};

export default NotificationBody;
