import { useNavigate, useLocation } from "react-router-dom";

import { validateResetPasswordData } from "../../validator";
import { changePassword, validateResetToken } from "../../services/auth";

import { Button, Input, InfoCard } from "../../components/ui";

import { LOGO } from "../../theme";

import { userStore } from "../../stores";
import SplashScreen from "../SplashScreen";
import useForm from "../../Hooks/useForm";
import useQuery from "../../Hooks/useQuery";

const ResetPassword = () => {
  document.title = "Reset Password";
  const navigate = useNavigate();
  const location = useLocation();
  const resetToken = location.pathname.split("/")[2];
  console.log("TOKEN", resetToken);
  const storeUser = userStore((state) => state.storeUser);

  const apiFn = async () => {
    return await changePassword({
      newPassword: formData?.newPassword,
      token: resetToken,
    });
  };

  const validateToken = () => {
    return validateResetToken({ token: resetToken });
  };

  const changePasswordSuccess = () => {
    storeUser(null);
    setTimeout(() => {
      navigate("/signin");
    }, 2000);
  };
  const { loading: validatingLoading, inf: validatingInfo } =
    useQuery(validateToken);

  const [
    formData,
    changePasswordLoading,
    changePasswordInfo,
    ,
    handleChange,
    handleSubmit,
  ] = useForm(apiFn, validateResetPasswordData, changePasswordSuccess);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full h-screen flex flex-col items-center justify-center bg-gray-100 p-2"
    >
      {!validatingLoading && validatingInfo?.type === "success" && (
        <div className="md:w-1/3 bg-white p-12">
          <div>
            <InfoCard
              type={changePasswordInfo?.type}
              message={changePasswordInfo?.message}
            />
          </div>
          <div>
            <img className="h-12 w-auto" src={LOGO} alt="Workflow" />
          </div>
          <div className="text-gray-600 text-2xl font-semibold tracking-wide mt-3">
            New Password
          </div>
          <div className="mt-3 text-gray-500">Enter your new password</div>
          <div>
            <div className="mt-3">
              <Input
                id={"reset-password"}
                type={"password"}
                name={"newPassword"}
                value={formData?.newPassword || ""}
                label=""
                placeholder={"Enter your new password"}
                autoComplete={""}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="mt-5">
              <Input
                id={"confirm-password"}
                type={"password"}
                name={"confirmPassword"}
                value={formData?.confirmPassword || ""}
                label=""
                placeholder={"Confirm password"}
                autoComplete={""}
                required={true}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-5">
            <Button
              text={"Change password"}
              loading={changePasswordLoading}
              buttonType={"submit"}
            />
          </div>
        </div>
      )}
      {validatingLoading && <SplashScreen />}
      {!validatingLoading && validatingInfo?.type !== "success" && (
        <div className="md:w-1/3 bg-white p-12">
          <div>
            <img className="h-12 w-auto" src={LOGO} alt="Workflow" />
          </div>
          <div className="text-red-500 font-semibold tracking-wide mt-3">
            {validatingInfo.message}
          </div>
          <div className="mt-5">
            <Button
              text={"Try again"}
              loading={changePasswordLoading}
              onClick={() => {
                navigate("/forgotPassword");
              }}
              buttonType={"button"}
            />
          </div>
        </div>
      )}
    </form>
  );
};

export default ResetPassword;
