import { useEffect, useState } from "react";

import Accordion, {
  AccordionItem,
  AccordionHeader,
  AccordionContent,
} from "../../../../../components/ui/Accordion";
import { Notification } from "../../../../../components/ui";
import Button from "src/components/ui/Button";
import { updateUser } from "../../../../../services/users";
import { getHierachicalPermissions } from "../../../../../services/company";
import ManageProfilesModal from "./ManageProfilesModal";
import { v4 as uuidv4 } from "uuid";
import Dropdown from "src/components/ui/Dropdown";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const CompanyPermissions = ({ user }) => {
  const [perms, setPerms] = useState(user?.permissions?.page || []);
  const [pagePermissions, setPagePermissions] = useState();
  const [updatedPermissions, setUpdatedPermissions] = useState(false);
  const [updatingCompanyPermissions, setUpdatingCompanyPermissions] =
    useState(false);
  const [info, setInfo] = useState({ title: "", type: "", message: "" });
  const [profiles, setProfiles] = useState(dummyProfiles);
  const [currentView, setCurrentView] = useState(
    profiles.find((op) => op.active)
  );
  const [showManageProfilesModal, setShowManageProfilesModal] = useState(false);

  useEffect(() => {
    getHierachicalPermissions().then((response) => {
      if (response?.status === 200) {
        setPagePermissions(response?.data?.permissions);
      }
    });
  }, []);

  const updateCompanyPermissionsFn = () => {
    setInfo({ title: "", type: "", message: "" });
    setUpdatingCompanyPermissions(true);
    updateUser(user?.id, {
      permissions: { page: perms },
    }).then((response) => {
      setUpdatingCompanyPermissions(false);
      if (response?.status === 200) {
        setInfo({
          title: "Successfully updated user permissions",
          message: response?.data?.message,
          type: "success",
        });
      } else {
        setInfo({
          title: "Error updating company permissions",
          message: response?.data?.message,
          type: "error",
        });
      }
    });
  };
  const handlePermission = (
    e,
    permission,
    parentPermission,
    parentsParentPermission
  ) => {
    if (e.target.checked) {
      if (parentPermission) {
        setPerms((prevState) => [
          ...prevState,
          ...(parentsParentPermission &&
          !prevState?.includes(parentsParentPermission)
            ? [parentsParentPermission]
            : []),
          ...(!prevState?.includes(parentPermission) ? [parentPermission] : []),
          ...(!prevState?.includes(permission) ? [permission] : []),
        ]);
      } else {
        setPerms((prevState) => [
          ...prevState,
          ...(!prevState?.includes(permission) ? [permission] : []),
        ]);
      }
    } else {
      setPerms((prevState) => {
        const oldPermissions = [...prevState];
        const newPerms = oldPermissions?.filter((perm) => {
          if (perm !== permission) {
            return perm;
          }
        });

        return newPerms;
      });
    }
    setUpdatedPermissions(true);
  };
  const countPermissions = (permission, notInitial) => {
    let allChildren = 0;
    let selectedChildren = 0;
    permission?.children?.forEach((child) => {
      const childCount = countPermissions(child, true);
      allChildren += childCount.totalPermissions;
      if (perms?.includes(child.entity)) {
        const childCount = countPermissions(child, true);
        selectedChildren += childCount?.selectedPermissions;
      }
    });
    if (!notInitial) {
      allChildren += 1;
    }
    if (!notInitial && perms?.includes(permission.entity)) {
      selectedChildren += 1;
    }
    return {
      selectedPermissions:
        (permission?.children?.filter((child) => perms?.includes(child.entity))
          ?.length || 0) + selectedChildren,
      totalPermissions: (permission?.children?.length || 0) + allChildren,
    };
  };
  const getPageBellows = (pagePermissions) => {
    return pagePermissions?.map((page, pageIdx) => {
      let p = {};
      p.title = (
        <div key={pageIdx} className="flex gap-x-2 items-center p-2">
          <div>
            {!user?.superAdmin && (
              <input
                type="checkbox"
                className="w-4 h-4 cursor-pointer"
                checked={perms?.includes(page.entity)}
                onChange={(e) => {
                  handlePermission(e, page.entity);
                }}
              />
            )}
          </div>
          <div className="font-semibold -mt-1">{page?.name}</div>
          <div className="text-xs">
            {countPermissions(page)?.selectedPermissions}/
            {countPermissions(page)?.totalPermissions}
          </div>
        </div>
      );

      p.body = (
        <div key={pageIdx} className="">
          {renderChildren(null, null, page)}
        </div>
      );
      return p;
    });
  };
  const renderChildren = (
    parentsParentParent,
    parentsParent,
    parent,
    showElement
  ) => {
    if (parent?.children) {
      return (
        <>
          <div className="m-2 flex flex-col p-2 border rounded-md">
            {showElement && (
              <div className="flex gap-x-2 items-center">
                <div>
                  {!user?.superAdmin && (
                    <input
                      type="checkbox"
                      className="w-4 h-4 cursor-pointer"
                      checked={perms?.includes(parent.entity)}
                      onChange={(e) => {
                        handlePermission(
                          e,
                          parent.entity,
                          parentsParent?.entity,
                          parentsParentParent?.entity
                        );
                      }}
                    />
                  )}
                </div>
                <div className="-mt-1">{parent?.name}</div>
              </div>
            )}
            {parent?.children?.map((child, id) => {
              return (
                <div key={id} className="">
                  {renderChildren(parentsParent, parent, child, true)}
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            // key={id}
            className="m-2 mb-0 flex gap-x-2 items-center p-2 rounded-md"
          >
            <div>
              {!user?.superAdmin && (
                <input
                  type="checkbox"
                  className="w-4 h-4 cursor-pointer"
                  checked={perms?.includes(parent.entity)}
                  onChange={(e) => {
                    handlePermission(
                      e,
                      parent.entity,
                      parentsParent?.entity,
                      parentsParentParent?.entity
                    );
                  }}
                />
              )}
            </div>
            <div className="-mt-1">{parent?.name}</div>
          </div>
        </>
      );
    }
  };
  const [pageBellows, setPageBellows] = useState(
    getPageBellows(pagePermissions)
  );

  useEffect(() => {
    const newBellows = getPageBellows(pagePermissions);
    setPageBellows(newBellows);
  }, [pagePermissions, perms]);

  return (
    <div className="pb-3">
      <Notification
        type={info.type}
        message={info.message}
        title={info.title}
      />
      <div className="flex items-center justify-end gap-x-2 mb-5">
        <h2>Permissions Profile:</h2>
        <ProfilesToggle
          options={profiles}
          currentView={currentView}
          setCurrentView={setCurrentView}
          onClickEditView={() => {
            setShowManageProfilesModal((prev) => !prev);
          }}
        />
      </div>
      <Accordion defaultValue="0">
        {pageBellows?.map((bellow, index) => (
          <AccordionItem value={String(index)}>
            <AccordionHeader className="border">{bellow.title}</AccordionHeader>
            <AccordionContent className="p-3 border border-t-0">
              {bellow.body}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      {updatedPermissions && (
        <div className="flex justify-end mt-5">
          <div>
            <Button
              onClick={updateCompanyPermissionsFn}
              loading={updatingCompanyPermissions}
            >
              Update
            </Button>
          </div>
        </div>
      )}
      <ManageProfilesModal
        profiles={profiles}
        setProfiles={setProfiles}
        show={showManageProfilesModal}
        toggle={() => setShowManageProfilesModal((prev) => !prev)}
      />
    </div>
  );
};

export default CompanyPermissions;

export const dummyProfiles = [
  {
    id: uuidv4(),
    title: "Profile 1",
    active: true,
    columns: ["details", "tasks", "age", "actions", "checkbox"],
    filters: [],
  },
  {
    id: uuidv4(),
    title: "Profile 2",
    active: false,
    columns: ["details", "tasks", "actions", "checkbox"],
  },
];

const ProfilesToggle = ({
  currentView,
  setCurrentView,
  options,
  onClickEditView,
}) => {
  const [active, setActive] = useState(currentView?.title || options[0].title);

  return (
    <Dropdown
      trigger={
        <Button
          variant="ghost"
          mode="outlined"
          className="flex items-center gap-x-2"
        >
          {active}
          <ChevronDownIcon
            className="h-4 w-4 text-gray-500"
            aria-hidden="true"
            color="black"
          />
        </Button>
      }
    >
      {options.map((option, index) => (
        <Dropdown.Item
          key={index}
          onClick={() => {
            setActive(option.title);
            // fow now
            setCurrentView && setCurrentView(option);
          }}
        >
          {option.title}
        </Dropdown.Item>
      ))}
      <Dropdown.Item
        className="bg-slate-50 justify-center hover:bg-slate-100"
        onClick={() => onClickEditView && onClickEditView()}
      >
        Edit Profiles
      </Dropdown.Item>
    </Dropdown>
  );
};
