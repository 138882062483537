// useGetCars.js

import { getCompanyCars } from "../../../../../../../../../../services/companies";
import useQuery from "../../../../../../../../../../Hooks/useQuery";

const useGetCars = () => {
  const filterObject = {};
  const resultsPerPage = 30;
  const cursor = null;

  const getCompanyCarsFn = () => {
    return getCompanyCars(4, {
      resultsPerPage: resultsPerPage,
      cursor: cursor,
      filterObject,
    });
  };

  const { data, reload, loading } = useQuery(getCompanyCarsFn);

  /*   useEffect(() => {
    reload();
  }, []); */

  const cars = data ? data.cars : null;
  const reloadCars = reload;
  const loadingCars = loading;

  return { cars, reloadCars, loadingCars };
};

export default useGetCars;
