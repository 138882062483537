import { create } from "zustand";

export const userStore = create((set) => ({
  user: null,
  company: null,
  storeUser: (user) => set({ user: user }),
  removeUser: () => set({ user: null }),
  storeCompany: (company) => set({ company: company }),
  removeCompany: () => set({ company: null }),
  loadingUser: false,
  setLoadingUser: (status) => set({ loadingUser: status }),
}));

export const companyStore = create((set) => ({
  company: null,
  companies: [],
  task: null,
  car: null,
  product: null,
  workflowRun: null,
  setCompany: (company) => set({ company: company }),
  setCompanies: (companies) => set({ companies: companies }),
  setTask: (task) => set({ task: task }),
  setCar: (car) => set({ car: car }),
  addCompany: (company) =>
    set((state) => ({ companies: [...state.companies, company] })),

  removeCompany: () => set({ company: null }),
  removeCompanies: () => set({ companies: null }),
  removeTask: () => set({ task: null }),
  removeCar: () => set({ car: null }),
  storeProduct: (product) => set({ product: product }),
  storeWorkflowRun: (workflowRun) => set({ workflowRun: workflowRun }),
}));

export const workflowStore = create((set) => ({
  service: null,
  product: null,
  productUpdated: null,
  basicDetails: {
    name: null,
    description: null,
    icon: null,
  },
  autosave: false,
  storeBasicDetails: (deets) => set({ basicDetails: deets }),
  removeBasicDetails: () => set({ basicDetails: null }),
  storeService: (service) => set({ service: service }),
  storeProduct: (product) => set({ product: product }),
  storeProductUpdated: (status) => set({ productUpdated: status }),
  storeAutosave: (status) => set({ autosave: status }),
}));

export const memberStore = create((set) => ({
  user: null,
  company: null,
  storeUser: (user) => set({ user: user }),
  removeUser: () => set({ user: null }),
}));

export const appStore = create((set) => ({
  showSearch: false,
  storeShowSearch: (status) => set({ showSearch: status }),
}));

export const customerStore = create((set) => ({
  company: null,
  setCompany: (company) => set({ company: company }),
}));

// this is for the main desktop sidebar
export const sidebar1Store = create((set) => ({
  sidebar1Open: false,
  setSidebar1Open: (open) => {
    // console.log(open)
    set({ sidebar1Open: open });
  },
}));

// this one is for mobile
export const secondaryNavStore = create((set) => ({
  navOpen: false,
  setNavOpen: (open) => set({ navOpen: open }),
}));
