import { useState, useEffect, Suspense, createElement } from "react";
import { useLocation } from "react-router-dom";
import PagePermissions from "./components/PagePermissions";
import TaskPermissions from "./components/TaskPermissions";
import ProductCategoryPermissions from "./components/ProductCategoryPermissions";
import CustomerPermissions from "./components/CustomerPermissions";
import Sections from "../../../../components/ui/Sections";

const Permissions = ({ user }) => {
  const location = useLocation();

  const [menus, setMenus] = useState([
    {
      name: "Page",
      value: "page",
      href: `menu=page`,
      current: true,
      component: PagePermissions,
      data: { user },
    },
    {
      name: "Task",
      value: "task",
      href: `menu=task`,
      current: false,
      component: TaskPermissions,
      data: { user },
    },
    {
      name: "Product Category",
      value: "product_category",
      href: `menu=product_category`,
      current: false,
      component: ProductCategoryPermissions,
      data: { user },
    },
    {
      name: "Customer",
      value: "customer",
      href: `menu=customer`,
      current: false,
      component: CustomerPermissions,
      data: { user },
    },
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentMenu = params.get("menu");
    if (currentMenu) {
      const newMenus = menus.map((menu) => {
        if (menu.value === currentMenu) {
          menu.current = true;
          return menu;
        } else {
          menu.current = false;
          return menu;
        }
      });
      setMenus(newMenus);
    }
    //eslint-disable-next-line
  }, [location]);

  return (
    <div className="max-w-5xl flex flex-col px-4 h-full">
      <Sections tabs={menus} />
      <div className="max-w-7xl">
        {menus
          .filter((menu) => menu.current)
          .map((menu, idx) => {
            return (
              <Suspense
                fallback={
                  <div
                    key={idx}
                    className="w-full flex justify-center items-center"
                  ></div>
                }
                key={idx}
              >
                {createElement(menu.component, menu.data)}
              </Suspense>
            );
          })}
      </div>
    </div>
  );
};

export default Permissions;
