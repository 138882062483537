import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../../../components/ui";
import ButtonGroupField from "../../../../components/fields/ButtonGroupField";
import Button from "../../../../components/ui/Button";
import DatePickerField from "../../../../components/fields/DatePickerField";
import TextAreaField from "../../../../components/fields/TextAreaField";
import moment from "moment";

const NewTimeOffRequestModal = ({ show, toggle }) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    register,
    setValue,
    unregister,
  } = useForm({
    defaultValues: {
      type: "fd", // or partial_day
      from: "",
      to: "",
      comments: "",
    },
  });

  useEffect(() => {
    if (watch("type") === "partial_day") {
      register("date", { required: true });
      setValue("date", "");
    } else {
      unregister("date");
    }
  }, [watch("type")]);

  const onClosed = () => {
    reset();
    toggle();
  };

  return (
    <Modal
      open={show}
      setOpen={onClosed}
      modalStyle={`md:max-w-md overflow-y-auto`}
      title="Time Off Request"
      placement="top"
      ui={
        <form onSubmit={handleSubmit(onClosed)} className="px-1 mt-9">
          <Controller
            control={control}
            name="type"
            rules={{
              required: "Required",
            }}
            render={(props) => (
              <ButtonGroupField
                {...props}
                options={[
                  {
                    name: "Full Day",
                    value: "fd",
                  },
                  {
                    name: "Partial Day",
                    value: "pd",
                  },
                ]}
              />
            )}
          />

          {watch("type") === "pd" && (
            <Controller
              control={control}
              name="date"
              rules={{
                required: "Required",
              }}
              render={(props) => (
                <DatePickerField
                  {...props}
                  showTimeSelect={false}
                  placeholder="Select Date.."
                  required
                  label="Date"
                />
              )}
            />
          )}
          {watch("type") === "fd" ? (
            <div className="flex justify-between item-center gap-x-2 mb-4">
              <Controller
                control={control}
                name="from"
                rules={{
                  required: "Required",
                }}
                render={(props) => (
                  <DatePickerField
                    {...props}
                    showTimeSelect={false}
                    placeholder="Select Date.."
                    required
                    label="From"
                  />
                )}
              />

              <Controller
                control={control}
                name="to"
                rules={{
                  required: "Required",
                }}
                render={(props) => (
                  <DatePickerField
                    {...props}
                    showTimeSelect={false}
                    placeholder="Select Date.."
                    required
                    label="To"
                  />
                )}
              />
            </div>
          ) : (
            <div className="flex justify-between item-center gap-x-2 mb-4">
              <Controller
                control={control}
                name="from"
                rules={{
                  required: "Required",
                }}
                render={(props) => (
                  <DatePickerField
                    {...props}
                    showTimeSelectOnly
                    placeholder="Select Date.."
                    required
                    dateFormat="h:mm aa"
                    label="From"
                  />
                )}
              />

              <Controller
                control={control}
                name="to"
                rules={{
                  required: "Required",
                  validate: (value) => {
                    if (value) {
                      return (
                        moment(watch("from")).isBefore(moment(value)) ||
                        "Select higher time"
                      );
                    }
                    return true;
                  },
                }}
                render={(props) => (
                  <DatePickerField
                    {...props}
                    showTimeSelectOnly
                    placeholder="Select Date.."
                    required
                    dateFormat="h:mm aa"
                    label="To"
                  />
                )}
              />
            </div>
          )}

          <Controller
            control={control}
            name="comments"
            rules={{
              required: "Required",
            }}
            render={(props) => (
              <TextAreaField
                {...props}
                label="Comments"
                className="p-0"
                rows={4}
                required
              />
            )}
          />
          <div className="flex justify-end mt-10 gap-x-2">
            <Button onClick={onClosed} variant="ghost">
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </form>
      }
    />
  );
};

NewTimeOffRequestModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func,
};

export default NewTimeOffRequestModal;
