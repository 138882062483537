import React from "react";
import useCanvas from "../../../Context/index";
import Button from "../../../fields/ControlButton";
import { updateSuperFeaturesGroup } from "../../../utils/helpers/updateFeaturesGroup";

interface Props {}

const FeaturesGroup: React.FC<Props> = ({}) => {
  const { canvasRef } = useCanvas();

  return (
    <Button
      className="text-xs w-full"
      onClick={() =>
        updateSuperFeaturesGroup(canvasRef?.current, false, {}, {})
      }
    >
      Features
    </Button>
  );
};

export default FeaturesGroup;
