import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/app/companies",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
  },
  withCredentials: true,
});

export const createCompany = async (typeName, companyData) => {
  return await api
    .post(`/type/${typeName}`, companyData)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const updateCompany = async (
  companyId,
  updatedDetais,
  backgroundJobSetup
) => {
  return await api
    .patch("/" + companyId, {
      ...updatedDetais,
      backgroundJobSetup: backgroundJobSetup,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompany = async (companyId) => {
  return await api
    .get("/" + companyId)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const syncFeedManually = async (companyId) => {
  return await api
    .get(`/${companyId}/syncFeeds`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const syncKnackManually = async (companyId) => {
  return await api
    .get(`/${companyId}/syncKnack`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompaniesByType = async (typeName) => {
  return await api
    .get(`/byType/${typeName}`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyStats = async (companyId) => {
  return await api
    .get(`/${companyId}/stats`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getMappingFields = async (companyId) => {
  return await api
    .get(`/${companyId}/mappingFields`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyFeeds = async (companyId) => {
  return await api
    .get(`/${companyId}/feeds`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getMappingFieldsRequest = async (companyId) => {
  return await api
    .post("/mappingFields", { companyId: companyId })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyCars = async (companyId, data) => {
  return await api
    .post(
      `/${companyId}/cars?cursor=${data.cursor}&results=${data.resultsPerPage}`,
      data
    )
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const searchForCarsRequest = async (companyId, searchInput) => {
  return await api
    .post(`/${companyId}/searchForCars`, searchInput)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyCarsCount = async (companyId, data) => {
  return await api
    .post(`/${companyId}/cars/count`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyProducts = async (companyId) => {
  return await api
    .get(`${companyId}/products`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const createTask = async (companyId, taskDetails) => {
  return await api
    .post(`/${companyId}/tasks`, taskDetails)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getAutorevoMappingFields = async () => {
  return await api
    .get("/autorevoMappingFields")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const addAutorevoMappingFields = async (companyId, autorevoDetails) => {
  return await api
    .post(`/${companyId}/addAutorevokMapping`, autorevoDetails)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const createCompanyProductWorkflowRun = async (
  companyId,
  productId,
  workflowDetails
) => {
  return await api
    .post(`${companyId}/products/${productId}/new`, workflowDetails)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const updateCompanyFeedMapping = async (companyId, updatedDetais) => {
  return await api
    .post(`${companyId}/addFeedMapping`, updatedDetais)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const updateCompanyKnackMapping = async (companyId, updatedDetais) => {
  return await api
    .post(`${companyId}/addKnackMapping`, updatedDetais)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const inviteUserToCompany = async (companyId, userDetails) => {
  return await api
    .post(`${companyId}/inviteUser`, userDetails)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyEmployees = async (companyId) => {
  return await api
    .get(`${companyId}/employees`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyUserInvitations = async (companyId) => {
  return await api
    .get(`${companyId}/invitations`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyTasks = async (companyId, data) => {
  return await api
    .get(
      `${companyId}/lateTasks?cursor=${data.cursor}&results=${data.resultsPerPage}`,
      data
    )
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyTasksWithCompany = async (
  companyId,
  assignedId,
  data
) => {
  return await api
    .get(
      `${companyId}/lateTasks/assigned/${assignedId}/?cursor=${data.cursor}&results=${data.resultsPerPage}`,
      data
    )
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyTasksCount = async (companyId, assignedId, data) => {
  return await api
    .get(`${companyId}/tasks/count/assigned/${assignedId}`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const createCompanyProduct = async (companyId, data) => {
  return await api
    .post(`/${companyId}/products/new`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyProductForApproval = async (companyId, productId) => {
  return await api
    .get(`/${companyId}/products/${productId}/approval`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const updateCompanyPermissions = async (companyId, permissions) => {
  return await api
    .patch(`/${companyId}/permissions`, permissions)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyFeedSources = async (companyId) => {
  return await api
    .get(`/${companyId}/feedSources`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyFeedSource = async (companyId, feedSourceId) => {
  return await api
    .get(`/${companyId}/feedSources/${feedSourceId}`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const createFeedSource = async (companyId, data) => {
  return await api
    .post(`/${companyId}/feedSources/new`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const updateFeedSources = async (companyId, feedSourceId, data) => {
  return await api
    .patch(`/${companyId}/feedSources/${feedSourceId}`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const deleteFeedSource = async (companyId, feedSourceId) => {
  return await api
    .delete(`/${companyId}/feedSources/${feedSourceId}`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
