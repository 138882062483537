import { ArrowUturnRightIcon } from "@heroicons/react/20/solid";
import TooltipWrapper from "../../fields/TooltipWrapper";
import useCanvas from "../../Context";
import Button from "../../fields/ControlButton";

const Redo = () => {
  const { redo } = useCanvas();
  return (
    <TooltipWrapper title="Redo">
      <Button onClick={() => redo()} className="py-1">
        <ArrowUturnRightIcon className="w-4 h-4" />
      </Button>
    </TooltipWrapper>
  );
};

export default Redo;
