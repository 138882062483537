import { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";

const Coordinates = () => {
  const [xCoord, setXCoord] = useState("");
  const [yCoord, setYCoord] = useState("");
  const { canvasRef, isCanvasReady } = useCanvas();

  // get coordintes of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleObjectMoving = (e) => {
      const obj = e.target;
      setXCoord(obj.left.toFixed(1));
      setYCoord(obj.top.toFixed(1));
    };

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        setXCoord(obj.left.toFixed(1));
        setYCoord(obj.top.toFixed(1));
      }
    };

    // built in canvas events
    currentCanvas?.on("object:moving", handleObjectMoving);
    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);
    // custom event listener for moving object with arrows - set up in context
    currentCanvas?.on("object:manualMove", handleObjectMoving);

    // Clean up the event listener when the component is unmounted
    return () => {
      //   console.log("Detaching event listener.");
      currentCanvas?.off("object:moving", handleObjectMoving);
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
      currentCanvas?.off("object:manualMove", handleObjectMoving);
    };
  }, [canvasRef.current, setXCoord, setYCoord, isCanvasReady]);

  const handleCoordChange = (newX, newY) => {
    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects) {
      activeObjects.forEach((obj) => {
        if (newX !== null) {
          obj.set({ left: parseFloat(newX) });
        }
        if (newY !== null) {
          obj.set({ top: parseFloat(newY) });
        }
        obj.setCoords();
      });
      canvasRef.current?.renderAll();
    }
  };

  return (
    <section className="flex w-full">
      <div className="w-1/2">
        <Label>X</Label>
        <Input
          value={xCoord}
          onChange={(e) => {
            // Just update the state without changing the coordinates
            setXCoord(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Update the coordinates when Enter key is pressed
              handleCoordChange(xCoord, null);
            }
          }}
          className="w-[55%] py-1"
        />
      </div>
      <div className="w-1/2">
        <Label>Y</Label>
        <Input
          value={yCoord}
          onChange={(e) => {
            // Just update the state without changing the coordinates
            setYCoord(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Update the coordinates when Enter key is pressed
              handleCoordChange(yCoord, null);
            }
          }}
          className="w-[55%] py-1"
        />
      </div>
    </section>
  );
};

export default Coordinates;
