import { Fragment } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Popover from "../../../../../../../../../../components/ui/Popover";
import TooltipWrapper from "../../fields/TooltipWrapper";

const Shorcuts = () => {
  function isMac() {
    return navigator.userAgent.includes("Mac");
  }

  const platformSpecificKey = isMac() ? "CMD" : "CTRL";

  const shortcuts = [
    { key: "Arrow Keys", action: "Move Item(s) Around", platformKey: null },
    { key: "Delete", action: "Delete Selected Item(s)", platformKey: null },
    { key: `${platformSpecificKey} + C`, action: "Copy Selected Item(s)" },
    { key: `${platformSpecificKey} + V`, action: "Paste Copied Item(s)" },
    { key: `${platformSpecificKey} + Z`, action: "Undo" },
    { key: `${platformSpecificKey} + Y`, action: "Redo" },
  ];

  return (
    <TooltipWrapper title="Keyboard Shortcuts">
      <Popover
        contentClassName="p-0 m-2 mr-5 min-w-[310px]"
        triggerClassName=""
        trigger={
          <button>
            <QuestionMarkCircleIcon className="w-6 h-6 bg-white rounded-full" />
          </button>
        }
        content={
          <div className="z-10 mt-2 pt-2 pb-5 px-5 rounded-md bg-white shadow-lg ring-1 ring-gray-700 ring-opacity-20 focus:outline-none">
            <div className="text-center text-slate-700 pb-2">Shortcuts</div>
            <section className="grid grid-cols-3 gap-x-4 gap-y-1 items-center text-sm text-slate-700">
              {shortcuts.map((s, index) => (
                <Fragment key={index}>
                  <div className="text-end text-slate-800 font-semibold col-span-1">
                    <p>{s.key}</p>
                  </div>
                  <div className="text-start col-span-2">
                    <p>{s.action}</p>
                  </div>
                </Fragment>
              ))}
            </section>
          </div>
        }
      />
    </TooltipWrapper>
  );
};

export default Shorcuts;
