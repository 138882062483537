import React from "react";
import { Modal } from "../../../../../../components/ui";
import { Input } from "../../../../../../components/ui";
import Button from "../../../../../../components/ui/Button";

const EditDashboardNameModal = ({ open, closeModal, layout }) => {
  return (
    <Modal
      title=""
      open={open}
      setOpen={closeModal}
      ui={
        <>
          <div className=" text-center sm:ml-4 sm:mt-0 sm:text-left pb-5">
            <Input
              label={"Edit This Dashboard's Name"}
              placeholder={layout.name}
              labelClassname="text-gray-400"
              className="w-[300px]"
            ></Input>
          </div>

          <div className="mt-5 sm:mt-4 flex gap-3 justify-end">
            <Button size="sm" variant="ghost" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button size="sm" variant="" onClick={() => closeModal()}>
              Edit
            </Button>
          </div>
        </>
      }
      modalStyle={`md:max-w-md overflow-y-auto`}
      placement="top"
    />
  );
};

export default EditDashboardNameModal;
