import { twMerge } from "tailwind-merge";

// Ideally for fields that are loading only.
const Loading = ({ className }) => {
  return (
    <div
      className={twMerge("flex justify-center gap-x-1 items-center", className)}
    >
      <div
        className="w-1 h-1 bg-gray-500 rounded-full animate-pulse"
        style={{ animationDuration: "0.5s" }} // Adjust the animation duration here
      />
      <div
        className="w-1 h-1 bg-gray-500 rounded-full animate-pulse"
        style={{ animationDuration: "0.8s" }} // Adjust the animation duration here
      />
      <div
        className="w-1 h-1 bg-gray-500 rounded-full animate-pulse"
        style={{ animationDuration: "0.5s" }} // Adjust the animation duration here
      />
    </div>
  );
};

export default Loading;
