import React from "react";
import { BellIcon } from "@heroicons/react/24/outline";

import { useQuery } from "react-query";
import { getNotificationsCount } from "../../api";

import Popover from "../../../../components/ui/Popover";

import NotificationBody from "./NotificationBody";

const NotificationFlyout = () => {
  const { data: count } = useQuery(
    ["notificationsCount"],
    () => getNotificationsCount(),
    {
      refetchInterval: 20000,
    }
  );

  return (
    <Popover
      contentClassName="md:max-w-[500px] w-screen md:w-auto  mr-16 p-0 border"
      trigger={
        <div className="flex relative mt-2 mx-4 hover:bg-gray-200 rounded-full p-2 transition ease-in-out">
          <BellIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
          {count?.notSeen > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 rounded-full px-1.5 text-white text-xs">
              {count?.notSeen}
            </span>
          )}
        </div>
      }
      content={<NotificationBody />}
    />
  );
};

export default NotificationFlyout;
