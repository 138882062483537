import { useEffect, useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Button, Toggle } from "../../../../../components/ui";
import { PlusIcon } from "@heroicons/react/24/outline";

import { taskPermissions } from "./dummy";

const animatedComponents = makeAnimated();

const TaskPermissions = () => {
  const [permissions, setPermissions] = useState(taskPermissions);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const alreadySelected = permissions?.filter(
      (permission) => permission.checked
    );
    setSelectedPermissions(alreadySelected);
  }, [permissions]);

  const changePermissions = (permissions) => {
    setSelectedPermissions(permissions);
  };

  const addAll = () => {
    setSelectedPermissions(permissions);
  };

  const removeAll = () => {
    setSelectedPermissions([]);
  };

  return (
    <div className="py-5">
      <div className="flex gap-x-5">
        <Toggle
          title=""
          enabled={enabled}
          setEnabled={() => {
            setEnabled((prevState) => !prevState);
          }}
        />
        <div className="font-semibold text-lg">
          User can perform all Task Types
        </div>
      </div>
      {!enabled && (
        <div className="flex flex-col py-4 gap-y-3">
          <div className="flex justify-between items-center">
            <div>Authorized Task Types</div>
            <div className="flex gap-x-2">
              <Button
                text="Add All"
                onClick={addAll}
                type="primary"
                loading={false}
                active={true}
                buttonType={"button"}
                className=""
                size=""
                leftIcon={<PlusIcon className="w-5 h-5" />}
                rightIcon={null}
              />
              <Button
                text="Remove All"
                onClick={removeAll}
                type="secondary"
                loading={false}
                active={true}
                buttonType={"button"}
                className=""
                size=""
                leftIcon={<PlusIcon className="w-5 h-5" />}
                rightIcon={null}
              />
            </div>
          </div>
          <div className="border rounded-md p-4 flex gap-x-2 gap-y-2 flex-wrap">
            <div className="w-full">
              <Select
                closeMenuOnSelect={false}
                placeholder="Select Permissions"
                components={animatedComponents}
                value={selectedPermissions}
                isMulti
                options={permissions}
                onChange={changePermissions}
                className="w-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskPermissions;
