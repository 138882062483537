import { twMerge } from "tailwind-merge";

interface ToggleProps {
  title: string;
  enabled: boolean;
  setEnabled: (value: boolean) => void;
  className?: string;
  labelClassName?: string;
}

const Toggle = ({
  title,
  enabled,
  setEnabled,
  className,
  labelClassName,
}: ToggleProps) => {
  const toggle = () => {
    setEnabled(!enabled);
  };

  return (
    <div className={twMerge("flex items-center space-x-2 mt-4", className)}>
      <div
        onClick={toggle}
        style={{
          width: "30px",
          height: "15px",
          borderRadius: "15px",
          backgroundColor: enabled ? "#79a677" : "#D3D3D3",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: "15px",
            height: "15px",
            border: "1px solid #D3D3D3",
            borderRadius: "50%",
            backgroundColor: "white",
            position: "absolute",
            top: "0",
            left: enabled ? "15px" : "0",
            transition: "left 0.2s",
          }}
        ></div>
      </div>
      <label
        className={twMerge("text-xs text-gray-600 font-medium", labelClassName)}
      >
        {title}
      </label>
    </div>
  );
};

export default Toggle;
