import React from "react";
import Tooltip from "../../../../../../../../../components/ui/Tooltip";
import { v4 as uuidv4 } from "uuid";

const TooltipWrapper = ({ children, title = "", ...rest }) => {
  return (
    <Tooltip
      {...rest}
      content={title}
      type="light"
      border={true}
      borderColor="gray"
      id={uuidv4()}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipWrapper;
