import React, { useState, useEffect } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import Input from "../../../../fields/ControlInput";
import { fabric } from "fabric";
import { updateSuperFeaturesGroup } from "../../../../utils/helpers/updateFeaturesGroup";

// a FeaturesGroup is a group with groups nested in it.
// each group nested in it consists of a Text obj for the bullet points,
// and a Textbox obj for the features text
const FeaturesGroupWidth = () => {
  const { canvasRef, isCanvasReady, lastSelectedObject } = useCanvas();
  const [width, setWidth] = useState<string>("");

  //   console.log(lastSelectedObject.featuresGroup);

  useEffect(() => {
    setWidth(lastSelectedObject?.width);
  }, [lastSelectedObject]);

  const handleWidthChange = (newWidth) => {
    const canvas = canvasRef.current;
    const activeObject = canvas?.getActiveObject() as fabric.Group;

    if (activeObject && activeObject.name === "superFeaturesGroup") {
      updateSuperFeaturesGroup(canvasRef.current, true, activeObject, {
        targetSuperGroupWidth: newWidth,
      });
    }
  };

  return (
    <section>
      <Label>Width</Label>
      <Input
        value={width}
        onChange={(e) => {
          // Just update the state without changing the dimensions
          const newWidth = e.target.value;
          setWidth(newWidth);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // Update the dimensions when Enter key is pressed
            handleWidthChange(width);
          }
        }}
        min={1}
        className="w-full"
        type="number"
      />
    </section>
  );
};

export default FeaturesGroupWidth;
