import React from "react";
import PropTypes from "prop-types";
import Popover from "./Popover";
import { twMerge } from "tailwind-merge";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Dropdown = ({ trigger, children, className, contentClassName }) => {
  return (
    <Popover
      trigger={trigger}
      contentClassName={twMerge("p-0 mx-8", contentClassName)}
      content={
        <div
          className={twMerge(
            "flex flex-col gap-2 p-1 items-start justify-start text-start ",
            className
          )}
        >
          {children}
        </div>
      }
    />
  );
};

Dropdown.Item = ({
  children,
  onClick,
  variant = "ghost",
  className,
  disabled,
  ...rest
}) => {
  return (
    <Popover.CloseOnClickItem
      className={twMerge(
        "px-3 py-2 text-sm hover:bg-slate-200 text-gray-500 w-full text-start",
        disabled ? "opacity-50 cursor-not-allowed" : "",
        className
      )}
      onClick={!disabled && onClick}
      variant={variant}
      {...rest}
    >
      {children}
    </Popover.CloseOnClickItem>
  );
};

Dropdown.Sub = ({ trigger, children, className }) => {
  return (
    <Popover
      trigger={
        <div className="flex items-center justify-between">
          {trigger}
          <ChevronRightIcon className="w-3 h-3" />
        </div>
      }
      triggerClassName="px-3 py-2 text-sm hover:bg-slate-200 text-gray-500 w-full text-start"
      contentClassName="p-0"
      content={
        <div
          className={twMerge(
            "flex flex-col gap-2 p-1 items-start justify-start text-start ",
            className
          )}
        >
          {children}
        </div>
      }
    />
  );
};

Dropdown.Item.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
    "ghost",
    "link",
  ]),
};

Dropdown.Sub.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Dropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

export default Dropdown;
