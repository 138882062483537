import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface ButtonProps {
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = ({ className, onClick, children }) => {
  const defaultClasses =
    "border rounded-sm py-1 px-2 border-slate-300 text-slate-900 bg-white text-xs shadow-md";
  const mergedClasses = twMerge(defaultClasses, className);

  return (
    <button className={mergedClasses} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
