import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import * as RadixScrollArea from "@radix-ui/react-scroll-area";

const ScrollArea = ({
  children,
  rootClassName,
  scrollbarClassName,
  scrollAreaOrientation = "vertical",
  scrollBarOrientation = "horizontal",
  thumbClassName,
  viewportClassName,
}) => {
  return (
    <RadixScrollArea.Root
      className={twMerge(
        // please the specificy height via rootClassName
        // by default its 200px because
        // but you can change it to whatever you want
        "h-[200px] overflow-hidden bg-white",
        rootClassName
      )}
    >
      <RadixScrollArea.Viewport
        className={twMerge("w-full h-full", viewportClassName)}
      >
        {children}
      </RadixScrollArea.Viewport>
      <RadixScrollArea.Scrollbar
        className={twMerge(
          "flex  select-none touch-none p-0.5 bg-gray-100 transition-colors duration-[150ms] ease-out hover:bg-gray-200 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5",
          scrollbarClassName
        )}
        orientation={scrollAreaOrientation}
      >
        <RadixScrollArea.Thumb
          className={twMerge(
            "flex-1 bg-gray-500 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]",
            thumbClassName
          )}
        />
      </RadixScrollArea.Scrollbar>
      <RadixScrollArea.Scrollbar
        className={twMerge(
          "flex select-none touch-none p-0.5 bg-gray-100 transition-colors duration-[150ms] ease-out hover:bg-gray-200 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5",
          scrollbarClassName
        )}
        orientation={scrollBarOrientation}
      >
        <RadixScrollArea.Thumb
          className={twMerge(
            "flex-1 bg-gray-500 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]",
            thumbClassName
          )}
        />
      </RadixScrollArea.Scrollbar>
      <RadixScrollArea.Corner className="bg-gray-100" />
    </RadixScrollArea.Root>
  );
};

ScrollArea.propTypes = {
  children: PropTypes.node,
  rootClassName: PropTypes.string,
  scrollbarClassName: PropTypes.string,
  scrollAreaOrientation: PropTypes.oneOf(["vertical", "horizontal"]),
  scrollBarOrientation: PropTypes.oneOf(["vertical", "horizontal"]),
  thumbClassName: PropTypes.string,
  viewportClassName: PropTypes.string,
};

ScrollArea.defaultProps = {
  scrollAreaOrientation: "vertical",
  scrollBarOrientation: "horizontal",
};

export default ScrollArea;
