/* 
Works well for undo/ redo:
√ adding textbox
√ adding any object

Undo works from command + Z but not from button?

Working on:
- moving any objects
- scaling any objects

- text and object adjustment actions - bg color, border font size etc 
*/

const stateOperations = (canvasRef, setNotification, setLoading) => {
  let historyUndo = [];
  let historyRedo = []; // New stack for redo operations
  let historyProcessing = false;
  let historyNextState = null;
  let saveTimeout;

  // --------------------  Initialize history management -----------------------------
  // Initializes the history management for the canvas.
  // Adds various event listeners to the canvas to trigger saving of history.
  const historyInit = () => {
    // console.log("Initializing history...");
    historyNextState = historyNext();
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.on("object:added", historySaveAction.bind(canvas));
      canvas.on("object:removed", historySaveAction.bind(canvas));
      canvas.on("object:modified", historySaveAction.bind(canvas));
      canvas.on("object:modified", (e) => {
        if (e.target && e.target.type === "text") {
          console.log("A text object was modified.");
        }
        historySaveAction.bind(canvas)();
      });

      canvas.on("object:scaling", historySaveAction.bind(canvas));
      canvas.on("object:scaling", historySaveAction.bind(canvas));
      canvas.on("text:changed", () => {
        console.log("t");
        historySaveAction.bind(canvas);
      });
      canvas.on("object:manualMove", historySaveAction.bind(canvas)); //  objects moved with arrow keys - "manualMove" is defined in the move functions
      /* 
      canvas.on("object:modified", (opt) => {
        const action = opt?.action;

        if (action === 'drag')
        console.log("Action:", action);
      }); */

      // -----------------OBJECTS MOVED From DRAGGING --------------------------------
      // Both SCALING and DRAGGING have the SAME PROBLEM
      // problem: there is no "object:moved" or "object:scaled" event from fabricJS
      // current manual solution - use'isMoving' and 'object:modified' to listen for the object to stop moving/ scaling
      // seems like there are still some extra saves happening mid object drag or scale, not sure why

      let isMoving = false;

      canvas.on("object:moving", () => {
        isMoving = true;
        clearTimeout(saveTimeout); // Clear any existing timeout
      });

      canvas.on("object:modified", () => {
        if (isMoving) {
          clearTimeout(saveTimeout); // Clear any existing timeout
          saveTimeout = setTimeout(() => {
            // Set a new timeout
            historySaveAction();
            isMoving = false;
          }, 1000); // 100 ms delay
        }
      });

      // -----------------OBJECTS SCALED -----------------------------
      let isScaling = false;

      canvas.on("object:scaling", () => {
        isScaling = true;
        clearTimeout(saveTimeout); // Clear any existing timeout
      });

      canvas.on("object:modified", () => {
        if (isScaling) {
          clearTimeout(saveTimeout); // Clear any existing timeout
          saveTimeout = setTimeout(() => {
            // Set a new timeout
            historySaveAction();
            isScaling = false;
          }, 1000); // 100 ms delay
        }
      });

      // ----------------- END OBJECTS SCALED -----------------------------
    } else {
      console.log("Canvas ref not initialized");
    }
  };

  // -------------------- Fetch next state -----------------------------
  // Fetches and returns the next state of the canvas.
  const historyNext = () => {
    if (!canvasRef.current) return null;
    // console.log("Fetching next state...");
    return JSON.stringify(canvasRef.current.toDatalessJSON());
  };

  // Saves the next canvas state into the undo stack.
  // Clears the redo stack as a new action has been made.
  const historySaveAction = () => {
    if (historyProcessing) return;
    if (!historyNextState) return;
    // console.log("Before Save: Undo Stack: ", JSON.stringify(historyUndo));
    historyUndo.push(historyNextState);
    historyNextState = historyNext();
    historyRedo = []; // Clear the redo stack whenever we save a new action
    // console.log("After Save: Undo Stack: ", JSON.stringify(historyUndo));
    /*     setNotification("history save action");
    setTimeout(() => {
      setNotification("");
    }, 1000); */
  };

  const undo = () => {
    // console.log("here");
    if (!canvasRef?.current) return;
    // console.log(canvasRef.current);
    // console.log("Before Undo: Undo Stack: ", JSON.stringify(historyUndo));
    // console.log("Before Undo: Redo Stack: ", JSON.stringify(historyRedo));

    historyProcessing = true;
    // setLoading(true);

    // when the undo function gets to here, called from the undo button, it doesn't work because there is no history???
    // it does work from the shortcut because the shortcut does have history
    const history = historyUndo.pop();
    // console.log("here");
    if (history) {
      //   console.log("here");
      historyRedo.push(historyNext()); // Save current state to redo stack before undoing
      canvasRef.current.loadFromJSON(history, () => {
        canvasRef.current.renderAll();
      });
    }
    historyProcessing = false;
    // setLoading(false);

    // console.log("After Undo: Undo Stack: ", JSON.stringify(historyUndo));
    // console.log("After Undo: Redo Stack: ", JSON.stringify(historyRedo));
  };

  // -------------------- redo -----------------------------
  const redo = () => {
    if (!canvasRef.current || historyRedo.length === 0) return;
    // console.log("redo...");
    historyProcessing = true;
    setLoading(true);

    const history = historyRedo.pop();
    if (history) {
      historyUndo.push(historyNext()); // Save current state to undo stack before redoing
      canvasRef.current.loadFromJSON(history, () => {
        canvasRef.current.renderAll();
      });
    }

    historyProcessing = false;
    setLoading(false);
  };

  return { undo, redo, historyInit };
};

export default stateOperations;
