import React, { useEffect, useState } from "react";
import useCanvas from "../Context/index";
import EditControls from "./Edit";
import AddControls from "./Add";
import { twMerge } from "tailwind-merge";

interface Props {
  autoMenuSwitching: boolean;
}

const Sidebar: React.FC<Props> = ({ autoMenuSwitching }) => {
  const {
    canvasRef,
    isCanvasReady,
    setTabChanged,
    lastSelectedObject,
    setLastSelectedObject,
  } = useCanvas();
  const [activeTab, setActiveTab] = useState("add");

  useEffect(() => {
    const canvas = canvasRef?.current;

    const onObjectSelected = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        setLastSelectedObject(obj);
        console.log(obj.name);
        setActiveTab("edit");
      }
    };

    const onSelectionCleared = () => {
      //   console.log("Selection cleared");
      setActiveTab("add");
    };

    if (autoMenuSwitching && canvas) {
      //   console.log("Event listeners added");
      canvas.on("selection:created", onObjectSelected);
      canvas.on("selection:updated", onObjectSelected);
      canvas.on("selection:cleared", onSelectionCleared);
    }

    return () => {
      if (canvas) {
        // console.log("Event listeners removed");
        canvas.off("selection:created", onObjectSelected);
        canvas.off("selection:updated", onObjectSelected);
        canvas.off("selection:cleared", onSelectionCleared);
      }
    };
  }, [canvasRef.current, autoMenuSwitching, isCanvasReady]);

  const handleEditTabClick = () => {
    setActiveTab("edit");
    if (lastSelectedObject && canvasRef?.current) {
      const allObjects = canvasRef.current.getObjects();
      if (allObjects.includes(lastSelectedObject)) {
        canvasRef.current.setActiveObject(lastSelectedObject);
        canvasRef.current.renderAll();
      }
    }
    setTabChanged((prev) => (prev += 1));
  };

  const handleAddTabClick = () => {
    if (canvasRef?.current) {
      const activeObject = canvasRef.current.getActiveObject();
      if (activeObject) {
        setLastSelectedObject(activeObject);
      }
      canvasRef.current.discardActiveObject();
      canvasRef.current.renderAll();
      setActiveTab("add");
    }
    setTabChanged((prev) => (prev += 1));
  };

  const tabClassName =
    "w-full text-gray-500 border-l border-r border-t border-gray-300 rounded-t-lg bg-gray-50";
  const tabActiveClassName = "font-bold with-overlay";
  const tabNotActiveClassName = "font-normal border-b";

  const sidebarUIclassName = `px-6 pt-5 flex flex-col overflow-y-auto h-[85vh] pb-[100px] bg-gray-100 border-l border-r border-b rounded-tr-md rounded-b-md border-gray-200`;

  return (
    <section className="">
      <div className={`flex text-sm w-1/2`}>
        <button
          className={`${tabClassName} ${
            activeTab === "add" ? tabActiveClassName : tabNotActiveClassName
          }`}
          onClick={handleAddTabClick}
        >
          <div className="w-full h-full flex items-center justify-center px-1 py-[1px] rounded-t-lg">
            Add
          </div>
        </button>
        <button
          className={`${tabClassName} ${
            activeTab === "edit" ? tabActiveClassName : tabNotActiveClassName
          }`}
          onClick={handleEditTabClick}
        >
          <div className="w-full h-full flex items-center justify-center px-1 py-[1px] rounded-t-lg">
            Edit
          </div>
        </button>
      </div>
      <div>
        <div
          className={twMerge(
            sidebarUIclassName,
            "gap-8 ",
            activeTab === "add" ? "" : "hidden"
          )}
        >
          <AddControls />
        </div>
        <div
          className={twMerge(
            sidebarUIclassName,
            "gap-5",
            activeTab === "edit" ? "" : "hidden"
          )}
          id="unique-scrollbar"
        >
          <EditControls />
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
