export const customFields = {
  OBFUSCATE: () => {
    return "*********";
  },
  PHOTOS: (photos: string) => {
    let processedPhotos;
    if (photos[0] === "[") {
      processedPhotos = JSON.parse(photos);
    } else {
      processedPhotos = [photos];
    }
    let photosElment = `<div className="flex gap-x-2 flex-wrap">`;
    processedPhotos.map((photo: String) => {
      photosElment += `<div> <img src="${photo}" class="w-10 h-10"/></div>`;
    });
    photosElment += "</div>";
    return photosElment;
  },
  COLOR: (color: string) => {
    return `<div class="w-6 h-6" style='background-color: ${color}'/>`;
  },
  PERMISSIONS: (permission: object | null) => {
    if (!permission) {
      return "";
    }
    let permissions = `<div class="flex flex-wrap">`;
    for (const key in permission) {
      permissions += `<div class="break-all"> <span class="capitalize">${key}</span> permissions => ${permission[
        key
      ].toString()} </div>`;
    }
    permissions += `</div>`;
    return permissions;
  },
};
