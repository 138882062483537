import { fabric } from "fabric";
import { LoremIpsum } from "lorem-ipsum";

const updateSuperFeaturesGroup = (
  canvas,
  updatingExistingGroup,
  superFeaturesGroup, // if updating the group, then pass in the old one
  updatedProperties
) => {
  const defaultTextOptions = {
    fontSize: 12,
    fontWeight: 500,
    fill: "#000000",
  };
  // Combine the defaults, superFeaturesGroup properties, and updatedProperties
  const {
    // these options are placed here to allow them to be changed by controls via updatedProperties prop - the ones below this don't need to be updated by controls
    numColumns = updatingExistingGroup ? superFeaturesGroup.numColumns : 3,
    columnSpacing = updatingExistingGroup
      ? superFeaturesGroup.columnSpacing
      : 25,
    targetSuperGroupWidth = updatingExistingGroup
      ? superFeaturesGroup.targetWidth
      : 600,
    verticalSpacing = updatingExistingGroup
      ? superFeaturesGroup.verticalSpacing
      : 3,
    totalNumFeatures = updatingExistingGroup
      ? superFeaturesGroup.totalNumFeatures
      : 25,
    bulletTextSpacing = updatingExistingGroup
      ? superFeaturesGroup.bulletTextSpacing
      : 15,
    bulletLeftMargin = updatingExistingGroup
      ? superFeaturesGroup.bulletLeftMargin
      : 10,
    textOptions = updatingExistingGroup
      ? superFeaturesGroup.textOptions
      : defaultTextOptions,
    realFeaturesArray = updatingExistingGroup
      ? superFeaturesGroup.realFeaturesArray
      : [],
  } = updatingExistingGroup
    ? { ...superFeaturesGroup, ...updatedProperties }
    : updatedProperties;

  let loremArray = getLoremArray(
    updatingExistingGroup,
    superFeaturesGroup,
    totalNumFeatures
  );

  let featuresArray =
    realFeaturesArray.length > 0 ? [...realFeaturesArray] : [...loremArray];

  const featuresGroupWidth =
    (targetSuperGroupWidth - columnSpacing * (numColumns - 1)) / numColumns;

  // if we are updating the features group, then remove the old one
  if (updatingExistingGroup) {
    canvas.remove(superFeaturesGroup);
  }

  const superGroupList = [];
  // spacing for next group from left of supergroup
  let accumulatedLeft = 0;
  let baseNumTextboxes = Math.floor(totalNumFeatures / numColumns);
  let remainder = totalNumFeatures % numColumns;

  for (let colIndex = 0; colIndex < numColumns; colIndex++) {
    let numTextboxes = baseNumTextboxes;
    if (remainder > 0) {
      numTextboxes += 1;
      remainder--;
    }

    let topPosition = 0;
    const groupList = [];

    for (let i = 0; i < numTextboxes; i++) {
      const textValue = featuresArray?.shift();
      // const textValue = "-";
      const bullet = new fabric.Text("•", {
        left: bulletLeftMargin,
        top: topPosition,
        fontSize: 12,
        textAlign: "left",
      });

      const textboxWidth =
        featuresGroupWidth - bullet.width - bulletTextSpacing;

      const textObj = new fabric.Textbox(textValue, {
        left: bullet.width + bulletTextSpacing, // position it next to the bullet
        top: topPosition,
        width: textboxWidth,
        fontSize: textOptions.fontSize,
        fontWeight: textOptions.fontWeight,
        lineHeight: 1.1,
        textAlign: "left",
        fill: textOptions.fill,
        charSpacing: 0,
      });

      const feature = new fabric.Group([bullet, textObj], {
        name: "feature",
        lockScalingFlip: true,
        left: 0, // Start at 0 because positioning is relative to featuresColumn
        top: topPosition,
      });

      groupList.push(feature);

      topPosition =
        topPosition + textObj.getScaledHeight() + Number(verticalSpacing);
    }

    const featuresColumn = new fabric.Group(groupList, {
      name: "featuresColumn",
      left: accumulatedLeft,
      top: 0,
    });

    superGroupList.push(featuresColumn);

    accumulatedLeft += featuresGroupWidth + columnSpacing;
  }

  const updatedSuperFeaturesGroup = new fabric.Group(superGroupList, {
    name: "superFeaturesGroup",
    lockScalingFlip: true,
    left: updatingExistingGroup ? superFeaturesGroup.left : 100,
    top: updatingExistingGroup ? superFeaturesGroup.top : 100,
  });

  updatedSuperFeaturesGroup.set({
    targetSuperGroupWidth,
    numColumns,
    columnSpacing,
    verticalSpacing,
    totalNumFeatures,
    bulletTextSpacing,
    bulletLeftMargin,
    textOptions,
    loremArray,
    realFeaturesArray,
  });
  updatedSuperFeaturesGroup.setControlsVisibility({
    mt: false,
    mb: false,
    ml: false,
    mr: false,
    bl: false,
    br: false,
    tl: false,
    tr: false,
    mtr: true,
  });

  canvas.add(updatedSuperFeaturesGroup);
  canvas.setActiveObject(updatedSuperFeaturesGroup);
  canvas.requestRenderAll();
};

export { updateSuperFeaturesGroup };

const getLoremArray = (
  updatingExistingGroup,
  superFeaturesGroup,
  totalNumFeatures
) => {
  // Initialize the loremArray with the existing sentences or an empty array
  let loremArray = updatingExistingGroup
    ? [...superFeaturesGroup.loremArray]
    : [];

  // Only generate new sentences if the total number of features has increased
  const currentNumFeatures = loremArray.length;
  const amountToIncrease = totalNumFeatures - currentNumFeatures;

  if (amountToIncrease > 0) {
    const lorem = new LoremIpsum({
      wordsPerSentence: {
        max: 8,
        min: 2,
      },
    });
    // Generate and append the new sentences
    for (let i = 0; i < amountToIncrease; i++) {
      loremArray.push(lorem.generateSentences(1));
    }
  } else if (amountToIncrease < 0) {
    // If the number has decreased, remove the excess sentences
    loremArray.length = totalNumFeatures;
  }
  return loremArray;
};
