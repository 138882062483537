import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  containerClassName?: string;
  labelClassName?: string;
  children: ReactNode;
}

const Label: React.FC<Props> = ({
  children,
  labelClassName,
  containerClassName,
}) => {
  return (
    <div className={containerClassName}>
      <h4
        className={twMerge(
          "text-gray-600 text-xs font-medium pb-1",
          labelClassName
        )}
      >
        {children}
      </h4>
    </div>
  );
};

export default Label;
