import React, { useEffect, useState } from "react";
import useCanvas, { CanvasProvider } from "./components/Context/index";
import Sidebar from "./components/Sidebar";
import BottomToolbar from "./components/BottomBar";
import Toggle from "./components/fields/ControlToggle";
import { startCase } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";
import "./components/editor.css";
import {
  initialCanvasHeight,
  initialCanvasWidth,
} from "./components/Context/canvasOptions";
import useKeyboardShortcuts from "./components/utils/hooks/useKeyboardShortcuts";
import useReplaceDynamicFieldsWithVehicleData from "./components/utils/hooks/useReplaceDynamicFields";
import useResponsiveCanvas from "./components/utils/hooks/useResponsiveCanvas";
import { useConstantFontSizeOnScale } from "./components/utils/hooks/useConstantFontSizeOnScale";
import useStopLoading from "./components/utils/hooks/useStopLoading";

interface EditorProps {
  isStickerEditor: boolean;
}

const FabricEditor: React.FC<EditorProps> = ({ isStickerEditor }) => {
  const navigate = useNavigate();
  const { editorName } = useParams();

  // ------------------------- auto menu switching --------------------------------  ( auto changing between AddControls and EditControls UI in sidebar)----------
  // Initialize state with localStorage or default to true
  const [autoMenuSwitching, setAutoMenuSwitching] = useState(() => {
    const storedValue = localStorage.getItem("fabricEditor-autoMenuSwitching");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    localStorage.setItem(
      "fabricEditor-autoMenuSwitching",
      JSON.stringify(autoMenuSwitching)
    );
  }, [autoMenuSwitching]);

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    // this is passing the editorType prop to the context
    <CanvasProvider
      editorType={isStickerEditor ? "window_sticker" : "overlay"}
      editorName={editorName}
    >
      <section className="bg-gray-300 h-[100vh] relative w-full">
        {/* ----------------------------------- exit button -------------------------------------- */}
        <div className="absolute top-0 right-0">
          <button
            onClick={() =>
              navigate(
                isStickerEditor
                  ? "/dashboard/settings/merchandising?tab=documents"
                  : "/dashboard/settings/merchandising?tab=photos&subtab=overlays"
              )
            }
            className="border rounded-md px-2 m-2 py-1 text-slate-700 font-medium text-sm hover:text-slate-600 bg-slate-200/20 hover:bg-white"
          >
            <div className="flex items-center gap-1">
              <XMarkIcon className="h-4 w-4" />
              Exit
            </div>
          </button>
        </div>
        {/* --------------------------------------------------------------------------------------- */}

        <div className="flex xl:gap-10 xl:ml-7 pt-5">
          <div className="w-[25vw] xl:w-[22vw] min-w-[200px] max-w-[350px]">
            <Sidebar autoMenuSwitching={autoMenuSwitching} />

            <Toggle
              title={"Auto Menu Switching"}
              className="m-2"
              labelClassName=" text-sm"
              enabled={autoMenuSwitching}
              setEnabled={() => {
                setAutoMenuSwitching((prev) => !prev);
              }}
            />
          </div>
          <div className="relative pb-3 px-10 mt-10">
            <div className=" absolute -top-10 left-[15%] lg:left-[40%] text-center text-md text-gray-600 font-semibold flex items-center gap-1">
              <h2 className="">{startCase(editorName)}</h2>
              <h1>{isStickerEditor ? "Window Sticker" : "Overlay"}</h1>
            </div>
            <Canvas />
            <BottomToolbar />
          </div>
          {/*  
         if allowing zoom abilities, this will work better - fixing bottom bar to bottom of screen
         <div className="relative flex justify-center items-start w-full">
            <Canvas />
            <div className="absolute bottom-0 flex justify-between w-full pb-3 px-10">
              <BottomToolbar />
            </div>
          </div> */}
        </div>
      </section>
    </CanvasProvider>
  );
};

export default FabricEditor;

// this is just the default DOM's canvas
// FabricJS then sets its canvas on top of this one in CanvasContext/index.tsx
const Canvas = () => {
  const { canvasDOM, canvasRef } = useCanvas();
  useKeyboardShortcuts();
  useReplaceDynamicFieldsWithVehicleData();
  useResponsiveCanvas();
  //   useConstantFontSizeOnScale(canvasRef);
  useStopLoading();

  return (
    <canvas
      width={initialCanvasWidth}
      height={initialCanvasHeight}
      className="shadow-sm"
      ref={canvasDOM}
    />
  );
};
