import { useEffect } from "react";
import useCanvas from "../../Context/index";
import { initialCanvasWidth, aspectRatio } from "../../Context/canvasOptions";

const useResponsiveCanvas = () => {
  const { isCanvasReady, canvasDOM, canvasRef } = useCanvas();

  let fabricCanvasRef = canvasRef;

  // ------------------- make canvas responsive -------------------------------
  useEffect(() => {
    if (!isCanvasReady) return;

    let initialWidth = fabricCanvasRef.current?.getWidth() || 0;
    let initialHeight = fabricCanvasRef.current?.getHeight() || 0;

    const updateCanvasSize = () => {
      //   console.log("Is Canvas Ready:", isCanvasReady);
      //   console.log("Fabric Canvas Ref:", fabricCanvasRef.current);
      if (!canvasDOM.current || !fabricCanvasRef.current || !initialCanvasWidth)
        return;

      const getMultiplier = (width) => {
        if (width > 1500) return 1;
        if (width > 1300) return 0.8;
        if (width > 1000) return 0.7;
        if (width < 1000) return 0.6;
        return 0.4;
      };

      const viewportWidth = window?.innerWidth;
      const viewportHeight = window?.innerHeight;

      const multiplier = getMultiplier(viewportWidth);
      let newCanvasWidth = Math.min(
        initialCanvasWidth,
        viewportWidth * multiplier
      ); // 80% of viewport
      let newCanvasHeight = newCanvasWidth / aspectRatio;

      if (newCanvasHeight > viewportHeight * multiplier) {
        newCanvasHeight = viewportHeight * multiplier;
        newCanvasWidth = newCanvasHeight * aspectRatio;
      }

      if (canvasDOM.current) {
        canvasDOM.current.width = newCanvasWidth;
        canvasDOM.current.height = newCanvasHeight;
      }

      if (fabricCanvasRef.current) {
        // Only scale objects if the canvas was previously initialized
        if (initialWidth > 0 && initialHeight > 0) {
          let scaleX = newCanvasWidth / initialWidth;
          let scaleY = newCanvasHeight / initialHeight;

          if (fabricCanvasRef.current) {
            const objects = fabricCanvasRef.current.getObjects();

            for (let i in objects) {
              objects[i].scaleX *= scaleX;
              objects[i].scaleY *= scaleY;
              objects[i].left *= scaleX;
              objects[i].top *= scaleY;
              objects[i].setCoords();
            }
          }
        }

        // Update the canvas dimensions
        try {
          fabricCanvasRef.current.setWidth(newCanvasWidth);
          fabricCanvasRef.current.setHeight(newCanvasHeight);
          fabricCanvasRef.current.renderAll();
        } catch (error) {
          console.log("Error setting fabric canvas dimensions:", error);
        }

        // Update the initial width and height for the next resize event
        initialWidth = newCanvasWidth;
        initialHeight = newCanvasHeight;
      }
    };

    updateCanvasSize(); // Initialize canvas size
    window.addEventListener("resize", updateCanvasSize); // Update on resize

    return () => {
      window.removeEventListener("resize", updateCanvasSize); // Cleanup
    };
  }, [canvasDOM, fabricCanvasRef, isCanvasReady, initialCanvasWidth]);
};

export default useResponsiveCanvas;
