import { useEffect, useState } from "react";
import useCanvas from "../../../../Context";
import Label from "../../../../fields/ControlLabel";
import ColorPicker from "../../../../fields/ControlColorPicker";

const TextColor = () => {
  const { canvasRef, isCanvasReady } = useCanvas();
  const [currentColor, setCurrentColor] = useState("#000000");

  // get text color of selected box to display in field
  useEffect(() => {
    const currentCanvas = canvasRef.current;

    const handleSelection = (e) => {
      const activeObjects = e.selected;
      if (activeObjects && activeObjects.length === 1) {
        const obj = activeObjects[0];
        if (obj.type === "i-text" || obj.type === "textbox") {
          setCurrentColor(obj.fill || "#000000");
        }
      }
    };

    currentCanvas?.on("selection:created", handleSelection);
    currentCanvas?.on("selection:updated", handleSelection);

    return () => {
      currentCanvas?.off("selection:created", handleSelection);
      currentCanvas?.off("selection:updated", handleSelection);
    };
  }, [canvasRef.current, isCanvasReady]);

  const handleColorChange = (newColor) => {
    setCurrentColor(newColor);

    const activeObjects = canvasRef.current?.getActiveObjects();
    if (activeObjects && activeObjects.length === 1) {
      const obj = activeObjects[0];
      if (obj.type === "i-text" || obj.type === "textbox") {
        const selectionStart = obj.selectionStart;
        const selectionEnd = obj.selectionEnd;

        // Check if any text is selected inside the text box
        if (selectionStart !== selectionEnd) {
          obj.setSelectionStyles(
            { fill: newColor },
            selectionStart,
            selectionEnd
          );
        } else {
          // No text is selected; change the fill for the entire text box
          obj.set("fill", newColor);
        }

        obj.setCoords();
        obj.fire("object:modified"); // triggers listener in state management - allows action to be undone
        canvasRef.current?.renderAll();
      }
    }
  };

  return (
    <section className="">
      <Label>Text Color</Label>
      <ColorPicker value={currentColor} onChange={handleColorChange} />
    </section>
  );
};

export default TextColor;
