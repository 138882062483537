import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/auth",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
  },
  withCredentials: true,
});

export const signinUser = async ({ email, password }) => {
  return await api
    .post("/signin", { email, password })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const loginAs = async (userId) => {
  return await api
    .post("/loginAs", { userId })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const passwordReset = async ({ email }) => {
  return await api
    .post("/resetPassword", { email })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const changePassword = async (data) => {
  return await api
    .post("/changePassword", data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const validateResetToken = async (data) => {
  return await api
    .post("/validateResetToken", data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const refreshSession = async () => {
  return await api
    .post("/refreshSession")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const userJoin = async (user) => {
  try {
    return await api
      .post("/join", user)
      .then((response) => response)
      .catch((error) => {
        return error.response;
      });
  } catch (error) {}
};

export const validateInvitationToken = async (data) => {
  return await api
    .post("/validateInvitationToken", data)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const logoutUser = async () => {
  return await api
    .post("/logout")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
