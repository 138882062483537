import tinycolor from "tinycolor2";

export const LOGO =
  "https://firebasestorage.googleapis.com/v0/b/chrome-c4a68.appspot.com/o/image1.png?alt=media&token=b67113a3-8d12-41a3-8c50-a053060078ff";
export let PRIMARY_COLOR = "#FF0000";
export let SECONDARY_COLOR = "#000000";
export let PRIMARY_TEXT_COLOR = "#000000";
export let ALTERNATE_PRIMARY_COLOR = "#22c55e";
export let SECONDARY_TEXT_COLOR = "#ffffff";
export let LINK_COLOR = "#FF0000";
export let LABEL_COLOR = "#374151";
export const SUBTLE_COLOR = "#9b9b9b";

export const setPrimaryColor = (color) => {
  PRIMARY_COLOR = color;
};

export const setSecondaryColor = (color) => {
  SECONDARY_COLOR = color;
};

export const getPastelColor = (hexColor) => {
  // Convert hex color to HSL
  const hslColor = tinycolor(hexColor).toHsl();

  // Adjust the lightness value to a pastel range
  hslColor.l = 75;

  // Convert HSL color back to hex format
  const pastelColor = tinycolor(hslColor).toHexString();

  return pastelColor;
};

export const getGreyscaleColor = (hexColor) => {
  // Convert hex color to HSL
  const greyScaleColor = tinycolor(hexColor).desaturate(80);

  return greyScaleColor;
};
