import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const TableColumns = ({
  columns,
  className,
  columnClassName,
  columnClassNames,
  withActionColumn = false,
}) => {
  return (
    <thead className={twMerge("top-0 z-2 w-full", className)}>
      <tr>
        {columns.map((column, index) => (
          <th
            key={index}
            scope="col"
            className={twMerge(
              ` sticky top-0 z-5  bg-gray-100 bg-opacity-75 py-3.5 ${
                index === 0 ? "pl-4" : ""
              } text-start text-sm font-semibold text-gray-900`,
              columnClassNames ? columnClassNames[index] : columnClassName
            )}
          >
            {column}
          </th>
        ))}
        {withActionColumn && (
          <th
            scope="col"
            className={twMerge(
              "sticky top-0 z-5  bg-gray-100 bg-opacity-75 py-3.5 pl-3 text-start text-sm font-semibold text-gray-900",
              columnClassName
            )}
          />
        )}
      </tr>
    </thead>
  );
};

TableColumns.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  ).isRequired,
  className: PropTypes.string,
  columnClassName: PropTypes.string,
  columnClassNames: PropTypes.array,
  withActionColumn: PropTypes.bool,
};

export default TableColumns;
