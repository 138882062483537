import API from "../../../utils/api";

export const getNotifications = async (queryParams) => {
  return await API("/notifications")
    .get("/", {
      params: queryParams,
    })
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const getNotificationsCount = async (queryParams) => {
  return await API("/notifications")
    .get("/counts")
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const markNotificationAsSeen = async (id) => {
  return await API("/notifications")
    .post("/seen/" + id)
    .then((response) => response.data)
    .catch((error) => error.response);
};
