import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Skeleton from "./Skeleton";
import { createElement } from "react";
import { twMerge } from "tailwind-merge";

// Define the shape of a single page
const PagePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  asLink: PropTypes.bool.isRequired,
});

// Define the PropTypes for the Breadcrumb component
const BreadcrumbPropTypes = {
  homeIcon: PropTypes.node,
  pages: PropTypes.arrayOf(PagePropType).isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

const Breadcrumb = ({ pages, loading, homeIcon, className }) => {
  return (
    <nav
      className={twMerge("flex justify-between", className)}
      aria-label="Breadcrumb"
    >
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <span className="text-gray-400 hover:text-gray-500">
              {(homeIcon &&
                createElement(homeIcon, {
                  className: "h-5 w-5 flex-shrink-0",
                })) || (
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
              )}
              <span className="sr-only">Home</span>
            </span>
          </div>
        </li>
        {loading
          ? pages.map(({ name }) => (
              <Skeleton key={name} className="w-36 h-[25px] mr-4" />
            ))
          : pages.map(({ name, asLink, href }) => {
              if (asLink) {
                return (
                  <Link to={href} key={name + asLink}>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                        {name}
                      </span>
                    </div>
                  </Link>
                );
              }
              return (
                <li key={name}>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                      {name}
                    </span>
                  </div>
                </li>
              );
            })}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = BreadcrumbPropTypes;

export default Breadcrumb;
